import { create } from 'zustand';

interface ProductCanvasState {
  importingProductCanvases: Set<string>;
}

const productCanvasesStore = create<ProductCanvasState>(() => ({
  importingProductCanvases: new Set<string>(),
}));

export const setProductCanvasImportingStart = (id: string) => {
  productCanvasesStore.setState((state) => ({
    importingProductCanvases: new Set(state.importingProductCanvases.add(id)),
  }));
};

export const setProductCanvasImportingEnd = (id: string) => {
  productCanvasesStore.setState((state) => {
    state.importingProductCanvases.delete(id);
    return {
      importingProductCanvases: new Set(state.importingProductCanvases),
    };
  });
};

export const useImportingProductCanvases = () => {
  return productCanvasesStore((state) => state.importingProductCanvases);
};
