import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { closeSideModal, useSideModalContext } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { useFormik } from 'formik';
import { PersonaDetails } from '@/models/persona-details.interface.ts';
import { useUpdatePersona } from '@/api/segments/persona/update-persona.ts';
import { GenericCanvasGenerationContextSideModalBody } from '@/components/GenericCanvasGenerationContextSideModalBody.tsx';

export const PersonaGenerationContextSideModalBody = () => {
  const { dataRoomItems } = useDataRoomItems();
  const { updatePersona } = useUpdatePersona();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { personaDetails } = useSideModalContext<{ personaDetails: PersonaDetails }>();

  const formik = useFormik<{ generationContextItemsIds: string[] }>({
    initialValues: {
      generationContextItemsIds: personaDetails.generationContextItemsIds ?? [],
    },
    onSubmit: async ({ generationContextItemsIds }) => {
      closeSideModal();
      showLoader();
      await updatePersona(personaDetails.id, { generationContextItemsIds });
      hideLoader();
    },
  });

  return (
    <GenericCanvasGenerationContextSideModalBody
      formik={formik}
      dataRoomItems={dataRoomItems}
      submitButtonText="Save Changes"
    />
  );
};
