import { closeSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { Button } from 'flowbite-react';

interface SideModalBodyWithHeaderProps {
  header: string;
  subheader: string;
  onSubmit: () => void;
  submitButtonText: string;
  buttonDisabled?: boolean;
  children: React.ReactNode;
}

export const SideModalBodyWithHeader = ({
  header,
  subheader,
  onSubmit,
  submitButtonText,
  buttonDisabled,
  children,
}: SideModalBodyWithHeaderProps) => {
  return (
    <>
      <div className="relative flex-col justify-between rounded-t-2xl bg-gray-ultra-light p-6">
        <div className="mb-2 flex w-full justify-stretch text-h2-desktop font-medium text-gray-dark">
          <div className="grow">{header}</div>
          <div>
            <button
              className="hover:opacity-70"
              onClick={() => closeSideModal()}
            >
              <img
                src="/close-side-panel.svg"
                className="cursor-pointer"
                alt=""
              />
            </button>
          </div>
        </div>
        {!!subheader && <div className="text-sm font-normal text-gray-dark">{subheader}</div>}
      </div>

      <div className="grow overflow-y-scroll bg-white p-6">{children}</div>

      <div className="flex flex-col rounded-b-2xl bg-white p-6">
        <Button
          color="primary"
          onClick={onSubmit}
          disabled={buttonDisabled}
        >
          {submitButtonText}
        </Button>
      </div>
    </>
  );
};
