import { Organization } from '@/models/organization.interface.ts';
import useSWR from 'swr';
import { useApi } from '@/api/api.ts';
import { useAuth } from '@/hooks/auth/useAuth';
import { useSemrush } from '@/state/semrush.store';

export const useOrganizations = () => {
  const { isSemrush } = useSemrush();

  const { getFetcher } = useApi();
  const { user } = useAuth();

  const { data } = useSWR<Organization[]>(isSemrush ? null : `/user/organizations`, getFetcher);

  return {
    organizations: data,
    organizationsCreatedByUser: data?.filter((org) => org.payerId === user?.sub),
  };
};
