import { Tag } from '../../libs/tables/table/tags/TagInput.tsx';

export const mapTagColorToCssClasses = (color: Tag['color']) => {
  switch (color) {
    case 'Gray':
      return 'bg-neutrals-300 text-neutrals-1000';
    case 'Green':
      return 'bg-positive-200 text-positive-1000';
    case 'Red':
      return 'bg-negative-200 text-negative-1000';
    case 'Pink':
      return 'bg-pink-200 text-pink-1000';
    case 'Violet':
      return 'bg-violet-100 text-violet-1000';
    case 'Purple':
      return 'bg-purple-200 text-purple-1000';
    case 'Blue':
      return 'bg-blue-100 text-blue-1000';
    case 'Turquoise':
      return 'bg-turquoise-100 text-turquoise-1000';
    case 'Mint':
      return 'bg-mint-300 text-mint-1000';
    case 'Lime':
      return 'bg-lime-100 text-lime-1000';
    case 'Olive':
      return 'bg-olive-100 text-olive-1000';
    case 'Yellow':
      return 'bg-yellow-100 text-yellow-1000';
    case 'Orange':
      return 'bg-orange-100 text-orange-1000';
    case 'Brown':
      return 'bg-brown-200 text-brown-1000';
    default:
      return 'bg-neutrals-300 text-neutrals-1000';
  }
};
