import { useApi } from '@/api/api';
import useSWR, { mutate } from 'swr';
import { DataColumn, TableViewConfig } from '../table/table.inferfaces';
import { useCallback, useEffect, useRef } from 'react';
import { RowData } from '@tanstack/react-table';

interface TableDto {
  _id: string;
  name: string;
}

export type DynamicRowData = RowData & {
  _id: string;
  [key: string]: unknown;
};

export function useMyTables() {
  const { getFetcher } = useApi();
  return useSWR<TableDto[]>('/table', getFetcher);
}

export function useTableUpdateData() {
  const { postFetcher } = useApi();
  const postFetcherRef = useRef(postFetcher);

  return (
    tableId: string,
    changes: {
      updatedRows: DynamicRowData[];
      removedIds: string[];
    },
  ) =>
    postFetcherRef.current(`/table/${tableId}/rows/batch`, {
      arg: changes,
    });
}

export function useCreateTable() {
  const { postFetcher } = useApi();

  return async (name: string) => {
    const table = await postFetcher('/table', { arg: { name } });
    mutate('/table');
    return table;
  };
}

export function useUpdateTableColumns() {
  const { putFetcher } = useApi();
  const putFetcherRef = useRef(putFetcher);

  useEffect(() => {
    putFetcherRef.current = putFetcher;
  }, [putFetcher]);

  return (tableId: string, columns: DataColumn[]) => {
    putFetcherRef.current(`/table/${tableId}/column`, { arg: columns });
  };
}

export function useUpdateTableView() {
  const { patchFetcher } = useApi();
  return (tableId: string, viewConfig: TableViewConfig) =>
    patchFetcher(`/table/${tableId}/view-config`, { arg: viewConfig });
}

export function useTableDefinitionApi() {
  const { getFetcher } = useApi();
  const fetcher = useRef(getFetcher);

  useEffect(() => {
    fetcher.current = getFetcher;
  }, [getFetcher]);

  return useCallback(async (tableId: string) => {
    return await fetcher.current(`/table/${tableId}`);
  }, []);
}

export function useTableLoadData() {
  const { getFetcher } = useApi();
  const fetcher = useRef(getFetcher);

  useEffect(() => {
    fetcher.current = getFetcher;
  }, [getFetcher]);

  return useCallback(async (tableId: string) => {
    try {
      return await fetcher.current(`/table/${tableId}/data?limit=10000`);
    } catch (error) {
      console.error(error);
      return [];
    }
  }, []);
}

export function useDeleteTable() {
  const { deleteFetcher } = useApi();
  return async (tableId: string) => {
    await deleteFetcher(`/table/${tableId}`);
    mutate('/table');
  };
}

export function useRefillTableRows() {
  const { postFetcher } = useApi();
  const postFetcherRef = useRef(postFetcher);

  return (tableId: string, rows: DynamicRowData[]) =>
    postFetcherRef.current(`/table/${tableId}/rows/refill`, { arg: rows });
}
