import { useCanvasDetails, useRenameCanvas } from '@/api/canvases/api';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { VALIDATION_ERRORS } from '@/consts/validation-errors.const';
import { getDfFlowbiteFieldProps } from '@/helpers/get-df-flowbite-field-props';
import { Button, TextInput } from 'flowbite-react';
import { FormikProvider, useFormik } from 'formik';
import { object, string } from 'yup';

export function CanvasRenameModalBody() {
  const { closeModal, context } = useGlobalModal<{ instanceId: string }>();
  const { canvasDetails } = useCanvasDetails(context.instanceId);
  const { showLoader, hideLoader } = useGlobalLoader();
  const { renameCanvas } = useRenameCanvas();

  const formik = useFormik({
    initialValues: {
      name: canvasDetails?.name,
    },
    validationSchema: object({
      name: string().required(VALIDATION_ERRORS.Required),
    }),
    onSubmit: async (data) => {
      if (!data.name) return;

      closeModal();
      showLoader();
      await renameCanvas(context.instanceId, data.name);
      hideLoader();
    },
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <TextInput
          {...getDfFlowbiteFieldProps('name', formik)}
          placeholder="Canvas name"
        />

        <div className="modal-divider mb-6 mt-8"></div>

        <div className="flex items-center justify-end gap-3">
          <Button
            color="secondary"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="submit"
          >
            Save changes
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
