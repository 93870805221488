import { cn } from '@/helpers/cn';
import { Project } from '@/models/project.interface';
import { Workspace } from '@/models/workspace.interface';
import { FormikProps } from 'formik';
import { TileRadio } from '@/components/TileRadio.tsx';

export function WorkspacePorojectsSelector({
  currentWorkspace,
  projects,
  formik,
  fieldName,
}: {
  currentWorkspace?: Workspace | null;
  projects?: Project[];
  formik: FormikProps<{ projectId?: number }> | FormikProps<{ newProjectId: number }>;
  fieldName: string;
}) {
  return (
    <div className="flex flex-col gap-4">
      <h2 className="flex items-center gap-3 text-subtitle-md">
        {currentWorkspace?.avatarUrl ? (
          <img
            src={import.meta.env.VITE_ASSETS + currentWorkspace.avatarUrl}
            className="size-6 overflow-hidden rounded-lg object-cover"
          />
        ) : (
          <div className="flex size-6 items-center justify-center rounded-lg bg-pink-300 text-[14px]">W</div>
        )}

        <span>{currentWorkspace?.name} projects</span>
      </h2>
      <div className="flex flex-col gap-3">
        {projects &&
          projects.map((project) => (
            <TileRadio
              name={fieldName}
              value={project.id}
              formik={formik}
              key={project.id}
            >
              <div className={cn('rounded-lg p-2', project.color ?? 'bg-green-light')}>
                <img
                  src="/business-black.svg"
                  alt=""
                />
              </div>
              <span className="text-caption-md">{project.name}</span>
            </TileRadio>
          ))}
      </div>
    </div>
  );
}
