import { useNavigate } from 'react-router-dom';
import { initAppCenterSDK } from '@semcore/app-center-js-sdk';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';

import { type SM } from '@semcore/app-center-js-sdk';
import { buildUrl } from '@/helpers/build-url';
import { Urls } from '@/consts/urls';

import { jwtDecode } from 'jwt-decode';
import { setDecodedToken, setIsSemrush } from '@/state/semrush.store';
import axios from 'axios';

declare global {
  interface Window {
    SM: SM;
  }
}

const addSemrushPlan = async (token: string) => {
  return await axios.post(
    `${import.meta.env.VITE_API_SERVER}/semrush/plan`,
    {},
    { headers: { Authorization: `Bearer ${token}` } },
  );
};

export const Semrush = () => {
  const { showLoader, hideLoader } = useGlobalLoader();

  const navigate = useNavigate();
  showLoader('Loading...');

  const inIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  if (!inIframe()) {
    hideLoader();
    return <div>Return to semrush app</div>;
  }

  initAppCenterSDK();

  try {
    window?.SM?.init().then(async () => {
      setIsSemrush(true);
      const token = await window.SM.client('getAccessToken');
      await addSemrushPlan(token);
      setDecodedToken(jwtDecode(token));
      hideLoader();
      navigate(buildUrl([Urls.DASHBOARD]));
    });
  } catch {
    hideLoader();
  }

  return <div>Return to semrush app</div>;
};
