import { useCreatePersona } from '@/api/segments/persona/create-persona.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import GroupsIcon from '@assets/groups.svg';
import { Card } from 'flowbite-react';
import { useNoSegmentPersonas } from '@/api/segments/persona/get-no-segment-personas.ts';
import { PersonaCard } from './PersonaCard.tsx';

export const NoSegmentPersonasList = () => {
  const { noSegmentPersonas } = useNoSegmentPersonas();
  const { createPersona } = useCreatePersona();
  const { showLoader, hideLoader } = useGlobalLoader();
  const navigate = useNavigate();

  const onAddNoSegmentPersona = async () => {
    showLoader();
    const persona = await createPersona();
    navigate(buildUrl([Urls.SEGMENTS, Urls.PERSONA_DETAILS], { personaId: persona.id }));
    hideLoader();
  };

  return (
    <div className="relative after:absolute after:-bottom-4 after:-left-4 after:-right-4 after:-top-4 after:z-[-1] after:rounded-xl after:bg-primary-100">
      <h2 className="mb-4 flex items-center gap-2 text-h4-desktop">
        <div className="rounded-lg bg-primary-200 p-2">
          <GroupsIcon />
        </div>
        <span>No segment</span>
      </h2>
      <div className="grid grid-cols-[112px,1fr] items-start gap-4">
        <button
          className=""
          onClick={onAddNoSegmentPersona}
        >
          <Card className="size-[112px] border-primary-300 !bg-primary-200 hover:!bg-primary-300 active:!bg-primary-400">
            <div className="flex size-full items-center justify-center">
              <img
                src="/add-black.svg"
                width={32}
                height={32}
                alt=""
              />
            </div>
          </Card>
        </button>
        <div className="grid grid-cols-3 gap-4">
          {!!noSegmentPersonas &&
            noSegmentPersonas.map((persona, i) => (
              <PersonaCard
                persona={persona}
                key={i}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
