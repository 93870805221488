import { AddResourcePayload } from '@/models/add-resource-payload.interface.ts';
import { useApi } from '../api.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import { mutate } from 'swr';
import { useTriggerEvent } from '../events/api.ts';
import { TagManagerEvent } from '../events/model.ts';

export const useAddResource = (): {
  addResource: (payload: AddResourcePayload) => Promise<void>;
} => {
  const { putFetcher } = useApi();
  const dataRoomId = useDataRoomId();
  const { triggerDataLayer } = useTriggerEvent();

  const addResource = (payload: AddResourcePayload) => {
    triggerDataLayer(TagManagerEvent.user_created_data, {});
    return putFetcher<AddResourcePayload>(`/inputs/dataRoom/${dataRoomId}/add-resource`, {
      arg: payload,
    }).then(() => mutate(`/inputs/dataRoom/${dataRoomId}`));
  };

  return {
    addResource,
  };
};
