import { FormikProvider, useFormik } from 'formik';
import { TileRadio } from '@/components/TileRadio.tsx';
import { cn } from '@/helpers/cn';
import { getDfFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { Button } from 'flowbite-react';
import { number, object } from 'yup';
import { StripeUpsellProduct } from '@/models/stripe-upsell-product.interface.ts';
import { ChangeEvent, useMemo } from 'react';
import { useByeUpsellProduct } from '@/api/pricing/buy-upsell-product.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { StripeUpsellProductName } from '@/enums/StripeUpsellProductName.enum.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';

export const UpsellProductForm = ({
  upsellProduct,
  displayingOnModal,
}: {
  upsellProduct: StripeUpsellProduct;
  displayingOnModal?: boolean;
}) => {
  const { buyUpsellProduct } = useByeUpsellProduct();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal } = useGlobalModal();
  const maxCustomAmountDigits = 3;
  const quantityFieldName = `quantity-${upsellProduct.metadata.upsellName}`;
  const customQuantityFieldName = `custom-quantity-${upsellProduct.metadata.upsellName}`;

  const productFormInfo = useMemo(() => {
    switch (upsellProduct.metadata.upsellName) {
      case StripeUpsellProductName.WORKSPACE:
        return {
          singleItemLabel: 'workspace',
          multipleItemsLabel: 'workspaces',
          icon: '/workspace-black.svg',
          iconColors: ['bg-orange-300', 'bg-olive-300', 'bg-purple-300'],
        };
      case StripeUpsellProductName.STORAGE:
        return {
          multipleItemsLabel: 'GB',
          icon: '/rows-black.svg',
          iconColors: ['bg-lime-300', 'bg-pink-300', 'bg-primary-300'],
        };
    }
  }, [upsellProduct.metadata.upsellName]);

  const formik = useFormik({
    initialValues: {
      [`${quantityFieldName}`]: null,
      [`${customQuantityFieldName}`]: '',
    },
    validationSchema: object({
      [`${quantityFieldName}`]: number().nullable(),
      [`${customQuantityFieldName}`]: number(),
    }),
    onSubmit: async (data) => {
      const quantity = data[quantityFieldName] ?? data[customQuantityFieldName];

      if (displayingOnModal) {
        closeModal();
      }

      showLoader();
      await buyUpsellProduct(upsellProduct.priceId, +quantity!).catch(() => hideLoader());
    },
  });

  const onRadioCheck = () => {
    formik.setFieldValue(customQuantityFieldName, '');
  };

  const onCustomQuantityChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length <= maxCustomAmountDigits) {
      formik.setFieldValue(customQuantityFieldName, e.target.value);
    }
  };

  return (
    <>
      {!!productFormInfo && (
        <FormikProvider value={formik}>
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-col gap-2"
          >
            {new Array(3).fill(null).map((_, i) => (
              <div
                key={i}
                className="flex w-full flex-col text-button-md"
                onClick={onRadioCheck}
              >
                <TileRadio
                  name={quantityFieldName}
                  value={i + 1}
                  formik={formik}
                  className={cn({ '!border-white !bg-[#F8F8F9A3] hover:!bg-[#F8F8F9A3]': displayingOnModal })}
                >
                  <div className={cn('rounded-md p-1', productFormInfo.iconColors[i])}>
                    <img
                      src={productFormInfo.icon}
                      width={16}
                      height={16}
                      alt=""
                    />
                  </div>
                  <div className="grow">
                    +{(i + 1) * +(upsellProduct.unitInProduct ?? 1)}{' '}
                    {i === 0 && productFormInfo.singleItemLabel
                      ? productFormInfo.singleItemLabel
                      : productFormInfo.multipleItemsLabel}
                  </div>
                  <div>
                    ${((i + 1) * upsellProduct.priceData.unit_amount) / 100}{' '}
                    <span className="text-body-sm text-neutrals-700">/ monthly</span>
                  </div>
                </TileRadio>
              </div>
            ))}

            <div className="my-1 flex items-center gap-2">
              <div className={cn('h-[1px] grow bg-neutrals-300', { '!bg-neutrals-500': displayingOnModal })}></div>
              <div className="text-body-sm text-neutrals-700">OR</div>
              <div className={cn('h-[1px] grow bg-neutrals-300', { '!bg-neutrals-500': displayingOnModal })}></div>
            </div>

            <div
              className={cn(
                'mb-4 flex items-center gap-3 rounded-lg border border-neutrals-300 p-2 !pl-3 text-button-md',
                { '!mb-6 !border-white !bg-[#F8F8F9A3] hover:!bg-[#F8F8F9A3]': displayingOnModal },
              )}
            >
              <div className="shrink-0 rounded-md bg-neutrals-300 p-1">
                <img
                  src="/pencil-black.svg"
                  alt=""
                />
              </div>
              {upsellProduct.unitInProduct && (
                <div className="shrink-0">
                  +{upsellProduct.unitInProduct}{' '}
                  {productFormInfo.singleItemLabel
                    ? productFormInfo.singleItemLabel
                    : productFormInfo.multipleItemsLabel}{' '}
                  <span className="ml-2">x</span>
                </div>
              )}
              <input
                {...getDfFieldProps(customQuantityFieldName, formik)}
                className="reset-input min-w-0 grow text-button-md"
                type="number"
                placeholder="Type custom number..."
                onChange={onCustomQuantityChange}
                onFocus={() => formik.setFieldValue(quantityFieldName, null)}
              ></input>
              <div className="shrink-0">
                {!!formik.values[customQuantityFieldName] && (
                  <span>${(+formik.values[customQuantityFieldName] * upsellProduct.priceData.unit_amount) / 100} </span>
                )}
                <span className="shrink-0 text-body-sm text-neutrals-700">/ monthly</span>
              </div>
            </div>

            <Button
              color="primary"
              type="submit"
              disabled={!formik.values[quantityFieldName] && !formik.values[customQuantityFieldName]}
            >
              <img
                src="/arrow-circle-up-white.svg"
                alt=""
              />
              Add to plan
            </Button>
          </form>
        </FormikProvider>
      )}
    </>
  );
};
