import { EditableCellProps } from '../interface.ts';
import DropdownMenuPanel from '../../../shared/DropdownMenuPanel.tsx';
import { DropdownMenu } from '../../../shared/DropdownMenu.tsx';
import { Tag } from '../../tags/TagInput.tsx';
import { useEffect, useMemo, useState } from 'react';
import { TagElement } from '../../tags/TagElement.tsx';
import { cn } from '@/helpers/cn.ts';
import { EditTagForm } from '../../tags/EditTagForm.tsx';
import { TagSelector } from '../../tags/TagSelector.tsx';
import { cellHeight } from '../../cell-height.const.ts';

export const SingleSelectCell = ({
  value,
  onChange,
  onBlur,
  innerRef,
  table,
  column,
}: EditableCellProps<Tag['id'] | null, HTMLInputElement>) => {
  const [selectedTagId, setSelectedTagId] = useState<Tag['id'] | null>(value);
  const [tags, setTags] = useState<Tag[]>(column.columnDef.meta!.tags || []);
  const [currentlyEditingTag, setCurrentlyEditingTag] = useState<Tag | null>(null);
  const [currentlyEditingTagInitialName, setCurrentlyEditingTagInitialName] = useState<string>('');
  const { updateColumnTags } = table.options.meta || {};

  const selectedTag = useMemo(() => {
    return tags.find((tag) => tag.id === selectedTagId) ?? null;
  }, [tags, selectedTagId]);

  useEffect(() => {
    if (!tags.some((tag) => tag.id === selectedTagId)) {
      setSelectedTagId(null);
    }
  }, [tags, selectedTagId]);

  const onDropdownClose = () => {
    onBlur();
    updateColumnTags?.(column.id, tags);
  };

  const onTagSelect = (tag: Tag) => {
    setSelectedTagId(tag.id);
    onChange(tag.id);
  };

  const turnOnEditingTag = (tag: Tag) => {
    setCurrentlyEditingTag(tag);
    setCurrentlyEditingTagInitialName(tag.name);
  };

  const turnOffEditingTag = () => {
    if (currentlyEditingTag?.name === '') {
      updateCurrentlyEditingTag({ name: currentlyEditingTagInitialName });
    }
    setCurrentlyEditingTag(null);
  };

  const updateCurrentlyEditingTag = (newProps: { name?: string; color?: Tag['color'] }) => {
    setCurrentlyEditingTag({ ...currentlyEditingTag!, ...newProps });
    setTags((oldTags) =>
      oldTags.map((tag) =>
        tag.id === currentlyEditingTag!.id
          ? {
              ...tag,
              ...newProps,
            }
          : tag,
      ),
    );
  };

  const onTagDelete = () => {
    setTags((oldTags) => oldTags.filter((tag) => tag.id !== currentlyEditingTag!.id));
    setCurrentlyEditingTag(null);
  };

  const onRemoveTagFromSelectedTags = () => {
    if (selectedTag) {
      setSelectedTagId(null);
      onChange(null);
    }
  };

  return (
    <>
      <DropdownMenu
        placement="bottom-start"
        offset={-cellHeight}
        onDropdownClose={onDropdownClose}
        DropdownControl={
          <>
            <DropdownMenuPanel
              className={cn(currentlyEditingTag ? '!w-[250px]' : '!w-[400px]', 'max-h-[50svh] overflow-y-auto')}
            >
              {currentlyEditingTag ? (
                <EditTagForm
                  tag={currentlyEditingTag}
                  onTurnOffEditingTag={turnOffEditingTag}
                  onUpdateTag={updateCurrentlyEditingTag}
                  onDeleteTag={onTagDelete}
                />
              ) : (
                <TagSelector
                  selectedTags={selectedTag ? [selectedTag] : []}
                  tags={tags}
                  setTags={setTags}
                  onTagSelect={onTagSelect}
                  onRemoveTagFromSelectedTags={onRemoveTagFromSelectedTags}
                  innerRef={innerRef}
                  onTurnOnTagEditing={turnOnEditingTag}
                />
              )}
            </DropdownMenuPanel>
          </>
        }
      >
        {(props) => (
          <div
            {...props}
            className="flex size-full items-start overflow-x-hidden px-3 py-2"
          >
            {!!selectedTag && <TagElement tag={selectedTag} />}
          </div>
        )}
      </DropdownMenu>
    </>
  );
};
