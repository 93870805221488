import { EditableCellProps } from '../interface.ts';
import { Tag } from '../../tags/TagInput.tsx';
import { useEffect, useMemo, useState } from 'react';
import { DropdownMenu } from '../../../shared/DropdownMenu.tsx';
import DropdownMenuPanel from '../../../shared/DropdownMenuPanel.tsx';
import { cn } from '@/helpers/cn.ts';
import { EditTagForm } from '../../tags/EditTagForm.tsx';
import { TagSelector } from '../../tags/TagSelector.tsx';
import { TagElement } from '../../tags/TagElement.tsx';
import { cellHeight } from '../../cell-height.const.ts';

export const MultiSelectCell = ({
  value,
  onChange,
  onBlur,
  innerRef,
  table,
  column,
}: EditableCellProps<Array<Tag['id']>, HTMLInputElement>) => {
  const [selectedTagsIds, setSelectedTagsIds] = useState<Array<Tag['id']>>(value);
  const [tags, setTags] = useState<Tag[]>(column.columnDef.meta!.tags || []);
  const [currentlyEditingTag, setCurrentlyEditingTag] = useState<Tag | null>(null);
  const [currentlyEditingTagInitialName, setCurrentlyEditingTagInitialName] = useState<string>('');
  const { updateColumnTags } = table.options.meta || {};

  const selectedTags = useMemo(() => {
    return selectedTagsIds.map((id) => tags.find((tag) => tag.id === id)!);
  }, [tags, selectedTagsIds]);

  useEffect(() => {
    setSelectedTagsIds((prevSelectedTagsIds) => prevSelectedTagsIds.filter((id) => tags.some((tag) => tag.id === id)));
  }, [tags]);

  const onDropdownClose = () => {
    onBlur();
    updateColumnTags?.(column.id, tags);
  };

  const onTagSelect = (tag: Tag) => {
    const newSelectedTagsIds = selectedTagsIds.includes(tag.id) ? selectedTagsIds : [...selectedTagsIds, tag.id];
    setSelectedTagsIds(newSelectedTagsIds);
    onChange(newSelectedTagsIds);
  };

  const turnOnEditingTag = (tag: Tag) => {
    setCurrentlyEditingTag(tag);
    setCurrentlyEditingTagInitialName(tag.name);
  };

  const turnOffEditingTag = () => {
    if (currentlyEditingTag?.name === '') {
      updateCurrentlyEditingTag({ name: currentlyEditingTagInitialName });
    }
    setCurrentlyEditingTag(null);
  };

  const updateCurrentlyEditingTag = (newProps: { name?: string; color?: Tag['color'] }) => {
    setCurrentlyEditingTag({ ...currentlyEditingTag!, ...newProps });
    setTags((oldTags) =>
      oldTags.map((tag) =>
        tag.id === currentlyEditingTag!.id
          ? {
              ...tag,
              ...newProps,
            }
          : tag,
      ),
    );
  };

  const onTagDelete = () => {
    setTags((oldTags) => oldTags.filter((tag) => tag.id !== currentlyEditingTag!.id));
    setCurrentlyEditingTag(null);
  };

  const onRemoveTagFromSelectedTags = (tag: Tag) => {
    const newSelectedTagsIds = selectedTagsIds.filter((id) => id !== tag.id);
    setSelectedTagsIds(newSelectedTagsIds);
    onChange(newSelectedTagsIds);
  };

  return (
    <>
      <DropdownMenu
        placement="bottom-start"
        offset={-cellHeight}
        onDropdownClose={onDropdownClose}
        DropdownControl={
          <>
            <DropdownMenuPanel
              className={cn(currentlyEditingTag ? '!w-[250px]' : '!w-[400px]', 'max-h-[50svh] overflow-y-auto')}
            >
              {currentlyEditingTag ? (
                <EditTagForm
                  tag={currentlyEditingTag}
                  onTurnOffEditingTag={turnOffEditingTag}
                  onUpdateTag={updateCurrentlyEditingTag}
                  onDeleteTag={onTagDelete}
                />
              ) : (
                <TagSelector
                  selectedTags={selectedTags}
                  tags={tags}
                  setTags={setTags}
                  onTagSelect={onTagSelect}
                  onRemoveTagFromSelectedTags={onRemoveTagFromSelectedTags}
                  innerRef={innerRef}
                  onTurnOnTagEditing={turnOnEditingTag}
                />
              )}
            </DropdownMenuPanel>
          </>
        }
      >
        {(props) => (
          <div
            {...props}
            className="flex size-full max-w-full items-start gap-2 overflow-x-hidden px-3 py-2"
          >
            {selectedTags.map((tag) => (
              <TagElement
                key={tag.id}
                tag={tag}
              />
            ))}
          </div>
        )}
      </DropdownMenu>
    </>
  );
};
