import { useUnFinishedOnboardingProcesses } from '../../routes/onboarding/onboarding-store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useAuth } from '@/hooks/auth/useAuth.ts';

export const useOpenOnboardingModal = () => {
  const { openModal } = useGlobalModal();
  const { user } = useAuth();
  const unfinishedOnboardingProcesses = useUnFinishedOnboardingProcesses(user!.sub!);

  const openOnboardingModal = () => {
    if (unfinishedOnboardingProcesses.length) {
      openModal(MODALS.ContinueOnboarding);
    } else {
      openModal(MODALS.UpgradePlan);
    }
  };

  return { openOnboardingModal };
};
