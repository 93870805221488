import { Toggle } from '@/components/Toggle.tsx';
import { Button } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useState } from 'react';
import { useBuyPlan } from '@/api/pricing/buy-plan.ts';

export const UpgradePlanForm = () => {
  const { closeModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const [annualBillingChecked, setAnnualBillingChecked] = useState(true);
  const { buyPlan } = useBuyPlan();

  const onUpgradePlan = async () => {
    closeModal();
    showLoader();
    buyPlan(annualBillingChecked).catch(() => {
      hideLoader();
    });
  };

  return (
    <>
      <div className="mb-7 mr-7 mt-10 flex flex-col justify-between">
        <div>
          <h2 className="mb-3 flex w-[70%] flex-wrap items-center gap-3 text-h2-desktop">
            <span className="italic">Unlock premium features</span>
            <div className="rounded-lg bg-purple-200 p-2">
              <img
                src="/generate-black.svg"
                alt=""
              />
            </div>
            <span>with</span>
            <span className="rounded-md bg-gradient-to-r from-[#db34f7] to-[#4734f7] px-3 text-h4-desktop text-white">
              Pro
            </span>
          </h2>

          <div className="mb-6 text-body-md text-neutrals-900">Upgrade now to get:</div>

          <div className="mb-10 grid grid-cols-2 gap-x-8 gap-y-6">
            {[
              {
                title: 'Unlimited Credits',
                description: 'Enjoy unrestricted access to generate as much as you need.',
              },
              {
                title: 'Unlimited Team Seats',
                description: 'Collaborate effortlessly with your entire team, no matter its size.',
              },
              {
                title: 'Additional Workspace Projects',
                description: 'Manage up to 3 projects within each workspace.',
              },
              {
                title: 'Expanded Data Room Storage',
                description: 'Securely store up to 1 GB of data in each workspace.',
              },
              {
                title: 'Flows Templates Library',
                description:
                  'Access a comprehensive library of ready-to-use strategic flow documents anytime, without limits.',
              },
            ].map((elem, index) => (
              <div
                key={index}
                className="flex items-start gap-2"
              >
                <img
                  src="/circle-check-black.svg"
                  alt=""
                />
                <div className="flex flex-col gap-1">
                  <div className="text-subtitle-sm">{elem.title}</div>
                  <div className="text-body-sm text-neutrals-700">{elem.description}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col">
          <div className="mb-10 flex min-h-10 items-center gap-3">
            <div className="text-h2-desktop">{annualBillingChecked ? '$166' : '$199'}</div>
            <div className="flex grow flex-col">
              <div className="text-sm font-medium leading-[17.50px]">USD/month</div>
              {annualBillingChecked && (
                <div className="mt-0.5 text-xs leading-[18px] text-neutrals-700">
                  billed yearly as <span className="font-medium text-neutrals-1000">$1 990</span>
                </div>
              )}
            </div>
            <div className="rounded-md bg-positive-200 px-2 py-1 text-positive-800">Save 20%</div>
            <div>
              <Toggle
                onChange={setAnnualBillingChecked}
                checked={annualBillingChecked}
              />
            </div>
          </div>
          <Button
            color="primary"
            className="mb-4"
            size="lg"
            onClick={onUpgradePlan}
          >
            Start Free Trial
          </Button>

          <Link
            to={buildUrl([Urls.PRICING])}
            onClick={closeModal}
          >
            <Button
              color="secondary"
              size="lg"
              className="w-full"
            >
              Explore all plans
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};
