import { PersonaDetails } from '@/models/persona-details.interface';
import { Persona } from '@/models/persona.interface';

export const convertPersonaToPersonaDetails = (persona: Persona): PersonaDetails => {
  const values = persona.positionValues?.reduce(
    (
      acc: Record<number, string[]>,
      pv: {
        value: string;
        position: {
          id: number;
        };
      },
    ) => {
      acc[pv.position.id] = acc[pv.position.id] || [];
      acc[pv.position.id].push(pv.value);
      return acc;
    },
    {},
  );

  return {
    id: persona.id,
    name: persona.name,
    description: persona.description,
    segmentName: persona.segment?.name ?? null,
    positions: persona.template.positions.map((p) => ({
      id: p.id,
      label: p.label,
      value: values?.[p.id] ?? [],
      hierarchyPosition: p.hierarchyPosition,
    })),
    generationContextItemsIds: persona.generationContextItemsIds,
    createdAt: persona.createdAt,
    modifiedAt: persona.modifiedAt,
    deletedAt: persona.deletedAt,
  };
};
