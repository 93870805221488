import { Loader2 } from 'lucide-react';

export default function Loader({ isLoading, children }: { isLoading: boolean; children: React.ReactNode }) {
  return isLoading ? (
    <div className="flex h-full items-center justify-center">
      <Loader2 className="h-10 w-10 animate-spin" />
    </div>
  ) : (
    children
  );
}
