import { flexRender, Table } from '@tanstack/react-table';
import { DynamicRowData } from '../api/tables';
import { ColumnTypes } from './column-types.const.tsx';
import { ColumnType } from './table.inferfaces.ts';

interface DraggedColumnOverlayProps {
  id: string;
  table: Table<DynamicRowData>;
}

const DraggedColumnOverlay: React.FC<DraggedColumnOverlayProps> = ({ id, table }) => {
  const header = table.getHeaderGroups()[0].headers.find((h) => h.id === id);
  const columnWidths = table.getState().columnSizing;
  const { type } = header?.column.columnDef.meta as { type: ColumnType };

  if (!header) return null;

  return (
    <div
      className="border border-transparent"
      style={{ width: columnWidths && columnWidths[header.id] ? `${columnWidths[header.id]}px` : 'auto' }}
    >
      <div className="flex cursor-pointer items-center justify-between">
        <div className="flex grow items-center p-3">
          <span className="opacity-30">{ColumnTypes[type].icon}</span>
          <span className="ml-2 select-none text-nowrap text-sm font-normal text-[#27262c]">
            {flexRender(header.column.columnDef.header, header.getContext())}
          </span>
        </div>
        {/* <div>{getSortIcon(column)}</div> */}
      </div>
    </div>
  );
};

export default DraggedColumnOverlay;
