import { useUserPlan } from '@/api/pricing/get-user-plan';
import { useAuth } from '@/hooks/auth/useAuth';
import { useMemo } from 'react';

export const useTestABPaymentHooks = () => {
  const { user } = useAuth();
  const { userPlan, isLoading } = useUserPlan();

  const hasUserFullAccess = useMemo<boolean>(() => {
    const accountType = user?.['testABPayment'] as 'trial' | 'free' | undefined;
    return !accountType || accountType === 'free' || !!user?.org_id || !!userPlan;
  }, [user, userPlan]);

  return {
    hasUserFullAccess,
    isLoading,
  };
};
