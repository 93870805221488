import { IconButton } from '@/components/IconButton.tsx';
import { cn } from '@/helpers/cn';
import { CanvasPosition, CanvasTemplate, useCanvasTemplates } from '@/api/canvases/api.ts';
import { useContext, useState } from 'react';
import './MentionAccordionBody.scss';
import { PromptEditorContext } from '@/components/tiptap/extensions/DigitalFirst/editing/prompt-editor.context.ts';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';

export function CanvasMentionAccordionBody() {
  const { canvasTemplates, isLoading } = useCanvasTemplates();
  const [expandedCanvas, setExpandedCanvas] = useState<CanvasTemplate | null>(null);
  const { editor } = useContext(PromptEditorContext);

  const toggleExpandedCanvas = (canvas: CanvasTemplate) => {
    if (expandedCanvas?.id === canvas.id) {
      setExpandedCanvas(null);
    } else {
      setExpandedCanvas(canvas);
    }
  };

  const handleAddCanvasToPrompt = (canvas: CanvasTemplate) => {
    editor
      ?.chain()
      .focus()
      .insertContent({
        type: MentionReferenceType.CANVAS,
        attrs: {
          id: canvas.id,
          label: canvas.name,
        },
      })
      .insertContent(' ') // add an extra space after the mention
      .run();
  };

  const handleAddCanvasPositionToPrompt = (canvas: CanvasTemplate, position: CanvasPosition) => {
    editor
      ?.chain()
      .focus()
      .insertContent({
        type: MentionReferenceType.CANVAS,
        attrs: {
          id: canvas.id,
          label: `${canvas.name}/${position.label}`,
        },
      })
      .insertContent(' ') // add an extra space after the mention
      .run();
  };

  return (
    <>
      <div className="mention-accordion">
        <div className="mention-accordion__header">Add to prompt</div>
        {!isLoading && !!canvasTemplates?.length && (
          <div className="mention-accordion__list">
            {canvasTemplates.map((canvas) => (
              <div
                key={canvas.id}
                className="mention-accordion__add-button-wrapper"
              >
                <IconButton
                  icon="/add-black.svg"
                  size={20}
                  className="mention-accordion__add-button"
                  onClick={() => handleAddCanvasToPrompt(canvas)}
                />
                <div className="mention-accordion__list__item">
                  <button
                    onClick={() => toggleExpandedCanvas(canvas)}
                    className="mention-accordion__button-toggle"
                  >
                    {/* placeholder for IconButton */}
                    <div className="mention-accordion__button-toggle__placeholder"></div>
                    <span className="mention-accordion__button-toggle__title">{canvas.name}</span>
                    <img
                      src="/chevron-down-black.svg"
                      className={cn('mention-accordion__button-toggle__icon', {
                        'rotate-180': expandedCanvas?.id === canvas.id,
                      })}
                      alt=""
                      width={16}
                      height={16}
                    />
                  </button>

                  {expandedCanvas?.id === canvas.id && (
                    <div className="mention-accordion__badge-list">
                      {canvas.positions.map((position) => (
                        <button
                          key={position.id}
                          className="mention-accordion__badge"
                          onClick={() => handleAddCanvasPositionToPrompt(canvas, position)}
                        >
                          <img
                            src="/add-violet.svg"
                            alt=""
                            width={12}
                            height={12}
                          />
                          <span className="text-violet-1000">{position.label}</span>
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
