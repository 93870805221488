import { EditableCellProps } from '../interface.ts';
import { DropdownMenu } from '../../../shared/DropdownMenu.tsx';
import DropdownMenuPanel from '../../../shared/DropdownMenuPanel.tsx';
import { DateTime } from 'luxon';
import { CalendarDatePicker } from '../../CalendarDatePicker.tsx';

export const DateCell = ({ value, onChange, onBlur }: EditableCellProps<string, HTMLInputElement>) => {
  const onDropdownClose = () => {
    onBlur();
  };

  return (
    <>
      <DropdownMenu
        placement="bottom-start"
        onDropdownClose={onDropdownClose}
        DropdownControl={
          <>
            <DropdownMenuPanel className="w-[320px]">
              <CalendarDatePicker
                value={value}
                onChange={onChange}
              />
            </DropdownMenuPanel>
          </>
        }
      >
        {(props) => (
          <div
            {...props}
            className="flex size-full items-start p-3"
          >
            {!!value && DateTime.fromISO(value).toFormat('dd.MM.yyyy')}
          </div>
        )}
      </DropdownMenu>
    </>
  );
};
