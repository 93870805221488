import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { closeSideModal, useSideModalContext } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { PersonaDetails } from '@/models/persona-details.interface.ts';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useFormik } from 'formik';
import { ToolName } from '../../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { mutate } from 'swr';
import { CanvasPosition } from '@/api/canvases/api.ts';
import { setPersonaPositionrocessingState } from '../../persona/persona.store.ts';
import { useGeneratePersonaPosition } from '@/api/segments/persona/generate-persona-position.ts';
import { useUpdatePersona } from '@/api/segments/persona/update-persona.ts';
import { GenericCanvasGenerationContextSideModalBody } from '@/components/GenericCanvasGenerationContextSideModalBody.tsx';

export const ForcedPersonaPositionGenerationContextSideModalBody = () => {
  const { dataRoomItems } = useDataRoomItems();
  const { personaDetails, position } = useSideModalContext<{
    personaDetails: PersonaDetails;
    position: CanvasPosition;
  }>();
  const { listenerOn } = useWebSocketMessageListener();
  const { generatePersonaPosition } = useGeneratePersonaPosition();
  const { updatePersona } = useUpdatePersona();

  const formik = useFormik<{ generationContextItemsIds: string[] }>({
    initialValues: {
      generationContextItemsIds: [],
    },
    onSubmit: async ({ generationContextItemsIds }) => {
      closeSideModal();
      setPersonaPositionrocessingState(personaDetails.id, position.id, true);
      await updatePersona(personaDetails.id, { generationContextItemsIds });
      const wsChannel = `${ToolName.CANVAS_FILLER_POSITION}: ${uuid()}`;
      generatePersonaPosition(personaDetails.id, position.id, wsChannel);

      const { listenerOff } = listenerOn(wsChannel, async () => {
        await mutate(`/persona-canvas/${personaDetails.id}`);
        setPersonaPositionrocessingState(personaDetails.id, position.id, false);
        listenerOff();
      });
    },
  });

  return (
    <GenericCanvasGenerationContextSideModalBody
      formik={formik}
      dataRoomItems={dataRoomItems}
      submitButtonText="Generate"
    />
  );
};
