import { WebSearcherPage } from '../../web-searcher/WebSearcherPage.tsx';
import { useWebSearcherMenuStore } from '../../web-searcher/web-searcher.store.ts';
import { useEffect } from 'react';
import { setUnFinishedOnboardingProcesses, useUnFinishedOnboardingProcesses } from '../onboarding-store.ts';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';
import { useAuth } from '@/hooks/auth/useAuth.ts';

export const OnboardingWebSearcher = () => {
  const { isMenuOpen, setIsMenuOpen } = useWebSearcherMenuStore();
  const { user } = useAuth();
  const unFinishedOnboardingProcesses = useUnFinishedOnboardingProcesses(user!.sub!);

  useEffect(() => {
    setIsMenuOpen(false);
    setUnFinishedOnboardingProcesses(
      user!.sub!,
      unFinishedOnboardingProcesses.filter((type) => type !== OnboardingProcessType.WEB_SEARCHER),
    );
  }, []);

  return !isMenuOpen ? <WebSearcherPage onboardingMode={true} /> : null;
};
