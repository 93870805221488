import { EditableCellProps } from '../interface';
import { useRef } from 'react';
import { v4 as uuid } from 'uuid';

export const CheckboxCell = ({ value, onChange, innerRef }: EditableCellProps<boolean, HTMLInputElement>) => {
  const inputId = useRef(uuid());

  return (
    <label
      htmlFor={inputId.current}
      className="flex h-full w-full items-center p-3"
    >
      <input
        ref={innerRef}
        id={inputId.current}
        type="checkbox"
        checked={value}
        onChange={(e) => {
          onChange(e.target.checked);
        }}
        className="self-center"
      />
    </label>
  );
};
