import { useApi } from '@/api/api.ts';
import { mutate } from 'swr';
import { ProductCanvasDto } from '@/api/products/ProductCanvasDto.ts';

export const useImportFileAsProductCanvas = () => {
  const { postFetcher } = useApi({ contentType: 'multipart/form-data' });

  const importFileAsProductCanvas = async (file: File): Promise<ProductCanvasDto> => {
    const formData = new FormData();
    formData.append('file', file);
    const productCanvas = await postFetcher('/product-canvas/file', { arg: formData });
    await mutate('/product-canvas/by-workspace');
    return productCanvas;
  };

  return {
    importFileAsProductCanvas,
  };
};
