import { Breadcrumb } from '@/models/breadcrumb.interface';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export function Breadcrumbs({ breadcrumbs }: { breadcrumbs: Breadcrumb[] }) {
  const maxCharacters = useMemo(() => {
    if (window.innerWidth < 1280) {
      return 15;
    }
    if (window.innerWidth < 1440) {
      return 20;
    }
    if (window.innerWidth < 1670) {
      return 30;
    }
    return 50;
  }, [window.innerWidth]);

  const shorten = (breadcrumb: Breadcrumb) => {
    const max = maxCharacters ?? 50;

    if (breadcrumb.label.length > max) {
      return <span title={breadcrumb.label}>{`${breadcrumb.label.slice(0, maxCharacters)}...`}</span>;
    }
    return breadcrumb.label;
  };

  return (
    <>
      {breadcrumbs.map((breadcrumb, index) =>
        index === breadcrumbs.length - 1 ? (
          <span
            key={index}
            className="font-medium"
          >
            {shorten(breadcrumb)}
          </span>
        ) : (
          <span
            key={index}
            className="text-text-secondary"
          >
            <Link
              className="hover:underline"
              to={breadcrumb.href}
            >
              {shorten(breadcrumb)}
            </Link>{' '}
            /{' '}
          </span>
        ),
      )}
    </>
  );
}
