import { useApi } from '../api';
import { mutate } from 'swr';
import { useTriggerEvent } from '../events/api';
import { TagManagerEvent } from '../events/model';

export const useDeleteWorkspace = (): {
  deleteWorkspace: (workspaceId: number) => Promise<void>;
} => {
  const { deleteFetcher } = useApi();
  const { triggerDataLayer } = useTriggerEvent();

  const deleteWorkspace = async (workspaceId: number) => {
    await deleteFetcher(`/workspace/${workspaceId}`);
    await mutate('/workspace/by-user');
    triggerDataLayer(TagManagerEvent.user_deleted_workspace, {});
  };

  return { deleteWorkspace };
};
