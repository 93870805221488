import { Row } from '@tanstack/react-table';
import { DateTime } from 'luxon';

export const dateBetween = <TData>(
  row: Row<TData>,
  columnId: string,
  filterValue: [DateTime | null, DateTime | null],
) => {
  const cellValue = row.getValue(columnId) as string;

  if (!cellValue) return false;

  const date = DateTime.fromISO(cellValue);

  return (!filterValue[0] || date >= filterValue[0]) && (!filterValue[1] || date <= filterValue[1]);
};

dateBetween.autoRemove = (val: [string, string]) => !val[0] && !val[1];

dateBetween.resolveFilterValue = (val: [string, string]) => [
  val[0] ? DateTime.fromISO(val[0]) : null,
  val[1] ? DateTime.fromISO(val[1]) : null,
];
