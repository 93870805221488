import { useApi } from '../../api.ts';
import { Flow } from '@/models/flow.interface.ts';
import { TiptapDocumentType } from '@/enums/tiptap-document-type.enum.ts';
import { useTriggerEvent } from '@/api/events/api.ts';
import { TagManagerEvent } from '@/api/events/model.ts';

export const useCreateMyFlow = (): {
  createMyFlow: (name: string, projectId: number, type?: TiptapDocumentType) => Promise<Flow>;
} => {
  const { postFetcher } = useApi();
  const { triggerDataLayer } = useTriggerEvent();

  const createMyFlow = async (name: string, projectId: number, type: TiptapDocumentType = TiptapDocumentType.FLOW) => {
    triggerDataLayer(TagManagerEvent.user_created_flow, {});
    return await postFetcher(`/flow/my-flow`, {
      arg: {
        name,
        projectId,
        type,
      },
    });
  };

  return { createMyFlow };
};
