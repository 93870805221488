import { PersonaDetails } from '@/models/persona-details.interface';
import { Button, TextInput } from 'flowbite-react';
import { useState } from 'react';
import { useUpdatePersona } from '@/api/segments/persona/update-persona.ts';
import { PersonaUpdatePayload } from '@/models/persona-update-payload.interface.ts';
import { mutate } from 'swr';
import EditIcon from '@assets/edit.svg';

export const PersonaMainInfoCard = ({ personaDetails }: { personaDetails: PersonaDetails }) => {
  const [name, setName] = useState<string>(personaDetails.name);
  const [description, setDescription] = useState<string>(personaDetails.description);
  const [isEditing, setIsEditing] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { updatePersona } = useUpdatePersona();

  const onSave = async () => {
    if (!name || !description) {
      return;
    }

    const payload: PersonaUpdatePayload = {
      name,
      description,
    };
    setIsProcessing(true);
    await updatePersona(personaDetails.id, payload);
    await mutate(`/persona-canvas/${personaDetails.id}`);
    setIsProcessing(false);
    setIsEditing(false);
  };

  return (
    <div className="relative flex overflow-hidden rounded-l-lg rounded-r-lg px-7 py-5 transition duration-150 hover:scale-[1.005] hover:shadow-[0px_4px_20px_-1px_#97959F3D]">
      {isProcessing && (
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center bg-white opacity-80">
          <img
            src="/loading-blue.svg"
            className="animate-spin-slow"
            alt=""
          />
        </div>
      )}

      <div className="absolute right-4 top-4">
        <div className="flex gap-2">
          {isEditing ? (
            <>
              <Button
                color="transparent"
                size="sm"
                onClick={() => {
                  setIsEditing(false);
                  setName(personaDetails.name);
                  setDescription(personaDetails.description);
                }}
              >
                Cancel
              </Button>

              <Button
                color="secondary"
                size="sm"
                onClick={onSave}
              >
                <span className="text-primary-600">Save changes</span>
              </Button>
            </>
          ) : (
            <>
              <Button
                color="transparent"
                size="sm"
                onClick={() => {
                  setIsEditing(true);
                }}
              >
                <EditIcon />
                Edit
              </Button>
            </>
          )}
        </div>
      </div>

      <img
        src="/user-black.svg"
        width={164}
        height={164}
        alt="Persona image"
        className="size-[164px] scale-125 rounded-full"
      />
      <div className="ml-8 flex grow flex-col justify-center">
        {isEditing ? (
          <>
            <TextInput
              className="mb-3"
              theme={{
                field: {
                  input: {
                    sizes: {
                      md: 'px-4 py-1 h-7 relative -left-4 leading-[28px] text-[20px] font-medium',
                    },
                  },
                },
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Title"
            />
            <TextInput
              value={description}
              theme={{
                field: {
                  input: {
                    sizes: {
                      md: 'px-4 py-1 h-7 relative -left-4 leading-[28px] text-[16px] font-medium text-neutrals-700',
                    },
                  },
                },
              }}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Name"
            />
          </>
        ) : (
          <>
            <h2 className="mb-3 text-h3-desktop">{name}</h2>
            <div className="text-body-lg">{description}</div>
          </>
        )}
      </div>
    </div>
  );
};
