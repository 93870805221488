import { Button } from 'flowbite-react';
import { useAgentPanel } from './agent-panel.store.ts';
import ChatIcon from '@assets/chat.svg';

export const AgentPanelButton = () => {
  const { toggleAgentPanel } = useAgentPanel();

  return (
    <Button
      color="secondary"
      size="sm"
      onClick={toggleAgentPanel}
    >
      <ChatIcon className="size-4" />
      Ask assistant
    </Button>
  );
};
