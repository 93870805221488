import { Button } from 'flowbite-react';
import { useImportFileAsProductCanvas } from '@/api/products/import-file-as-product-canvas.ts';
import { useRef } from 'react';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import UploadIcon from '@assets/upload.svg';
import toast from 'react-hot-toast';
import { useAuth0 } from '@auth0/auth0-react';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { setProductCanvasImportingEnd, setProductCanvasImportingStart } from '../product-canvases.store.ts';

export const ProductCanvasImportFileButton = () => {
  const { importFileAsProductCanvas } = useImportFileAsProductCanvas();
  const hiddenInputRef = useRef<HTMLInputElement>(null);
  const { showLoader, hideLoader } = useGlobalLoader();
  const { user } = useAuth0();
  const { listenerOn } = useWebSocketMessageListener();

  const handleImportFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) return;

    if (!file.name.endsWith('.csv')) {
      toast.error('Please select a CSV file');
      return;
    }

    showLoader();
    const productCanvas = await importFileAsProductCanvas(file);
    hideLoader();

    const wsChannel = `product-canvas-import: ${user!.sub}`;

    setProductCanvasImportingStart(productCanvas._id);
    const { listenerOff } = listenerOn(wsChannel, () => {
      listenerOff();
      toast.success(`File imported successfully`);
      setProductCanvasImportingEnd(productCanvas._id);
    });
  };

  return (
    <>
      <Button
        color="primary"
        size="sm"
        onClick={() => hiddenInputRef.current?.click()}
      >
        <UploadIcon className="size-4 path:fill-white" />
        Import File
      </Button>

      <input
        ref={hiddenInputRef}
        type="file"
        accept=".csv"
        onChange={handleImportFile}
        className="hidden"
      />
    </>
  );
};
