import { ToolName } from '../../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { mutate } from 'swr';
import { PersonaDetails } from '@/models/persona-details.interface.ts';
import { setPersonaPositionrocessingState, usePersonaPositionProcessingStatus } from '../persona.store.ts';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useGeneratePersonaPosition } from '@/api/segments/persona/generate-persona-position.ts';
import { useUpdatePersona } from '@/api/segments/persona/update-persona.ts';
import { PersonaUpdatePayload } from '@/models/persona-update-payload.interface.ts';
import { CanvasPosition } from '@/api/canvases/api.ts';
import { CanvasPositionCard } from '@/components/CanvasPositionCard.tsx';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { ForcedPersonaPositionGenerationContextSideModalBody } from '../../components/modals/ForcedPersonaPositionGenerationContextSideModalBody.tsx';
import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';

export const PersonaCanvasPositionCard = ({
  personaDetails,
  position,
  index,
}: {
  personaDetails: PersonaDetails;
  position: CanvasPosition;
  index: number;
}) => {
  const isProcessing = usePersonaPositionProcessingStatus(personaDetails.id, position.id);
  const { listenerOn } = useWebSocketMessageListener();
  const { generatePersonaPosition } = useGeneratePersonaPosition();
  const { updatePersona } = useUpdatePersona();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { isSmallDataRoom } = useDataRoomItems();

  const onSave = async (newPositionValue: string[]) => {
    const newPositions = personaDetails.positions.filter((p) => p.id !== position.id);
    newPositions.push({
      ...position,
      value: newPositionValue,
    });
    const payload: PersonaUpdatePayload = {
      positionValue: newPositions
        .filter((p) => p.value && p.value.length > 0)
        .flatMap((p) => p.value.map((v) => ({ positionTemplateId: p.id, value: v }))),
    };
    showLoader();
    await updatePersona(personaDetails.id, payload);
    hideLoader();
  };

  const onGenerate = async () => {
    if (isSmallDataRoom || personaDetails.generationContextItemsIds) {
      setPersonaPositionrocessingState(personaDetails.id, position.id, true);
      const wsChannel = `${ToolName.CANVAS_FILLER_POSITION}: ${uuid()}`;

      generatePersonaPosition(personaDetails.id, position.id, wsChannel);

      const { listenerOff } = listenerOn(wsChannel, async () => {
        await mutate(`/persona-canvas/${personaDetails.id}`);
        setPersonaPositionrocessingState(personaDetails.id, position.id, false);
        listenerOff();
      });
    } else {
      openSideModal(<ForcedPersonaPositionGenerationContextSideModalBody />, { personaDetails, position });
    }
  };

  return (
    <CanvasPositionCard
      position={position}
      index={index}
      isProcessing={isProcessing}
      onSave={onSave}
      onRegenerate={onGenerate}
    />
  );
};
