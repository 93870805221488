import { Tag } from './TagInput';
import { mapTagColorToCssClasses } from '@/helpers/map-tag-color-to-css-classes.ts';
import { cn } from '@/helpers/cn.ts';

export const TagElement = ({
  tag,
  withRemoveOption,
  onRemove,
}: {
  tag: Tag;
  withRemoveOption?: boolean;
  onRemove?: () => void;
}) => {
  return (
    <>
      <div
        className={cn(
          `flex items-center text-nowrap rounded-lg px-2 py-1 text-body-md ${mapTagColorToCssClasses(tag.color)}`,
          {
            '!pr-0': withRemoveOption,
          },
        )}
      >
        {tag.name}
        {withRemoveOption && (
          <button
            className="px-2 py-1"
            onClick={onRemove}
          >
            <img
              src="/close-dark.svg"
              width={12}
              height={12}
              alt="Remove tag"
            />
          </button>
        )}
      </div>
    </>
  );
};
