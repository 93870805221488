import { useApi } from '@/api/api.ts';
import { PersonaUpdatePayload } from '@/models/persona-update-payload.interface.ts';
import { mutate } from 'swr';

export const useUpdatePersona = (): {
  updatePersona: (personaId: number, payload: PersonaUpdatePayload) => Promise<void>;
} => {
  const { patchFetcher } = useApi();

  const updatePersona = async (personaId: number, payload: PersonaUpdatePayload) => {
    await patchFetcher(`/persona-canvas/${personaId}`, {
      arg: payload,
    });
    await mutate(`/persona-canvas/${personaId}`);
  };

  return {
    updatePersona,
  };
};
