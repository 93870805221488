import { useApi } from '@/api/api.ts';
import useSWR from 'swr';
import { ProductCanvasDto } from '@/api/products/ProductCanvasDto.ts';

export const useProductCanvas = (productCanvasId: string) => {
  const { getFetcher } = useApi();

  const { data, ...rest } = useSWR<ProductCanvasDto>(`/product-canvas/${productCanvasId}`, getFetcher);

  return {
    productCanvas: data,
    ...rest,
  };
};
