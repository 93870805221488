import React, { useState } from 'react';
import TableSelector from './TableSelector';
import SmartTable from '../table/SmartTable';

const TableScreen: React.FC = () => {
  const [currentTable, setCurrentTable] = useState<string | undefined>(undefined);

  return (
    <div className="App flex h-screen flex-col">
      <div className="px-4 pt-4">
        <TableSelector
          currentTable={currentTable}
          setCurrentTable={(tableId) => setCurrentTable(tableId)}
        />
      </div>
      {currentTable && (
        <div className="grow overflow-y-scroll rounded-2xl bg-white p-5">
          <SmartTable tableId={currentTable} />
        </div>
      )}
    </div>
  );
};

export default TableScreen;
