import { useGlobalLoaderInternalState } from './global-loader.store.ts';

export function GlobalLoader() {
  const { show, message } = useGlobalLoaderInternalState();

  return (
    <>
      {show && (
        <div className="fixed left-0 top-0 z-[1000] flex h-screen w-screen items-center justify-center bg-white opacity-80">
          <div className="flex flex-col items-center gap-4">
            <img
              src="/loading-blue.svg"
              className="animate-spin-slow"
              alt=""
            />
            {message && <div className="text-center text-body-lg">{message}</div>}
          </div>
        </div>
      )}
    </>
  );
}
