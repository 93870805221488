import { Modal } from 'flowbite-react';
import { useGlobalModalInternalState } from './global-modal-store.ts';
import { cn } from '@/helpers/cn';

export function GlobalModal() {
  const { open, props, closeModal } = useGlobalModalInternalState();
  const hasHeader = !!props.header;

  return (
    <>
      {props.hideCloseButton && (
        <style>
          {`
            button[aria-label='Close'] {
              display: none;
            }
          `}
        </style>
      )}
      <style>
        {`
            div[role='dialog']:focus-visible {
              outline: none;
            }
        `}
      </style>
      <Modal
        show={open}
        onClose={closeModal}
        size={props.size}
        popup
        className={props.className}
      >
        {hasHeader && (
          <>
            <Modal.Header className="p-6 pb-2">
              <div className="mb-2 text-subtitle-lg">{props.header}</div>
              {props.subheader && <div className="text-body-sm text-text-secondary">{props.subheader}</div>}
            </Modal.Header>
          </>
        )}
        <Modal.Body className={cn({ 'pt-2': hasHeader }, { '!p-3': !hasHeader })}>
          {hasHeader ? (
            <div className="modal-divider mb-5"></div>
          ) : (
            <button
              aria-label="Close"
              className="absolute right-4 top-4 ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 transition hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
              type="button"
              onClick={closeModal}
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className="h-5 w-5"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
          )}
          <div>{props.body}</div>
        </Modal.Body>
      </Modal>
    </>
  );
}
