import { usePublicApi } from '@/api/public-api.ts';

export const useAskQuestionInPublicWebSearcherRoom = (): {
  askQuestionInPublicWebSearcherRoom: (
    roomId: number,
    question: string,
    historyId?: number,
    wsChannel?: string,
  ) => Promise<void>;
} => {
  const { publicPatchFetcher } = usePublicApi();

  const askQuestionInPublicWebSearcherRoom = async (
    roomId: number,
    question: string,
    historyId?: number,
    wsChannel?: string,
  ) => {
    return publicPatchFetcher(`/websearcher/room/${roomId}/ask-question`, {
      arg: {
        id: historyId,
        message: question,
        wsChannel,
      },
    });
  };

  return { askQuestionInPublicWebSearcherRoom };
};
