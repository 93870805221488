import { Column, Table } from '@tanstack/react-table';
import DropdownMenuPanel from '../../shared/DropdownMenuPanel';
import ColumnNameWithIcon from './ColumnNameWithIcon';
import { useMemo } from 'react';
import { MdOutlineArrowCircleLeft } from 'react-icons/md';
import { DynamicRowData } from '../../api/tables';

const ColumnSelector = ({
  table,
  onSelect,
  onBack,
  hiddenColumnIds,
}: {
  table: Table<DynamicRowData>;
  onSelect: (columnId: string) => void;
  onBack?: () => void;
  hiddenColumnIds?: string[];
}) => {
  const columns = table.getAllLeafColumns();
  const order = table.getState().columnOrder;

  const filteredAndOrderedColumns = useMemo(() => {
    const result: Column<DynamicRowData, unknown>[] = [];
    const allColumnIds = columns.map((c) => c.id);

    const sortedColumnIds = [...order, ...allColumnIds.filter((id) => !order.includes(id))];

    // Filter out hidden columns
    const visibleColumnIds = sortedColumnIds.filter((id) => !hiddenColumnIds?.includes(id));

    visibleColumnIds.forEach((columnId) => {
      const column = columns.find((c) => c.id === columnId);
      if (column) {
        result.push(column);
      }
    });
    return result;
  }, [columns, hiddenColumnIds, order]);

  return (
    <>
      <DropdownMenuPanel.Header>
        <div className="mb-2 flex items-center">
          <div
            onClick={onBack}
            className="flex h-8 w-8 cursor-pointer items-center hover:text-gray-500"
          >
            <MdOutlineArrowCircleLeft className="mr-2 h-5 w-5" />
          </div>
          Select Column
        </div>
      </DropdownMenuPanel.Header>
      {filteredAndOrderedColumns.map((column) => (
        <DropdownMenuPanel.HoverableItem
          key={column.id}
          onClick={() => onSelect(column.id!)}
        >
          <ColumnNameWithIcon
            key={column.id}
            column={column}
          />
        </DropdownMenuPanel.HoverableItem>
      ))}
    </>
  );
};

export default ColumnSelector;
