import { Tag } from './TagInput';

export const TagColors: Array<Tag['color']> = [
  'Gray',
  'Green',
  'Red',
  'Pink',
  'Violet',
  'Purple',
  'Blue',
  'Turquoise',
  'Mint',
  'Lime',
  'Olive',
  'Yellow',
  'Orange',
  'Brown',
] as const;
