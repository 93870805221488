import { EditableCellProps } from '../interface.ts';

export const NumberCell = ({ value, onChange, innerRef }: EditableCellProps<string, HTMLInputElement>) => (
  <input
    ref={innerRef}
    type="number"
    className="reset-input h-full w-full rounded !p-3 text-right !text-body-md focus:!ring-2 focus:!ring-inset focus:!ring-black"
    value={value}
    autoFocus
    onChange={(e) => onChange(e.target.value)}
  />
);
