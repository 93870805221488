import { useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { DynamicRowData, useTableUpdateData } from '../api/tables';

export const useDataSync = (tableId: string, debounceRate: number = 800) => {
  const updates = useRef<Record<string, DynamicRowData>>({});
  const removedRows = useRef<string[]>([]);

  const updateData = useTableUpdateData();

  const debouncedSync = useDebouncedCallback(
    async (
      tableId: string,
      updateFunction: (
        tableId: string,
        changes: {
          updatedRows: DynamicRowData[];
          removedIds: string[];
        },
      ) => unknown,
    ) => {
      const changes = {
        updatedRows: Object.values(updates.current),
        removedIds: removedRows.current,
      };
      updates.current = {};
      removedRows.current = [];

      await updateFunction(tableId, changes);
    },
    debounceRate,
  );

  const updateRow = (row: DynamicRowData) => {
    updates.current[row._id] = row;
    debouncedSync(tableId, updateData);
  };

  const removeRow = (row: DynamicRowData) => {
    const rowId = row._id;
    delete updates.current[rowId];
    removedRows.current.push(rowId);
    debouncedSync(tableId, updateData);
  };

  const flush = () => {
    debouncedSync.flush();
  };

  return {
    updateRow,
    removeRow,
    flush,
  };
};
