import { useApi } from '../api';
import { mutate } from 'swr';
// import { useDataRoomId } from '@/state/app-store.ts';

export const useDeleteProductCanvas = () => {
  const { deleteFetcher } = useApi();

  const deleteProductCanvas = async (id: string) => {
    await deleteFetcher(`/table/${id}`);
    mutate(`/product-canvas/by-workspace`);
  };

  return {
    deleteProductCanvas,
  };
};
