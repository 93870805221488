import { Button } from 'flowbite-react';

export const SemrushUpsellButton = () => {
  return (
    <Button
      color="secondary"
      size="sm"
      onClick={() => window.SM.client('requestInAppPurchase')}
    >
      Semrush Upsell
    </Button>
  );
};
