import { Column } from '@tanstack/react-table';
import { ColumnType } from '../table.inferfaces';
import { DynamicRowData } from '../../api/tables';
import { ColumnTypes } from '../column-types.const.tsx';

const ColumnNameWithIcon = ({ column }: { column: Column<DynamicRowData, unknown> }) => {
  const type = (column?.columnDef?.meta?.type as ColumnType) || 'default';
  return (
    <div className="flex items-center gap-2 py-1">
      {ColumnTypes[type].icon}
      <span>{column?.columnDef?.header as string}</span>
    </div>
  );
};

export default ColumnNameWithIcon;
