import { Editor } from '@tiptap/core';
import { getNode } from './getNode';
import { getTextContentBeetweenHeadings } from './getTextContentBeetweenHeadings';

import { useState } from 'react';
import { ExecuteToolFieldsPayload, ExecuteToolPayload, Tool, ToolName, ToolOutput } from 'src/libs/tools/type.model';
import toast from 'react-hot-toast';
import { useCreateTool, useExecuteTool } from 'src/libs/tools/api';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage';
import { v4 as uuid } from 'uuid';
import { sleep } from '@/components/tiptap/extensions/DigitalFirst/helpers/sleep';
import { useGetCanvasesFromPrompt } from './getCanvasesFromPrompt.ts';
import { canvasToText } from './canvasToText';

import fileToBase64 from '@/helpers/file-to-base64.ts';
import { useParams } from 'react-router-dom';
import { turndownService } from '../turndown/turndown.service.ts';
import { getImageBeetweenHeadings } from './getImageBeetweenHeadings.ts';
import { parseOutput } from './parseOutput.ts';
import { useInjectPersonasIntoPrompt } from '@/components/tiptap/extensions/DigitalFirst/helpers/injectPersonasIntoPrompt.ts';
import { useTiptapResourceChunks } from './tiptap-api/getTiptapResourceSummary.ts';
import { getDataRoomItemChunks } from './getDataRoomItemChunks.ts';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { useProductCanvases } from '@/api/products/get-product-canvases.ts';
import { useTableLoadData } from '../../../../../../libs/tables/api/tables.ts';
import { injectProductCanvasesIntoPrompt } from '@/components/tiptap/extensions/DigitalFirst/helpers/injectProductCanvasesIntoPrompt.ts';

export const useExecuteAllFields = () => {
  const [isExecute, setIsExecute] = useState(false);
  const { execute } = useExecuteField();

  const executeAll = async (editor: Editor) => {
    setIsExecute(true);

    const nodesPos = editor.$nodes('dfGenOutput') ?? [];

    for (const nodePos of nodesPos) {
      const nodeId = nodePos?.node?.attrs?.id;
      await sleep(1000);
      await execute(editor, nodeId);
    }

    setIsExecute(false);
  };

  return {
    executeAll,
    isExecute,
  };
};

const usePrepareToExecute = () => {
  const { executeTool } = useExecuteTool();
  const { listenerOn } = useWebSocketMessageListener();

  return async (
    editor: Editor,
    tool: Tool,
    values: Record<string, string | string[]>,
    toolId: number,
    nodeId: string,
  ) => {
    const wsChannel = `${tool?.name}: ${uuid()}`;
    const fields = await Promise.all(
      await Object.keys(values).map(async (key) => {
        let value = values[key] as unknown;

        if (value instanceof File) {
          value = await fileToBase64(value);
        }

        return {
          key: key,
          value: value as string | number | boolean,
        } as ExecuteToolFieldsPayload;
      }),
    );

    const payload: ExecuteToolPayload = {
      id: toolId,
      toolName: tool.name,
      fields: fields,
      wsChannel,
    };

    try {
      setTimeout(() => executeTool(payload), 100);
    } catch {
      return; // error is handled by WebSocketErrorListener
    }

    await new Promise((resolve) => {
      let output = '';

      const { listenerOff } = listenerOn(wsChannel, async (data) => {
        try {
          if (tool?.isStreamable) {
            if (typeof data === 'object' && (data as Record<string, boolean>).done) {
              listenerOff();
            } else {
              output = (output + data) as string;
              const node = getNode(editor, nodeId);
              if (!node) return;

              node.content = parseOutput(output as ToolOutput, tool.category, tool.name);
            }
          } else {
            const node = getNode(editor, nodeId);
            if (!node) return;

            node.content = parseOutput(data as ToolOutput, tool.category, tool.name);
            listenerOff();
          }
        } catch {
          // silent
        }

        resolve(true);
      });
    });
  };
};

export const useExecuteField = () => {
  const [isExecuting, setIsExecuting] = useState(false);
  const prepareToExecute = usePrepareToExecute();
  const { dataRoomItems } = useDataRoomItems();
  const { tiptapResourceChunks } = useTiptapResourceChunks();
  const { getCanvasesFromPrompt } = useGetCanvasesFromPrompt();
  const { injectPersonasIntoPrompt } = useInjectPersonasIntoPrompt();
  const { createTool } = useCreateTool();
  const { projectId } = useParams();
  const { productCanvases } = useProductCanvases();
  const loadTableData = useTableLoadData();

  const execute = async (editor: Editor, nodeId: string) => {
    setIsExecuting(true);
    const node = getNode(editor, nodeId);

    if (!node) {
      return toast.error('Something went wrong. Please try again.');
    }

    const selectedTool = node?.attributes?.selectedtool as Tool;
    const toolData = node?.attributes?.tooldata as Record<string, string | string[]>;
    let toolId = node?.attributes?.selectedtoolid;

    if (!toolId) {
      const tool = node.attributes?.selectedtool;
      const { id } = await createTool(tool.name, +projectId!);
      node.setAttribute({ selectedtoolid: id });

      toolId = id;
    }

    const toolDataCopy = { ...toolData };
    const injectableMentionFields = ['prompt', 'query', 'question'];

    for (const key in toolDataCopy) {
      if (!injectableMentionFields.includes(key)) {
        continue;
      }

      toolDataCopy[key] = getTextContentBeetweenHeadings(editor, toolDataCopy[key] as string);
      toolDataCopy[key] = await getDataRoomItemChunks(toolDataCopy[key], dataRoomItems, tiptapResourceChunks);
      const canvases = await getCanvasesFromPrompt(toolDataCopy[key]);
      toolDataCopy[key] = canvasToText(canvases ?? [], toolDataCopy[key]);
      toolDataCopy[key] = injectPersonasIntoPrompt(toolDataCopy[key]);
      toolDataCopy[key] = await injectProductCanvasesIntoPrompt(toolDataCopy[key], productCanvases!, loadTableData);
      toolDataCopy[key] = turndownService.turndown(toolDataCopy[key]);
    }

    if (selectedTool.name === ToolName.ASK_VISION_MODEL) {
      toolDataCopy.images = getImageBeetweenHeadings(editor, toolDataCopy.images as string);
    }

    if (toolDataCopy?.url) {
      toolDataCopy.url = (toolDataCopy.url as string).replace('<p>', '').replace('</p>', '');
    }

    await prepareToExecute(editor, selectedTool, toolDataCopy, toolId, nodeId);

    setIsExecuting(false);
  };

  return {
    execute,
    isExecuting,
  };
};
