import TagManager from 'react-gtm-module';
import { useApi } from '../api';
import { TagManagerEvent, UserEvent } from './model';
import { useAuth } from '@/hooks/auth/useAuth';

export const useTriggerEvent = () => {
  const { postFetcher } = useApi();
  const { user } = useAuth();

  const trigerUserEvent = async (event: UserEvent): Promise<void> => {
    await postFetcher('/user-events/triggerEvent', { arg: { eventType: event } });
  };

  const triggerDataLayer = (event: TagManagerEvent, data: Record<string, string | number | undefined | null>) => {
    TagManager?.dataLayer({
      dataLayer: {
        event: event,
        distinct_id: user?.sub,
        user_id: user?.sub,
        ...data,
      },
    });
  };

  return {
    trigerUserEvent,
    triggerDataLayer,
  };
};
