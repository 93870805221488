import { useApi } from '@/api/api.ts';

export const useAskQuestionInWebSearcherRoom = (): {
  askQuestionInWebSearcherRoom: (
    roomId: number,
    question: string,
    historyId?: number,
    wsChannel?: string,
  ) => Promise<void>;
} => {
  const { patchFetcher } = useApi();

  const askQuestionInWebSearcherRoom = async (
    roomId: number,
    question: string,
    historyId?: number,
    wsChannel?: string,
  ) => {
    await patchFetcher(`/websearcher/room/${roomId}/ask-question`, {
      arg: {
        id: historyId,
        message: question,
        returnImages: true,
        returnVideos: true,
        returnSources: true,
        returnMaps: true,
        returnFollowUpQuestions: true,
        wsChannel,
      },
    });
  };

  return { askQuestionInWebSearcherRoom };
};
