import { IconButton } from '@/components/IconButton.tsx';
import { Link } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Card } from 'flowbite-react';
import { useDeletePersona } from '@/api/segments/persona/delete-persona.ts';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { mutate } from 'swr';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import { Persona } from '@/models/persona.interface.ts';

export const PersonaCard = ({ persona }: { persona: Persona }) => {
  const { deletePersona } = useDeletePersona();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { openDialog } = useGlobalDialog();
  const dataRoomId = useDataRoomId();

  const onDeletePersona = async (personaId: number) => {
    openDialog(DIALOGS.DeletePersona, async () => {
      showLoader();
      await deletePersona(personaId);
      await mutate(`/persona-canvas/segment/${dataRoomId}`);
      await mutate(`/persona-canvas/no-segment-personas/${dataRoomId}`);
      hideLoader();
    });
  };

  return (
    <>
      <div className="relative h-[112px]">
        <div className="absolute right-4 top-4">
          <IconButton
            icon="/trash-gray.svg"
            size={24}
            alt="Delete"
            onClick={() => onDeletePersona(persona.id)}
          />
        </div>
        <Link
          className="h-full"
          to={buildUrl([Urls.SEGMENTS, Urls.PERSONA_DETAILS], { personaId: persona.id })}
        >
          <Card className="h-full">
            <h3 className="mb-1 text-h5-desktop">{persona.name}</h3>
            <div className="text-body-md text-neutrals-700">{persona.description}</div>
          </Card>
        </Link>
      </div>
    </>
  );
};
