import { useSemrush } from '@/state/semrush.store';
import HelpCenterMenuItem from '@/layouts/MainLayout/components/HelpCenterMenuItem';
import { LeftSideNavMenuItem } from '@/layouts/MainLayout/components/LeftSideNavMenuItem.tsx';
import { UserMenuItem } from '@/layouts/MainLayout/components/UserMenuItem.tsx';

export function MenuFooter() {
  const { isSemrush } = useSemrush();

  return (
    <div className="mt-3 flex flex-col gap-1">
      <UserMenuItem />

      {!isSemrush && (
        <>
          <LeftSideNavMenuItem
            label="Notifications"
            leftIcon="/bell-black.svg"
            addons={
              <div className="flex size-5 items-center justify-center rounded-full bg-red-default text-caption-sm text-white">
                1
              </div>
            }
            disabled={true}
          />

          <HelpCenterMenuItem />
        </>
      )}
    </div>
  );
}
