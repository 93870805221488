import React, { useState } from 'react';
import { cn } from '@/helpers/cn';

export interface IconButtonProps {
  icon: string | React.ReactNode;
  size?: 32 | 24 | 20;
  alt?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  styleOnSelect?: string;
  className?: string;
}

export function IconButton({
  icon,
  alt = '',
  size = 32,
  onClick = () => {},
  styleOnSelect = '',
  className = '',
}: IconButtonProps) {
  const [selected, setSelected] = useState(false);

  const paddingMap = {
    32: 4,
    24: 3,
    20: 2,
  };

  const iconSize = size - paddingMap[size] * 2;

  const iconElement =
    typeof icon === 'string' ? (
      <img
        src={icon}
        alt={alt}
        style={{ width: iconSize, height: iconSize }}
        width={iconSize}
        height={iconSize}
      />
    ) : (
      icon
    );

  return (
    <button
      className={cn('bg-interactive-gray rounded-full p-1', className, { [styleOnSelect]: selected })}
      onClick={(e) => {
        setSelected(!selected);
        onClick(e);
      }}
      style={{ padding: paddingMap[size] }}
    >
      {iconElement}
    </button>
  );
}
