import { MutableRefObject } from 'react';
import { Instance, Props } from 'tippy.js';
import Mention from '@tiptap/extension-mention';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { DOMOutputSpec } from 'prosemirror-model';
import { PluginKey } from '@tiptap/pm/state';
import { mentionRender } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mentionRender.ts';
import { ProductCanvasDto } from '@/api/products/ProductCanvasDto.ts';

export const ProductCanvasMention = (
  tippy: MutableRefObject<Instance<Props>[]>,
  productCanvases: ProductCanvasDto[],
) => {
  return Mention.extend({ name: MentionReferenceType.PRODUCT_CANVAS }).configure({
    HTMLAttributes: {
      class: MentionReferenceType.PRODUCT_CANVAS,
    },
    renderText({ options, node }) {
      return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
    },
    renderHTML({ options, node }): DOMOutputSpec {
      const span = document.createElement('span');
      span.dataset.id = node.attrs.id;
      span.dataset.child = node.attrs.childId;
      span.dataset.label = node.attrs.label;
      span.dataset.type = MentionReferenceType.PRODUCT_CANVAS;
      span.classList.add('inline-block', 'px-2', 'bg-brown-100', 'rounded', 'text-brown-1000', 'text-body-md');

      span.innerHTML = `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;

      return span;
    },
    deleteTriggerWithBackspace: true,
    suggestion: {
      char: '%',
      pluginKey: new PluginKey(MentionReferenceType.PRODUCT_CANVAS),
      items: async ({ query }: { query: string }) => {
        const commands = productCanvases?.map((productCanvas) => ({
          id: productCanvas._id,
          name: productCanvas.name,
          label: productCanvas.name,
        }));

        return [
          {
            name: 'List',
            title: 'Add to prompt',
            commands: commands.filter(({ name }: { name: string }) =>
              name.toLowerCase().startsWith(query.toLowerCase()),
            ),
          },
        ];
      },
      render: mentionRender(tippy),
    },
  });
};
