import { Table } from '@tanstack/react-table';

import { useDndGestures } from '../shared/useDndGestures';

export function useTableHeaderGestures<DType>(table: Table<DType>, distance = 8) {
  const headers = table.getHeaderGroups()[0].headers;
  return useDndGestures(headers, distance, (newOrder) => {
    table.setColumnOrder(newOrder);
  });
}
