import { Column, Header, Table } from '@tanstack/react-table';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/16/solid';
import { BsFilter } from 'react-icons/bs';
import { VscPinned } from 'react-icons/vsc';
import DropdownMenuPanel from '../../shared/DropdownMenuPanel';
import { DynamicRowData } from '../../api/tables';
import { useCloseDropdown } from '../../shared/dropdown-menu.context.ts';
import { useState } from 'react';
import { ColumnFilterMenu } from './ColumnFilterMenu.tsx';

interface ColumnMenuProps {
  table: Table<DynamicRowData>;
  header: Header<DynamicRowData, unknown>;
  column: Column<DynamicRowData>;
}

const ColumnMenu: React.FC<ColumnMenuProps> = ({ table, header, column }: ColumnMenuProps) => {
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState<boolean>(false);
  const closeMenu = useCloseDropdown();

  if (isFilterMenuOpen) {
    return (
      <ColumnFilterMenu
        table={table}
        column={column}
        onBack={() => setIsFilterMenuOpen(false)}
        showBackButton={true}
      />
    );
  }

  return (
    <DropdownMenuPanel>
      <input
        className="w-full rounded-md border border-gray-200 bg-[#f8f8f9] px-2 py-1"
        value={header.column.columnDef.header as string}
        onChange={(e) => table.options.meta?.updateColumnName?.(column.id, e.target.value)}
      />

      <DropdownMenuPanel.Separator />

      <DropdownMenuPanel.HoverableItem
        onClick={() => {
          table.setSorting(() => [{ id: column.id, desc: false }]);
          closeMenu();
        }}
      >
        <button className="flex w-full items-center text-sm">
          <ArrowUpIcon className="mr-2 h-3 w-4" />
          <span>Sort ascending</span>
        </button>
      </DropdownMenuPanel.HoverableItem>

      <DropdownMenuPanel.HoverableItem
        onClick={() => {
          table.setSorting(() => [{ id: column.id, desc: true }]);
          closeMenu();
        }}
      >
        <button className="flex w-full items-center text-sm">
          <ArrowDownIcon className="mr-2 h-3 w-4" />
          <span>Sort descending</span>
        </button>
      </DropdownMenuPanel.HoverableItem>

      <DropdownMenuPanel.HoverableItem onClick={() => setIsFilterMenuOpen(true)}>
        <button className="flex w-full items-center text-sm">
          <BsFilter className="mr-2 h-4 w-4" />
          <span>Filter</span>
        </button>
      </DropdownMenuPanel.HoverableItem>

      <DropdownMenuPanel.Separator />
      <DropdownMenuPanel.HoverableItem>
        <button className="flex w-full items-center text-sm">
          <VscPinned className="mr-2 h-3 w-4" />
          <span>Freeze up to column</span>
        </button>
      </DropdownMenuPanel.HoverableItem>
    </DropdownMenuPanel>
  );
};

export default ColumnMenu;
