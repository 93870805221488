import { Avatar, Dropdown } from 'flowbite-react';
import { useWorkspaces } from '@/api/workspace/get-workspaces.ts';
import { setCurrentWorkspace, useCurrentWorkspace } from '@/state/app-store.ts';
import { cn } from '@/helpers/cn';
import { Workspace } from '@/models/workspace.interface.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useNavigate } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { IconButton } from '@/components/IconButton.tsx';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { useDeleteWorkspace } from '@/api/workspace/delete-workspace.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { isOrganizationAdmin } from '@/helpers/is-organization-admin';
import { shortenText } from '@/helpers/shorten-text';
import { useAuth } from '@/hooks/auth/useAuth';
import { useSemrush } from '@/state/semrush.store';

export function WorkspaceSwitcher({ isNavCollapsed }: { isNavCollapsed: boolean }) {
  const currentWorkspace = useCurrentWorkspace();
  const { user } = useAuth();
  const { workspaces } = useWorkspaces();
  const { openModal } = useGlobalModal();
  const navigate = useNavigate();
  const { openDialog, closeDialog } = useGlobalDialog();
  const { deleteWorkspace } = useDeleteWorkspace();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { isSemrush } = useSemrush();

  const handleSelectWorkspace = (workspace: Workspace) => {
    setCurrentWorkspace(workspace);
    navigate(buildUrl([Urls.DASHBOARD]));
  };

  const handleDeleteWorkspace = (workspace: Workspace) => {
    openDialog(DIALOGS.DeleteWorkspace, async () => {
      closeDialog();
      showLoader('Deleting workspace...');
      await deleteWorkspace(workspace.id);
      hideLoader();
    });
  };

  return (
    <Dropdown
      label="Switch workspace"
      placement="bottom-end"
      className="max-h-[70svh] w-[288px] max-w-[288px] overflow-y-auto"
      renderTrigger={() => (
        <button className="bg-interactive-gray flex items-center rounded-lg p-1 group-[.is-collapsed]:p-0">
          {!currentWorkspace?.avatarUrl && (
            <div className="mr-3 flex size-10 shrink-0 items-center justify-center rounded-lg bg-pink-300 text-h3-desktop group-[.is-collapsed]:m-0">
              {currentWorkspace?.name.charAt(0).toUpperCase()}
            </div>
          )}
          {currentWorkspace?.avatarUrl && (
            <Avatar
              img={import.meta.env.VITE_ASSETS + currentWorkspace.avatarUrl}
              size="sm"
              className={!isNavCollapsed ? 'mr-3' : undefined}
            />
          )}
          <div className="flex items-center whitespace-nowrap text-caption-md group-[.is-collapsed]:hidden">
            {shortenText(currentWorkspace?.name ?? '', 20)}
            <img
              src="/unfold-more-black.svg"
              className="ml-1.5"
              alt=""
            />
          </div>
        </button>
      )}
    >
      <Dropdown.Item onClick={() => navigate(buildUrl([Urls.WORKSPACE_SETTINGS]))}>
        <img
          src="/settings-black.svg"
          alt=""
        />
        Workspace settings
      </Dropdown.Item>

      <Dropdown.Item
        className={cn(!isOrganizationAdmin(user) && 'hidden')}
        onClick={() => navigate(buildUrl([Urls.WORKSPACE_MEMBERS]))}
      >
        <img
          src="/settings-black.svg"
          alt=""
        />
        Team members
      </Dropdown.Item>

      {!isSemrush && (
        <Dropdown.Item onClick={() => navigate(buildUrl([Urls.PRICING]))}>
          <img
            src="/rocket-blue.svg"
            alt=""
          />
          <span className="text-primary-default">Upgrade to Pro</span>
        </Dropdown.Item>
      )}

      <Dropdown.Divider />

      <div className="dropdown-header">Workspaces</div>

      <Dropdown.Item onClick={() => openModal(MODALS.CreateWorkspace)}>
        <div className="dropdown-item__icon">
          <img
            src="/plus-black.svg"
            alt=""
          />
        </div>
        New workspace
      </Dropdown.Item>

      {!!workspaces &&
        workspaces.slice(0, 6).map((workspace) => (
          <div
            key={workspace.id}
            className="dropdown-item__workspace-container"
          >
            {workspaces.length !== 1 && (
              <div className="dropdown-item__workspace-container__button">
                <IconButton
                  icon="/trash-gray.svg"
                  size={24}
                  alt="Delete workspace"
                  onClick={() => handleDeleteWorkspace(workspace)}
                />
              </div>
            )}
            <Dropdown.Item
              onClick={() => handleSelectWorkspace(workspace)}
              key={workspace.id}
            >
              <div className="dropdown-item__workspace-avatar">
                {!workspace.avatarUrl && (
                  <div
                    className={cn(
                      'flex size-8 items-center justify-center rounded-lg bg-pink-300 text-h4-desktop group-[.is-collapsed]:m-0',
                      { 'border border-white outline outline-primary-default': currentWorkspace?.id === workspace.id },
                    )}
                  >
                    {workspace?.name.charAt(0).toUpperCase()}
                  </div>
                )}
                {workspace.avatarUrl && (
                  <Avatar
                    img={import.meta.env.VITE_ASSETS + workspace.avatarUrl}
                    className={cn([
                      { 'border border-white outline outline-primary-default': currentWorkspace?.id === workspace.id },
                    ])}
                    size="sm"
                  />
                )}
              </div>
              <div className="text-left">{workspace.name}</div>
            </Dropdown.Item>
          </div>
        ))}

      <Dropdown.Item onClick={() => navigate(buildUrl([Urls.WORKSPACES]))}>
        <div className="dropdown-item__icon">
          <img
            src="/tiles-black.svg"
            alt=""
          />
        </div>
        All workspaces
      </Dropdown.Item>
    </Dropdown>
  );
}
