/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  AppState,
  Auth0ContextInterface,
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
  useAuth0,
} from '@auth0/auth0-react';
import { setIsSemrush, useSemrush } from '../../state/semrush.store';

export const useAuth = (): Auth0ContextInterface => {
  const { isSemrush, decodedToken } = useSemrush();
  const auth0Client = useAuth0();

  if (isSemrush) {
    return {
      isAuthenticated: true,
      getAccessTokenSilently: async (options?: GetTokenSilentlyOptions) => {
        const token = await window.SM.client('getAccessToken');
        return options?.detailedResponse ? { accessToken: token } : token;
      },
      loginWithRedirect: (_options?: RedirectLoginOptions<AppState>) => {},
      logout: async (_options?: LogoutOptions) => {
        setIsSemrush(false);
      },
      user: {
        sub: decodedToken?.viewer_id,
        given_name: 'Semrush',
        family_name: 'User',
      },
    } as Auth0ContextInterface;
  }

  return {
    ...auth0Client,
  };
};
