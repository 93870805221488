import { useApi } from '../api';
import useSWR from 'swr';
import { ProductCanvasDto } from './ProductCanvasDto';

export const useProductCanvases = () => {
  const { getFetcher } = useApi();

  const { data, ...rest } = useSWR<ProductCanvasDto[]>(`/product-canvas/by-workspace`, getFetcher);

  return {
    productCanvases: data,
    ...rest,
  };
};
