import { Button, Datepicker } from 'flowbite-react';

interface CalendarDataPickerProps {
  value: string;
  onChange: (value: string) => void;
}

export const CalendarDatePicker = ({ value, onChange }: CalendarDataPickerProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Datepicker
        value={value ? new Date(value) : null}
        weekStart={1}
        defaultValue={value ? new Date(value) : undefined}
        onChange={(date) => onChange(date ? date.toISOString() : '')}
        showClearButton={false}
        showTodayButton={false}
        inline={true}
        theme={{
          popup: {
            root: {
              inner: 'inline-block rounded-lg bg-white p-4 dark:bg-gray-700',
            },
          },
          views: {
            days: {
              items: {
                item: {
                  selected: 'bg-primary-default text-white hover:bg-primary-hover',
                },
              },
            },
          },
        }}
      />

      <div className="grid grid-cols-2 gap-2">
        <Button
          onClick={() => onChange(new Date().toISOString())}
          color="primary"
          size="sm"
        >
          Today
        </Button>
        <Button
          onClick={() => onChange('')}
          color="secondary"
          size="sm"
        >
          Clear
        </Button>
      </div>
    </div>
  );
};
