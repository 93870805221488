import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { GenerateStrategyCanvasButton } from '../../canvases/routes/canvas-deatils/components/GenerateStrategyCanvasButton.tsx';
import { Helmet } from 'react-helmet';
import { StrategyCanvasPositionCard } from '../../canvases/routes/canvas-deatils/components/StrategyCanvasPositionCard.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { Dropdown } from 'flowbite-react';
import { CanvasDetails } from '@/api/canvases/api.ts';

export const OnboardingCanvasMockupPage = () => {
  const mockedCanvasDetails: CanvasDetails = {
    id: 1,
    name: 'Lean Canvas',
    positions: [],
    templateId: 1,
    isProcessing: false,
    createdAt: '',
    modifiedAt: '',
    deletedAt: null,
    generationContextItemsIds: [],
    tutorialUrl: null,
    tutorialImageUrl: null,
    tutorialDescription: null,
  };

  return (
    <>
      <PageWithHeaderLayout
        header={{
          breadcrumbs: [
            { label: 'Strategy canvases', href: buildUrl([Urls.CANVASES]) },
            { label: 'My canvases', href: buildUrl([Urls.CANVASES, Urls.MY_CANVASES]) },
            { label: 'Lean Canvas', href: '#' },
          ],
          icon: '/canvases-black.svg',
          leftAddons: [
            <Dropdown
              label=""
              key={1}
              renderTrigger={() => (
                <div>
                  <IconButton
                    icon="/three-dots-gray.svg"
                    alt="More"
                  />
                </div>
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <></>
            </Dropdown>,
          ],
          rightAddons: [
            <GenerateStrategyCanvasButton
              key={0}
              canvasDetails={mockedCanvasDetails}
            />,
          ],
        }}
      >
        <Helmet>
          <title>Digitalfirst.ai | Onboarding</title>
        </Helmet>

        <div className="grid grid-cols-2 items-start gap-6">
          {[
            {
              id: 1,
              label: 'Customer Segments',
              value: [''],
              hierarchyPosition: 1,
            },
            {
              id: 2,
              label: 'Value Propositions',
              value: [''],
              hierarchyPosition: 2,
            },
            {
              id: 3,
              label: 'Channels',
              value: [''],
              hierarchyPosition: 3,
            },
            {
              id: 4,
              label: 'Customer Relationships',
              value: [''],
              hierarchyPosition: 4,
            },
            {
              id: 5,
              label: 'Revenue Streams',
              value: [''],
              hierarchyPosition: 5,
            },
            {
              id: 6,
              label: 'Key Resources',
              value: [''],
              hierarchyPosition: 6,
            },
            {
              id: 7,
              label: 'Key Activities',
              value: [''],
              hierarchyPosition: 7,
            },
            {
              id: 8,
              label: 'Key Partners',
              value: [''],
              hierarchyPosition: 8,
            },
            {
              id: 9,
              label: 'Cost Structure',
              value: [''],
              hierarchyPosition: 9,
            },
            {
              id: 10,
              label: 'Problem',
              value: [''],
              hierarchyPosition: 10,
            },
            {
              id: 11,
              label: 'Solution',
              value: [''],
              hierarchyPosition: 11,
            },
            {
              id: 12,
              label: 'Unique Value Proposition',
              value: [''],
              hierarchyPosition: 12,
            },
          ]
            .sort((a, b) => {
              return a.hierarchyPosition - b.hierarchyPosition;
            })
            .map((position, i) => (
              <StrategyCanvasPositionCard
                key={position.id}
                position={position}
                canvasDetails={mockedCanvasDetails}
                index={i}
              />
            ))}
        </div>
      </PageWithHeaderLayout>
    </>
  );
};
