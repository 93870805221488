import { cn } from '@/helpers/cn.ts';
import { Button } from 'flowbite-react';
import EditIcon from '@assets/edit.svg';
import ContentEditableListEditor from '../../routes/canvases/routes/canvas-deatils/components/ContentEditableList.tsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CanvasPosition } from '@/api/canvases/api.ts';
import GenerateIcon from '@assets/generate.svg';
import RegenerateIcon from '@assets/regenerate.svg';

interface CanvasPositionCardProps {
  position: CanvasPosition;
  index: number;
  isProcessing: boolean;
  onSave: (newPositionValue: string[]) => void;
  onRegenerate: () => void;
}

export const CanvasPositionCard = ({
  position,
  index,
  isProcessing,
  onSave,
  onRegenerate,
}: CanvasPositionCardProps) => {
  const [positionValue, setPositionValue] = useState<string[]>([]);
  const [editingPositionValue, setEditingPositionValue] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setPositionValue(position.value);
  }, [position.value]);

  const onDescriptionsChange = useCallback((newPositionValue: string[]) => {
    setEditingPositionValue(newPositionValue);
  }, []);

  const generateButtonBody =
    position.value.length === 0 ? (
      <>
        <GenerateIcon className="path:fill-primary-600" />
        <span className="text-primary-600">Generate</span>
      </>
    ) : (
      <>
        <RegenerateIcon className="path:fill-primary-600" />
        <span className="text-primary-600">Regenerate</span>
      </>
    );

  const positionColors: { lightBg: string; darkBg: string } = useMemo(() => {
    switch (index % 8) {
      case 0:
        return {
          lightBg: '#FADBE6',
          darkBg: '#A00139',
        };
      case 1:
        return {
          lightBg: '#ECDCFD',
          darkBg: '#6B0ADB',
        };
      case 2:
        return {
          lightBg: '#FEF5D1',
          darkBg: '#CBAA27',
        };
      case 3:
        return {
          lightBg: '#E9F5DD',
          darkBg: '#599A17',
        };
      case 4:
        return {
          lightBg: '#E7E5FC',
          darkBg: '#3A25BA',
        };
      case 5:
        return {
          lightBg: '#CEE5E3',
          darkBg: '#006458',
        };
      case 6:
        return {
          lightBg: '#FDDECE',
          darkBg: '#C84D0B',
        };
      case 7:
        return {
          lightBg: '#F0CCFC',
          darkBg: '#A00CD2',
        };
      default:
        return {
          lightBg: '#E7E5FC',
          darkBg: '#3A25BA',
        };
    }
  }, [index]);

  return (
    <>
      <div className="relative flex overflow-hidden rounded-l-lg rounded-r-lg transition duration-150 hover:scale-[1.005] hover:shadow-[0px_4px_20px_-1px_#97959F3D]">
        <div
          className={cn('w-1 shrink-0')}
          style={{
            backgroundColor: positionColors.lightBg,
          }}
        ></div>
        <div
          className="w-full p-4 pl-6"
          style={{
            minHeight: isProcessing ? '200px' : 'auto',
          }}
        >
          {isProcessing && (
            <div className="absolute left-0 top-0 z-20 flex h-full w-full items-center justify-center bg-white opacity-80">
              <div className="flex flex-col items-center gap-4">
                <img
                  src="/loading-blue.svg"
                  className="animate-spin-slow"
                  alt=""
                />
                <div className="text-body-lg">Please wait for the results or check back here in a few minutes.</div>
              </div>
            </div>
          )}

          <div className="flex w-full items-center justify-between">
            <h2 className="text-subtitle-md">{position.label}</h2>
            <div className="flex gap-2">
              {isEditing ? (
                <>
                  <Button
                    color="transparent"
                    size="sm"
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => {
                      setIsEditing(false);
                      setPositionValue(editingPositionValue);
                      onSave(editingPositionValue);
                    }}
                  >
                    <span className="text-primary-600">Save changes</span>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="transparent"
                    size="sm"
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  >
                    <EditIcon className="size-4" />
                    Edit
                  </Button>

                  <Button
                    color="secondary"
                    size="sm"
                    onClick={onRegenerate}
                  >
                    {generateButtonBody}
                  </Button>
                </>
              )}
            </div>
          </div>

          {isEditing && (
            <div className="mt-4 w-full">
              <ContentEditableListEditor
                descriptions={positionValue}
                onDescriptionsChange={onDescriptionsChange}
              />
            </div>
          )}
          {!isEditing && positionValue.length !== 0 && (
            <ul className="mt-4">
              {positionValue.map((item, index) => (
                <li
                  key={index}
                  className={`relative mb-4 pl-6 text-body-md text-text-secondary before:absolute before:left-0 before:top-[6px] before:h-[14px] before:w-[14px] before:rounded-full before:border-[3px] before:border-[var(--tw-before-border-color)] before:bg-[var(--tw-before-bg-color)] before:content-['']`}
                  style={{
                    ['--tw-before-border-color' as never]: positionColors.lightBg,
                    ['--tw-before-bg-color' as never]: positionColors.darkBg,
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
