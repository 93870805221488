import { useApi } from '@/api/api.ts';
import useSWR from 'swr';
import { Persona } from '@/models/persona.interface.ts';
import { useDataRoomId } from '@/state/app-store.ts';

export const useNoSegmentPersonas = () => {
  const { getFetcher } = useApi();
  const dataRoomId = useDataRoomId();

  const { data } = useSWR<Persona[]>(`/persona-canvas/no-segment-personas/${dataRoomId}`, getFetcher);

  return {
    noSegmentPersonas: data,
  };
};
