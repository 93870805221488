import { cn } from '@/helpers/cn';
import AlertIcon from '@assets/alert.svg';
import CheckIcon from '@assets/check-filled.svg';
import CloseIcon from '@assets/close.svg';
import toast, { Toaster, resolveValue } from 'react-hot-toast';

function CustomToaster() {
  return (
    <Toaster containerStyle={{ zIndex: 10000 }}>
      {(t) => (
        <div
          className={cn('flex max-w-2xl items-center gap-2 rounded-lg border p-3 text-body-md text-neutrals-1000', {
            'border-positive-900 bg-positive-100': t.type === 'success',
            'border-negative-800 bg-negative-100': t.type === 'error',
            'border-primary-600 bg-primary-100': t.type === 'blank',
            'opacity-0': t.visible === false,
          })}
        >
          {t.type === 'error' && <AlertIcon />}
          {t.type === 'success' && <CheckIcon />}
          {t.type === 'blank' && <AlertIcon className="path:fill-primary-600" />}
          <span>{resolveValue(t.message, t)}</span>
          <button onClick={() => toast.dismiss(t.id)}>
            <CloseIcon
              className={cn({
                'path:fill-positive-900': t.type === 'success',
                'path:fill-negative-800': t.type === 'error',
                'path:fill-primary-600': t.type === 'blank',
              })}
            />
          </button>
        </div>
      )}
    </Toaster>
  );
}

export default CustomToaster;
