import { Badge, Card } from 'flowbite-react';
import { useCanvases } from '@/api/canvases/api.ts';
import { Link } from 'react-router-dom';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { useSegments } from '@/api/segments/get-segments.ts';
import { useProductCanvases } from '@/api/products/get-product-canvases.ts';

export function CompanyCanvasesShortcuts() {
  const { canvases } = useCanvases();
  const { segments } = useSegments();
  const { productCanvases } = useProductCanvases();

  return (
    <div className="grid grid-cols-3 gap-4">
      <Link
        to={buildUrl([Urls.CANVASES])}
        className="flex"
      >
        <Card className="card-shortcut">
          <div className="card-shortcut__icon">
            <img
              src="/canvases-black.svg"
              alt=""
            />
          </div>
          <h3 className="card-shortcut__title">
            <span>Strategy canvases</span>
            {!!canvases?.length && <Badge color="blue">{canvases.length}</Badge>}
            <img
              src="/chevron-right-blue.svg"
              alt=""
            />
          </h3>
          <div className="card-shortcut__description">
            Generate strategy canvases that are versatile and ready to use for any industry, product, service, or niche!
          </div>
        </Card>
      </Link>

      <Link
        to={buildUrl([Urls.SEGMENTS])}
        className="flex"
      >
        <Card className="card-shortcut">
          <div className="card-shortcut__icon">
            <img
              src="/user-black.svg"
              alt=""
            />
          </div>
          <h3 className="card-shortcut__title">
            <span>Segments</span>
            {!!segments?.length && (
              <Badge color="blue">{segments.reduce((acc, segment) => acc + segment.personaCanvases.length, 0)}</Badge>
            )}
            <img
              src="/chevron-right-blue.svg"
              alt=""
            />
          </h3>
          <div className="card-shortcut__description">
            Generate personas for your products, campaigns, and marketing strategies in just a few seconds.
          </div>
        </Card>
      </Link>

      <Link
        to={buildUrl([Urls.PRODUCTS])}
        className="flex"
      >
        <Card className="card-shortcut">
          <div className="card-shortcut__icon">
            <img
              src="/products-black.svg"
              alt=""
            />
          </div>
          <h3 className="card-shortcut__title">
            <span>Products</span>
            {!!productCanvases?.length && <Badge color="blue">{productCanvases.length}</Badge>}
            <img
              src="/chevron-right-blue.svg"
              alt=""
            />
          </h3>
          <div className="card-shortcut__description">
            Create product collections and add your company's products in just a few seconds.
          </div>
        </Card>
      </Link>
    </div>
  );
}
