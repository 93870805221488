import { Button, Dropdown } from 'flowbite-react';
import { FlowTemplateCategoryIcon } from './FlowTemplateCategoryIcon';
import { FlowTemplate, FlowTemplateCategory } from '@/models/flow-template.interface';
import { useNavigate, useParams } from 'react-router-dom';
import { buildUrl } from '@/helpers/build-url';
import { Urls } from '@/consts/urls.ts';
import { usePinFlowToProject } from '@/api/flow/pin-flow-to-project';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store';
import { IconButton } from '@/components/IconButton';
import { usePermissions } from '@/api/permission/get-permissions';
import { useDeleteFlowTemplate } from '@/api/flow/delete-flow-template';
import { DIALOGS } from '@/consts/dialogs.const';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { MODALS } from '@/components/GlobalModal/modals.const';
import { setFlowInstance } from '@/helpers/flowInstance';
import { useDuplicateTemplateFlow } from '@/api/flow/duplicate-template-flow';

interface FlowTemplateCardProps {
  template: FlowTemplate;
}

export function FlowTemplateCard({ template }: FlowTemplateCardProps) {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { openModal } = useGlobalModal();
  const { openDialog } = useGlobalDialog();
  const { pinFlowToProject } = usePinFlowToProject();
  const { showLoader, hideLoader } = useGlobalLoader();
  const isMyTemplate = template.category === FlowTemplateCategory.MY;
  const { canDeleteFlowsLibrary, canEditFlowsLibrary } = usePermissions();
  const { deleteFlowTemplate } = useDeleteFlowTemplate();
  const { duplicateTemplateFlow } = useDuplicateTemplateFlow();
  const canManageFlowsLibrary = isMyTemplate || canDeleteFlowsLibrary || canEditFlowsLibrary;

  const runClick = async () => {
    if (!projectId) {
      openModal(MODALS.AddFlowToProject, template);
      return;
    }
    showLoader();
    const flow = await pinFlowToProject(template.id, Number(projectId));
    setFlowInstance(flow.id);
    hideLoader();
    navigate(buildUrl([Urls.PROJECTS, Urls.PROJECT, Urls.FLOW_EDITOR], { projectId: projectId!, flowId: flow.id }));
  };

  const deleteTemplateClick = async () => {
    openDialog(DIALOGS.DeleteFlowTemplate, async () => {
      showLoader();
      await deleteFlowTemplate(template.id);
      hideLoader();
    });
  };

  const renameClick = async () => {
    openModal(MODALS.EditFlowTemplate, template);
  };

  const editTemplateClick = async () => {
    navigate(buildUrl([Urls.FLOW_TEMPLATE_EDITOR], { flowId: template.id }));
  };

  const duplicateTemplateClick = async () => {
    duplicateTemplateFlow(template.id);
  };

  return (
    <div className="relative h-full">
      {canManageFlowsLibrary && (
        <div className="absolute right-4 top-4">
          <Dropdown
            label=""
            className="w-60"
            onClick={(e) => e.stopPropagation()}
            dismissOnClick={false}
            renderTrigger={() => (
              <div>
                <IconButton
                  icon="/three-dots-gray.svg"
                  alt="More"
                />
              </div>
            )}
            placement="bottom-end"
          >
            {(canEditFlowsLibrary || isMyTemplate) && (
              <Dropdown.Item onClick={editTemplateClick}>
                <img
                  src="/edit-black.svg"
                  alt=""
                />
                Edit template
              </Dropdown.Item>
            )}
            {(canEditFlowsLibrary || isMyTemplate) && (
              <Dropdown.Item onClick={duplicateTemplateClick}>
                <img
                  src="/edit-black.svg"
                  alt=""
                />
                Duplicate template
              </Dropdown.Item>
            )}
            {(canEditFlowsLibrary || isMyTemplate) && (
              <Dropdown.Item onClick={renameClick}>
                <img
                  src="/pencil-black.svg"
                  alt=""
                />
                Rename
              </Dropdown.Item>
            )}
            {(canDeleteFlowsLibrary || isMyTemplate) && (
              <Dropdown.Item onClick={deleteTemplateClick}>
                <img
                  src="/archive-black.svg"
                  alt=""
                />
                Delete
              </Dropdown.Item>
            )}
          </Dropdown>
        </div>
      )}
      <div className="flex h-full flex-col rounded-xl border border-gray-divider bg-white transition">
        <div className="flex h-full flex-col items-start p-4">
          <div className="flex h-full w-full flex-col justify-between">
            <div className="flex flex-col items-start">
              <div className="flex gap-2">
                <div className="mb-4 rounded-lg bg-purple-dark p-2">
                  <FlowTemplateCategoryIcon category={template.category} />
                </div>
                {canEditFlowsLibrary && <div>{<span>{template?.access?.name ?? 'Public'}</span>}</div>}
              </div>
              <h5 className="mb-2 line-clamp-2 text-h5-desktop">{template.name}</h5>
              <div className="mb-12 line-clamp-4 text-body-sm text-text-secondary">{template.description}</div>
            </div>

            <Button
              color="secondary"
              gradientMonochrome="yellow"
              className="w-full"
              onClick={runClick}
            >
              <img
                src="/run-yellow.svg"
                alt=""
              />
              Run
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
