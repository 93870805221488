import { ReadOnlyCellProps } from '../interface.ts';

export function ReadonlyNumberCell({ value, onClick }: ReadOnlyCellProps<string>) {
  return (
    <div
      className="overflow-hidden text-ellipsis text-nowrap p-3 text-right"
      onClick={onClick}
    >
      {value || <div>&nbsp;</div>}
    </div>
  );
}
