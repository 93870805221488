import { create } from 'zustand';
import { ReactNode } from 'react';

type GlobalSideModalState = {
  open: boolean;
  body: ReactNode | null;
  context: object;
};

const useGlobalSideModalStore = create<GlobalSideModalState>(() => ({
  open: false,
  body: null,
  context: {},
}));

export const useGlobalSideModalInternalState = () => ({
  open: useGlobalSideModalStore((state) => state.open),
  body: useGlobalSideModalStore((state) => state.body),
});

export const openSideModal = (body: ReactNode, context?: object) => {
  useGlobalSideModalStore.setState(() => ({
    open: true,
    body,
    context,
  }));
};

export const closeSideModal = () => {
  useGlobalSideModalStore.setState(() => ({
    open: false,
  }));
};

export const useSideModalContext = <T = object>() => useGlobalSideModalStore((state) => state.context as T);
