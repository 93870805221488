import { EditableCellProps } from '../interface.ts';

export const GenericTextCell = ({ value, onChange, innerRef }: EditableCellProps<string, HTMLInputElement>) => (
  <input
    ref={innerRef}
    className="h-full w-full p-3"
    value={value}
    autoFocus
    onChange={(e) => onChange(e.target.value)}
  />
);
