import { useDataRoomId } from '@/state/app-store.ts';
import useSWR from 'swr';
import { useApi } from '../api.ts';
import { useMemo } from 'react';
import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { getDataRoomIconForFile } from '../../../routes/data/helpers/get-data-room-icon-for-file.ts';
import { getDataRoomIconForUrl } from '../../../routes/data/helpers/get-data-room-icon-for-url.ts';
import { InputsFile } from '@/models/inputs-file.interface.ts';
import { InputsUrl } from '@/models/inputs-url.interface.ts';
import moment from 'moment';
import { getAddedDataTypeForFile } from '../../../routes/data/helpers/get-added-data-type-for-file.ts';
import { getAddedDataTypeForUrl } from '../../../routes/data/helpers/get-added-data-type-for-url.ts';

export function useDataRoomItems() {
  const { getFetcher } = useApi();
  const dataRoomId = useDataRoomId();
  const { data, error, isLoading } = useSWR(`/inputs/dataRoom/${dataRoomId}`, getFetcher);

  const dataRoomItems: DataRoomItem[] = useMemo(() => {
    if (!data) return [];

    const items: DataRoomItem[] = [
      ...data.files.map((file: InputsFile) => ({
        id: file.id,
        name: file.displayName ?? file.name,
        isParsed: file.isParsed,
        icon: getDataRoomIconForFile(file),
        date: moment(file.date),
        type: getAddedDataTypeForFile(file),
        url: file.url,
        creatorId: file.creatorId,
        extension: file.extension,
      })),
      ...data.urls.map((url: InputsUrl) => ({
        id: url.id,
        name: url.url,
        isParsed: url.isParsed,
        icon: getDataRoomIconForUrl(url),
        date: moment(url.date),
        type: getAddedDataTypeForUrl(url),
        url: url.url,
        creatorId: url.creatorId,
      })),
    ];

    items.sort((a, b) => {
      return b.date.diff(a.date);
    });

    return items;
  }, [data]);

  return {
    dataRoomItems: dataRoomItems,
    isSmallDataRoom: dataRoomItems.filter((item) => item.isParsed).length <= 5,
    isLoading,
    error,
  };
}
