import { cn } from '@/helpers/cn';
import {
  setOnboardingFormData,
  useOnboardingScrappedUrl,
  useUnFinishedOnboardingProcesses,
} from '../onboarding-store.ts';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';
import { FormikProvider, useFormik } from 'formik';
import { TileRadio } from '@/components/TileRadio.tsx';
import { object, string } from 'yup';
import { Button } from 'flowbite-react';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { useStartOnboardingProcess } from '@/api/onboarding/start-onboarding-process.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useNavigate } from 'react-router-dom';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useAuth } from '@/hooks/auth/useAuth.ts';
import CheckIcon from '@assets/check.svg';
import GenerateIcon from '@assets/generate-pink-purple.svg';
import { useTriggerEvent } from '@/api/events/api.ts';
import { TagManagerEvent } from '@/api/events/model.ts';

export const ContinueOnboardingForm = () => {
  const { user } = useAuth();
  const unFinishedOnboardingProcesses = useUnFinishedOnboardingProcesses(user!.sub!);
  const onboardingScrappedUrl = useOnboardingScrappedUrl(user!.sub!);
  const { startOnboardingProcess } = useStartOnboardingProcess();
  const { closeModal } = useGlobalModal();
  const { showLoader, hideLoader } = useGlobalLoader();
  const navigate = useNavigate();
  const { openModal } = useGlobalModal();
  const { triggerDataLayer } = useTriggerEvent();

  const onboardingTabs = [
    {
      processType: OnboardingProcessType.FLOW,
      title: 'AI Strategy',
      options: [
        {
          icon: '/fast-check-black.svg',
          iconBgColor: 'bg-purple-300',
          label: 'Marketing Campaign Generator',
          value: 218,
        },
        {
          icon: '/action-key-black.svg',
          iconBgColor: 'bg-pink-300',
          label: 'Market Trends Analysis',
          value: 219,
        },
        {
          icon: '/aim-black.svg',
          iconBgColor: 'bg-mint-500',
          label: 'ADS Generator',
          value: 220,
        },
      ],
    },
    {
      processType: OnboardingProcessType.WEB_SEARCHER,
      title: 'Market Research',
      options: [
        {
          icon: '/fast-check-black.svg',
          iconBgColor: 'bg-purple-300',
          label: 'Review competitors',
          value: 'Review competitors',
        },
        {
          icon: '/action-key-black.svg',
          iconBgColor: 'bg-pink-300',
          label: 'Price comparison with the competition',
          value: 'Price comparison with the competition',
        },
        {
          icon: '/aim-black.svg',
          iconBgColor: 'bg-mint-500',
          label: 'Marketing inspirations',
          value: 'Marketing inspirations',
        },
      ],
    },
    {
      processType: OnboardingProcessType.CANVAS,
      title: 'Business canvases',
      options: [
        {
          icon: '/fast-check-black.svg',
          iconBgColor: 'bg-lime-300',
          label: 'Business Model Canvas',
          value: 7,
        },
        {
          icon: '/action-key-black.svg',
          iconBgColor: 'bg-blue-300',
          label: 'Lean Model Canvas',
          value: 3,
        },
        {
          icon: '/aim-black.svg',
          iconBgColor: 'bg-yellow-300',
          label: 'Customer Persona Canvas',
          value: 8,
        },
      ],
    },
  ];

  const formik = useFormik<{
    processType: OnboardingProcessType;
    option: string | null;
  }>({
    initialValues: {
      processType: Object.values(OnboardingProcessType).filter((type) =>
        unFinishedOnboardingProcesses.includes(type),
      )[0],
      option: null,
    },
    validationSchema: object({
      option: string().nullable(),
    }),
    onSubmit: async ({ processType, option }) => {
      closeModal();
      showLoader();
      setOnboardingFormData({
        url: onboardingScrappedUrl,
        onboardingProcessType: processType,
        option: option!,
      });
      await startOnboardingProcess(onboardingScrappedUrl, processType, option!);
      triggerDataLayer(TagManagerEvent.onboarding_process, {
        onboarding_type: processType,
        checkbox_text: option,
      });
      hideLoader();
      navigate(buildUrl([Urls.ONBOARDING, Urls.ONBOARDING_PREPARE]));
    },
  });

  if (unFinishedOnboardingProcesses.length === 0) {
    openModal(MODALS.UpgradePlan);
    return;
  }

  const onTabChange = (processType: OnboardingProcessType) => {
    formik.setFieldValue('processType', processType);
    formik.setFieldValue('option', null);
  };

  return (
    <>
      <div
        className="max-h-[calc(90svh-1.5rem)] min-h-full w-full rounded-xl"
        style={{ backgroundImage: 'url(/modal-gradient.jpg)', backgroundSize: 'cover' }}
      >
        <div className="flex h-full flex-col p-10">
          <div>
            <h2 className="mb-4 flex flex-wrap items-center gap-3 text-h2-desktop">
              <span>Build your</span>
              <span className="italic">Strategy</span>
              <div className="rounded-lg bg-mint-500 p-2">
                <img
                  src="/folder-upload-black.svg"
                  alt=""
                />
              </div>
              <span>using AI</span>
              <div className="rounded-lg bg-purple-200 p-2">
                <img
                  src="/generate-black.svg"
                  alt=""
                />
              </div>
            </h2>

            <div className="mb-6 text-body-md text-neutrals-700">
              Transform your strategy into action with practical tactics, targeted campaigns, and compelling content
              designed to achieve your goals.
            </div>

            <div className="mb-10 flex flex-wrap items-center justify-between gap-2 lg:flex-nowrap">
              {[
                ...onboardingTabs.filter((tab) => !unFinishedOnboardingProcesses.includes(tab.processType)),
                ...onboardingTabs.filter((tab) => unFinishedOnboardingProcesses.includes(tab.processType)),
              ].map((tab, idx) => (
                <>
                  {idx !== 0 ? <div className="h-px w-full shrink bg-neutrals-400"></div> : null}

                  <button
                    key={tab.processType}
                    onClick={() => onTabChange(tab.processType)}
                    className="flex shrink-0 items-center gap-2.5"
                  >
                    <div
                      className={cn(
                        'flex size-5 items-center justify-center rounded-full',
                        formik.values.processType === tab.processType ? 'bg-primary-600' : 'bg-primary-200',
                      )}
                    >
                      {!unFinishedOnboardingProcesses.includes(tab.processType) ? (
                        <CheckIcon
                          className={cn(
                            formik.values.processType === tab.processType
                              ? 'path:fill-primary-200'
                              : 'path:fill-primary-600',
                          )}
                        />
                      ) : (
                        <div
                          className={cn(
                            'flex size-5 items-center justify-center rounded-full',
                            formik.values.processType === tab.processType
                              ? 'bg-primary-600 text-white'
                              : 'bg-primary-200 text-primary-600',
                          )}
                        >
                          <span className="text-nav-sub">{idx + 1}</span>
                        </div>
                      )}
                    </div>

                    <div
                      className={cn(
                        'border-b border-dashed leading-6',
                        formik.values.processType === tab.processType
                          ? 'border-transparent text-caption-md'
                          : 'border-neutrals-700 text-body-md',
                      )}
                    >
                      {tab.title}
                    </div>
                  </button>
                </>
              ))}
            </div>
          </div>

          <FormikProvider value={formik}>
            <form
              onSubmit={formik.handleSubmit}
              className="flex grow flex-col justify-between"
            >
              <div className="flex flex-col gap-2">
                {onboardingTabs
                  .find((tab) => formik.values.processType === tab.processType)
                  ?.options.map((option, i) => (
                    <TileRadio
                      name="option"
                      value={option.value}
                      formik={formik}
                      className="bg-white"
                      key={i}
                    >
                      <div className={cn('ml-1 rounded-lg p-2', option.iconBgColor)}>
                        <img
                          src={option.icon}
                          width={12}
                          height={12}
                          alt=""
                        />
                      </div>
                      <div className="grow text-subtitle-sm">{option.label}</div>
                    </TileRadio>
                  ))}
              </div>

              <div className="flex flex-col">
                <Button
                  type="submit"
                  className="mt-10 w-full bg-neutrals-1000"
                  color="dark"
                  disabled={!formik.values.option}
                  size="lg"
                >
                  <GenerateIcon />
                  Generate for Free in seconds!
                </Button>

                <div className="mx-auto mt-6 rounded-[2rem] bg-primary-100 px-3 py-2 text-primary-600">
                  + 1000 generations already!
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
};
