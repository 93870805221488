import { normalizeUrl } from '@/helpers/normalize-url.ts';
import { ReadOnlyCellProps } from '../interface.ts';
import { URL_REGEXP } from '@/consts/url-regexp-validators.const.ts';

export const ReadonlyUrlCell = ({ value, onClick }: ReadOnlyCellProps<string>) => {
  const link = !value.includes(' ') && URL_REGEXP.test(value) ? normalizeUrl(value) : null;

  return (
    <div
      className="overflow-hidden text-ellipsis text-nowrap p-3"
      onClick={onClick}
    >
      {link ? (
        <a
          href={link}
          className="text-primary-default hover:underline"
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          rel="noreferrer"
        >
          {value}
        </a>
      ) : (
        <div className="overflow-hidden text-ellipsis text-nowrap">{value}</div>
      )}
    </div>
  );
};
