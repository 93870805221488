import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { useFormik } from 'formik';
import { closeSideModal, useSideModalContext } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { CanvasDetails, useSaveCanvas } from '@/api/canvases/api.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { GenericCanvasGenerationContextSideModalBody } from '@/components/GenericCanvasGenerationContextSideModalBody.tsx';

export const CanvasGenerationContextSideModalBody = () => {
  const { dataRoomItems } = useDataRoomItems();
  const { saveCanvas } = useSaveCanvas();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { canvasDetails } = useSideModalContext<{ canvasDetails: CanvasDetails }>();

  const formik = useFormik<{ generationContextItemsIds: string[] }>({
    initialValues: {
      generationContextItemsIds: canvasDetails.generationContextItemsIds ?? [],
    },
    onSubmit: async ({ generationContextItemsIds }) => {
      closeSideModal();
      showLoader();
      await saveCanvas(canvasDetails.templateId, canvasDetails.positions, canvasDetails.id, generationContextItemsIds);
      hideLoader();
    },
  });

  return (
    <GenericCanvasGenerationContextSideModalBody
      formik={formik}
      dataRoomItems={dataRoomItems}
      submitButtonText="Save Changes"
    />
  );
};
