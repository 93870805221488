import { useTriggerEvent } from '@/api/events/api';
import { TagManagerEvent } from '@/api/events/model';
import { UpgradePlanForm } from '@/components/UpgradePlanForm.tsx';
import { useEffect } from 'react';

export const UpgradePlanModalBody = () => {
  const { triggerDataLayer } = useTriggerEvent();

  useEffect(() => {
    triggerDataLayer(TagManagerEvent.plan_limits_reached, {});
  });

  return (
    <div className="grid grid-cols-[45%,minmax(0,1fr)] gap-10">
      <img
        src="/upgrade-plan-picture.jpg"
        className="sticky top-0 h-full max-h-[calc(90svh-1.5rem)] w-full rounded-l-xl object-cover"
        alt=""
      />

      <UpgradePlanForm />
    </div>
  );
};
