import { EMAIL_REGEXP } from '@/consts/email-regexp-validator.ts';
import { ReadOnlyCellProps } from '../interface.ts';

export const ReadonlyEmailCell = ({ value, onClick }: ReadOnlyCellProps<string>) => {
  const isValidMail = EMAIL_REGEXP.test(value);

  return (
    <div
      className="overflow-hidden text-nowrap p-3"
      onClick={onClick}
    >
      {isValidMail ? (
        <a
          href={`mailto:${value}`}
          className="block overflow-hidden text-ellipsis text-nowrap text-primary-default hover:underline"
          onClick={(e) => e.stopPropagation()}
        >
          {value}
        </a>
      ) : (
        <div className="overflow-hidden text-ellipsis text-nowrap">{value}</div>
      )}
    </div>
  );
};
