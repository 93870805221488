import { findElementId } from './findElementId';
import {
  CanvasDetails,
  convertToCanvasDetails,
  useCanvases,
  useGenerateCanvas,
  useSaveCanvas,
} from '@/api/canvases/api';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { useTiptapCanvasDetails } from '@/components/tiptap/extensions/DigitalFirst/helpers/tiptap-api/getTiptapCanvasDetails.ts';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { ToolName } from 'src/libs/tools/type.model';
import { v4 as uuid } from 'uuid';

export const useGetCanvasesFromPrompt = (): {
  getCanvasesFromPrompt: (prompt: string) => Promise<CanvasDetails[] | null>;
} => {
  const { generateCanvas } = useGenerateCanvas();
  const { saveCanvas } = useSaveCanvas();
  const { listenerOn } = useWebSocketMessageListener();
  const { getTiptapCanvasDetails } = useTiptapCanvasDetails();
  const { canvases } = useCanvases();

  const getCanvasesFromPrompt = async (prompt: string) => {
    const canvasTemplatesIds = findElementId(prompt, `[data-type="${MentionReferenceType.CANVAS}"]`);
    const returnCanvases: CanvasDetails[] = [];

    if (!canvasTemplatesIds || !canvasTemplatesIds.length) return null;

    for (const canvasTemplateId of canvasTemplatesIds) {
      let canvas = canvases?.find((canvas) => canvas.templateId === +canvasTemplateId!);

      if (!canvas) {
        const canvasInstance = await saveCanvas(+canvasTemplateId);
        const canvasDetails = convertToCanvasDetails(canvasInstance);
        const wsChannel = `${ToolName.CANVAS_FILLER}:${uuid()}`;
        generateCanvas(+canvasDetails.id, wsChannel);
        await new Promise((resolve) => {
          const { listenerOff } = listenerOn(wsChannel, async () => {
            canvas = await getTiptapCanvasDetails(canvasDetails.id);
            listenerOff();
            resolve(true);
          });
        });
      }

      if (canvas) {
        returnCanvases.push(canvas);
      }
    }

    return returnCanvases;
  };

  return {
    getCanvasesFromPrompt,
  };
};
