import { EditableCellProps } from '../interface';
import DropdownMenuPanel from '../../../shared/DropdownMenuPanel.tsx';
import { DropdownMenu } from '../../../shared/DropdownMenu.tsx';
import { useEffect, useRef } from 'react';
import { cellHeight } from '../../cell-height.const.ts';
import { cn } from '@/helpers/cn.ts';

export const TextCell = ({
  value,
  onChange,
  onBlur,
  innerRef,
  column,
}: EditableCellProps<string, HTMLTextAreaElement>) => {
  const dropdownMenuPanelRef = useRef<HTMLDivElement | null>(null);
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (innerRef.current) {
      innerRef.current.style.height = ``;
      innerRef.current.style.height = `${innerRef.current.scrollHeight}px`;
      innerRef.current.setSelectionRange(innerRef.current.value.length, innerRef.current.value.length);
    }
  }, [innerRef.current]);

  useEffect(() => {
    if (dropdownMenuPanelRef.current && innerRef.current) {
      dropdownMenuPanelRef.current.style.width = `${column.getSize()}px`;
      innerRef.current.style.height = ``;
      innerRef.current.style.height = `${innerRef.current.scrollHeight}px`;
    }
  }, [dropdownMenuPanelRef.current, innerRef.current]);

  return (
    <>
      <DropdownMenu
        placement="bottom-start"
        offset={-cellHeight}
        onDropdownClose={onBlur}
        DropdownControl={
          <>
            <DropdownMenuPanel
              className={cn('max-h-[400px] min-w-[300px] !p-0')}
              ref={dropdownMenuPanelRef}
            >
              <textarea
                ref={innerRef}
                className="reset-input w-full resize-none !border-none p-3 text-sm leading-[22px] !outline-none !ring-0"
                value={value}
                rows={1}
                autoFocus
                onInput={(e) => {
                  const textarea = e.target as HTMLTextAreaElement;
                  textarea.style.height = '';
                  textarea.style.height = `${textarea.scrollHeight}px`;
                  onChange(textarea.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onBlur();
                    divRef.current?.querySelector('div')?.click();
                  } else if (e.key === 'Tab') {
                    e.preventDefault();
                    onBlur();
                    divRef.current?.querySelector('div')?.click();
                  }
                }}
              />
            </DropdownMenuPanel>
          </>
        }
      >
        {(props) => (
          <div
            className="h-full"
            ref={divRef}
          >
            <div
              {...props}
              className="h-full w-full overflow-hidden text-ellipsis text-nowrap p-3"
            >
              {value || <div>&nbsp;</div>}
            </div>
          </div>
        )}
      </DropdownMenu>
    </>
  );
};
