import { useCreateDfAxios } from '@/api/create-df-axios.ts';

export interface ApiOptions {
  contentType?: string;
}

export const useApi = (options?: ApiOptions) => {
  const { createDfAxios } = useCreateDfAxios(options);

  const getFetcher = async (url: string) => {
    const dfAxios = await createDfAxios();
    return dfAxios.get(url).then((res) => res.data);
  };

  const postFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    const dfAxios = await createDfAxios();
    return dfAxios.post(url, arg).then((res) => res.data);
  };

  const deleteFetcher = async <T>(url: string, data?: { arg: T }) => {
    const dfAxios = await createDfAxios();
    return dfAxios.delete(url, data && { data: data.arg }).then((res) => res.data);
  };

  const putFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    const dfAxios = await createDfAxios();
    return dfAxios.put(url, arg).then((res) => res.data);
  };

  const patchFetcher = async <T>(url: string, { arg }: { arg: T }) => {
    const dfAxios = await createDfAxios();
    return dfAxios.patch(url, arg).then((res) => res.data);
  };

  return {
    getFetcher,
    postFetcher,
    deleteFetcher,
    putFetcher,
    patchFetcher,
  };
};
