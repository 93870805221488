import { Column, Table } from '@tanstack/react-table';
import { useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import { MdOutlineArrowCircleLeft } from 'react-icons/md';
import { ColumnType } from '../table.inferfaces';
import DropdownMenuPanel from '../../shared/DropdownMenuPanel';
import { FaRegTrashCan } from 'react-icons/fa6';
import { DynamicRowData } from '../../api/tables';
import { useCloseDropdown } from '../../shared/dropdown-menu.context.ts';
import { ColumnTypes } from '../column-types.const.tsx';

interface ColumnDetailsMenuProps {
  table: Table<DynamicRowData>;
  column: Column<DynamicRowData, unknown>;
  onBack: () => void;
}

interface ColumnTypeMenuProps {
  onBack: () => void;
  onSelectType: (type: ColumnType) => void;
}

const ColumnTypeMenu: React.FC<ColumnTypeMenuProps> = ({ onBack, onSelectType }) => {
  return (
    <div className="flex w-full flex-col bg-white text-sm font-normal">
      <DropdownMenuPanel.Header>
        <div
          onClick={() => onBack()}
          className="mr-2 flex h-5 w-5 cursor-pointer items-center hover:text-gray-500"
        >
          <MdOutlineArrowCircleLeft className="h-5 w-5" />
        </div>
        Column Type
      </DropdownMenuPanel.Header>
      <div className="mt-1">
        {(Object.keys(ColumnTypes) as ColumnType[]).map((type) => (
          <DropdownMenuPanel.HoverableItem
            key={type}
            onClick={() => onSelectType(type)}
          >
            <div className="my-[2px] flex items-center gap-2">
              {ColumnTypes[type].icon}
              <span>{ColumnTypes[type].title}</span>
            </div>
          </DropdownMenuPanel.HoverableItem>
        ))}
      </div>
    </div>
  );
};

const ColumnDetailsMenu: React.FC<ColumnDetailsMenuProps> = ({ table, column, onBack }) => {
  const closeMenu = useCloseDropdown();
  const [headerName, setHeaderName] = useState(column.columnDef.header);

  const [isSelectTypeMenuOpen, setIsSelectTypeMenuOpen] = useState(false);

  return (
    <div className="flex w-full flex-col bg-white text-sm font-normal">
      {isSelectTypeMenuOpen ? (
        <ColumnTypeMenu
          onBack={() => setIsSelectTypeMenuOpen(false)}
          onSelectType={(type) => {
            table.options.meta?.updateColumnType?.(column.id, type);
            setIsSelectTypeMenuOpen(false);
            closeMenu();
          }}
        />
      ) : (
        <>
          <DropdownMenuPanel.Header>
            <div
              onClick={onBack}
              className="mr-2 flex h-5 w-5 cursor-pointer items-center hover:text-gray-500"
            >
              <MdOutlineArrowCircleLeft className="h-5 w-5" />
            </div>
            Column Details
          </DropdownMenuPanel.Header>

          <input
            className="mt-2 w-full rounded-md border border-gray-200 bg-[#f8f8f9] px-2 py-1"
            value={headerName}
            autoFocus
            onChange={(e) => {
              setHeaderName(e.target.value);
              table.options.meta?.updateColumnName?.(column.id, e.target.value);
            }}
          />

          <DropdownMenuPanel.SubHeader>Column Type</DropdownMenuPanel.SubHeader>

          <DropdownMenuPanel.HoverableItem onClick={() => setIsSelectTypeMenuOpen(true)}>
            <div className="flex w-full items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="shrink-0">{ColumnTypes[column.columnDef.meta?.type ?? 'text'].icon}</div>
                <span>{ColumnTypes[column.columnDef.meta?.type ?? 'text'].title}</span>
              </div>
              <div>
                <ChevronRightIcon className="h-4 w-4 text-gray-500" />
              </div>
            </div>
          </DropdownMenuPanel.HoverableItem>

          <DropdownMenuPanel.Separator />
          <DropdownMenuPanel.HoverableItem
            onClick={() => {
              table.options.meta?.deleteColumn?.(column.id);
              closeMenu();
            }}
          >
            <div className="flex items-center gap-2 py-[2px] group-hover:text-red-500">
              <FaRegTrashCan className="h-4 w-4 text-gray-500 group-hover:text-red-500" />
              Delete this column
            </div>
          </DropdownMenuPanel.HoverableItem>
        </>
      )}
    </div>
  );
};

export default ColumnDetailsMenu;
