import { useSearchParams } from 'react-router-dom';
import { setOnboardingFormData } from '../../routes/onboarding/onboarding-store.ts';
import { ReactNode, useEffect, useState } from 'react';
import { OnboardingProcessType } from '@/enums/onboarding-process-type.enum.ts';
import { useAuth } from '@/hooks/auth/useAuth.ts';

export const OnboardingModeRedirectGuard = ({ children }: { children: ReactNode }) => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth();
  const [searchParams] = useSearchParams();
  const [isAppInitializedInOnboardingMode, setIsAppInitializedInOnboardingMode] = useState(true);

  useEffect(() => {
    if (isLoading) return;

    const onboardingProcessType = searchParams.get('onboardingProcessType') as OnboardingProcessType;
    const url = searchParams.get('url');
    const option = searchParams.get('option');

    if (!onboardingProcessType || !url || !option) {
      setIsAppInitializedInOnboardingMode(false);
      return;
    }

    setOnboardingFormData({ url, onboardingProcessType, option });

    if (isAuthenticated) {
      setIsAppInitializedInOnboardingMode(false);
    } else {
      loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          mode: 'signUp',
          redirect_uri: window.location.origin,
        },
      });
    }
  }, [isLoading]);

  return <>{!isAppInitializedInOnboardingMode && children}</>;
};
