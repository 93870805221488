import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { closeSideModal, useSideModalContext } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { useFormik } from 'formik';
import { ToolName } from '../../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { mutate } from 'swr';
import { useUpdatePersona } from '@/api/segments/persona/update-persona.ts';
import { PersonaDetails } from '@/models/persona-details.interface.ts';
import { useGeneratePersona } from '@/api/segments/persona/generate-persona.ts';
import { useWebSocketMessageListener } from '@/useOnWebSocketMessage.ts';
import { GenericCanvasGenerationContextSideModalBody } from '@/components/GenericCanvasGenerationContextSideModalBody.tsx';

export const ForcedPersonaGenerationContextSideModalBody = () => {
  const { dataRoomItems } = useDataRoomItems();
  const { updatePersona } = useUpdatePersona();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { personaDetails } = useSideModalContext<{ personaDetails: PersonaDetails }>();
  const { generatePersona } = useGeneratePersona();
  const { listenerOn } = useWebSocketMessageListener();

  const formik = useFormik<{ generationContextItemsIds: string[] }>({
    initialValues: {
      generationContextItemsIds: [],
    },
    onSubmit: async ({ generationContextItemsIds }) => {
      closeSideModal();
      showLoader('Generating persona...');
      await updatePersona(personaDetails.id, { generationContextItemsIds });
      const wsChannel = `${ToolName.CANVAS_FILLER}: ${uuid()}`;
      await generatePersona(personaDetails.id, wsChannel);

      const { listenerOff } = listenerOn(wsChannel, () => {
        mutate(`/persona-canvas/${personaDetails.id}`);
        hideLoader();
        listenerOff();
      });
    },
  });

  return (
    <GenericCanvasGenerationContextSideModalBody
      formik={formik}
      dataRoomItems={dataRoomItems}
      submitButtonText="Generate"
    />
  );
};
