import { Link } from 'react-router-dom';

export const Page404 = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center bg-main-image bg-cover bg-[50%]">
      <h1 className="mb-4 text-7xl font-bold">404</h1>
      <p className="mb-8 text-4xl">Page not found</p>
      <Link
        to="/"
        className="text-blue-500 hover:underline"
      >
        Go back to the homepage
      </Link>
    </div>
  );
};
