import { GLOBAL_LOADER_MESSAGES } from '@/components/GlobalLoader/global-loader-messages.const.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { cn } from '@/helpers/cn';
import { inputsPayloadMapper } from '@/helpers/inputs-payload-mapper.ts';
import { AddResourcePayload, InputType } from '@/models/add-resource-payload.interface.ts';
import FolderIcon from '@assets/drive-folder-upload.svg';
import TrackIcon from '@assets/track-changes.svg';
import UploadIcon from '@assets/upload.svg';
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useAddResource } from '@/api/data-room/add-resource.ts';

function DataDropFile() {
  const { addResource } = useAddResource();
  const { showLoader, hideLoader } = useGlobalLoader();

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const files: AddResourcePayload[] = await inputsPayloadMapper[InputType.File](acceptedFiles);
      showLoader(GLOBAL_LOADER_MESSAGES.AddingData);
      await Promise.all(files.map((file) => addResource(file)));
      hideLoader();
    },
    [addResource, hideLoader, showLoader],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop,
    multiple: false,
  });

  return (
    <div
      className="rounded-3xl p-6 text-center"
      style={{ backgroundImage: 'url(/gradient-wide.svg)', backgroundSize: 'cover' }}
    >
      <div
        {...getRootProps()}
        className={cn(
          'flex w-full flex-col items-center rounded-xl border border-dashed border-primary-600 bg-white bg-opacity-40 p-8',
          {
            'bg-opacity-100': isDragActive,
          },
        )}
      >
        <h2 className="text-center text-h3-desktop">
          <div className="">
            <div className="flex items-baseline justify-center gap-3">
              <span>Automatically</span>
              <div className="flex rounded-xl bg-mint-600 p-3">
                <FolderIcon />
              </div>
              <span className="italic">load</span>
            </div>
            <div className="flex items-baseline justify-center gap-3 pt-2">
              <span>company</span>
              <div className="flex rounded-xl bg-purple-200 p-3">
                <TrackIcon />
              </div>
              <span>data</span>
            </div>
          </div>
        </h2>

        <div className="max-w-xl pt-4 text-body-md text-neutrals-700">
          Enhance your strategies and marketing actions by leveraging AI. Paste your company website URL to seamlessly
          load your brand data.
        </div>

        <label className="flex cursor-pointer flex-col items-center pt-6">
          <UploadIcon />
          <span className="mt-2 text-body-md text-neutrals-700">Drag & drop files here </span>
          <span className="text-body-sm text-primary-600 underline">or browse for files</span>
          <input {...getInputProps()} />
        </label>
      </div>
    </div>
  );
}

export default DataDropFile;
