import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import './MentionAccordionBody.scss';
import { useContext } from 'react';
import { PromptEditorContext } from '@/components/tiptap/extensions/DigitalFirst/editing/prompt-editor.context.ts';
import { DataRoomItem } from '@/models/data-room-item.interface.ts';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';

export function DataRoomMentionAccordionBody() {
  const { dataRoomItems, isLoading } = useDataRoomItems();
  const { editor } = useContext(PromptEditorContext);

  const handleAddDataRoomItemToPrompt = (dataRoomItem: DataRoomItem) => {
    editor
      ?.chain()
      .focus()
      .insertContent({
        type: MentionReferenceType.DATA_ROOM,
        attrs: {
          id: dataRoomItem.id,
          label: `${dataRoomItem.name}`,
        },
      })
      .insertContent(' ') // add an extra space after the mention
      .run();
  };

  return (
    <>
      <div className="mention-accordion">
        <div className="mention-accordion__header">Add to prompt</div>
        {!isLoading && !!dataRoomItems?.length && (
          <div className="mention-accordion__list">
            {dataRoomItems.map((item) => (
              <button
                key={item.id}
                onClick={() => {
                  handleAddDataRoomItemToPrompt(item);
                }}
                className="mention-accordion__data-room-item"
              >
                <img
                  src="/add-orange.svg"
                  width={16}
                  height={16}
                  alt=""
                />
                <img
                  src={item.icon}
                  width={16}
                  height={16}
                  alt=""
                />
                <span className="mention-accordion__data-room-item__name">{item.name}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
