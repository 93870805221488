import { Checkbox } from 'flowbite-react';
import { cn } from '@/helpers/cn.ts';
import { ReactNode } from 'react';

interface TileCheckboxProps {
  checked: boolean;
  children: ReactNode;
  onClick: () => void;
  size?: 'md' | 'sm';
}

export const TileCheckbox = ({ checked, children, onClick, size = 'md' }: TileCheckboxProps) => {
  return (
    <>
      <div
        className={cn(
          'flex cursor-pointer items-center gap-3 rounded-lg border border-neutrals-400 transition hover:border-primary-600',
          checked && 'border-primary-600 bg-primary-100',
          size === 'md' && 'p-4',
          size === 'sm' && 'px-3 py-3.5',
        )}
        onClick={onClick}
      >
        <Checkbox
          className="cursor-pointer"
          checked={checked}
        />
        {children}
      </div>
    </>
  );
};
