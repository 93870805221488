import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Helmet } from 'react-helmet';
import { Button } from 'flowbite-react';
import { useParams } from 'react-router-dom';
import { PersonaCanvasPositionCard } from './components/PersonaCanvasPositionCard.tsx';
import { usePersonaDetails } from '@/api/segments/persona/get-persona-details.ts';
import { PersonaMainInfoCard } from './components/PersonaMainInfoCard.tsx';
import { InfoCard } from '@/components/InfoCard.tsx';
import { useMemo } from 'react';
import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import DataIcon from '@assets/data.svg';
import { GeneratePersonaCanvasButton } from '../components/GeneratePersonaCanvasButton.tsx';
import { PersonaGenerationContextSideModalBody } from '../components/modals/PersonaGenerationContextSideModalBody.tsx';

export const PersonaCanvasDetailsPage = () => {
  const { personaId } = useParams<{ personaId: string }>();
  const { personaDetails } = usePersonaDetails(Number(personaId));

  const shouldShowTutorialInfo = useMemo(
    () => personaDetails?.positions.every((position) => position.value.length === 0),
    [personaDetails?.id],
  );

  if (!personaDetails) {
    return null;
  }

  return (
    <PageWithHeaderLayout
      header={{
        breadcrumbs: personaDetails.segmentName
          ? [
              { label: 'Data Room', href: buildUrl([Urls.DATA_ROOM]) },
              { label: 'Segments', href: buildUrl([Urls.SEGMENTS]) },
              { label: personaDetails.segmentName, href: buildUrl([Urls.SEGMENTS]) },
              { label: personaDetails.name, href: '#' },
            ]
          : [
              { label: 'Data Room', href: buildUrl([Urls.DATA_ROOM]) },
              { label: 'Segments', href: buildUrl([Urls.SEGMENTS]) },
              { label: personaDetails.name, href: '#' },
            ],
        icon: '/user-black.svg',
        rightAddons: [
          <Button
            color="secondary"
            size="sm"
            key="sources"
            onClick={() => openSideModal(<PersonaGenerationContextSideModalBody />, { personaDetails })}
          >
            <DataIcon />
            Context
          </Button>,
          <GeneratePersonaCanvasButton
            key="generate"
            personaDetails={personaDetails}
          />,
        ],
      }}
    >
      <Helmet>
        <title>Digitalfirst.ai | {personaDetails.name}</title>
      </Helmet>

      {shouldShowTutorialInfo && (
        <InfoCard
          title="Customer Persona Canvas"
          description="
             The Ideal Customer Profile Template is an essential tool for any business looking to create a comprehensive
             profile of their target customer. This template helps you gather data and insights about your customers'
             interests, demographics, and purchasing behavior, which can help you tailor your marketing campaigns and
             improve customer experiences.
           "
          buttonUrl="https://www.digitalfirst.ai/ai-canvases/customer-persona-template"
          imageUrl="https://framerusercontent.com/images/t2csKh5XQQ76irloj0oTu4dvM3w.jpeg?scale-down-to=512"
          className="mb-8"
        />
      )}

      <div className="grid grid-cols-2 items-start gap-6">
        <PersonaMainInfoCard personaDetails={personaDetails} />
        <div></div>
        {personaDetails.positions
          .sort((a, b) => {
            return a.hierarchyPosition - b.hierarchyPosition;
          })
          .map((position, i) => (
            <PersonaCanvasPositionCard
              key={i}
              personaDetails={personaDetails}
              position={position}
              index={i}
            />
          ))}
      </div>
    </PageWithHeaderLayout>
  );
};
