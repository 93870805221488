import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import DropdownMenuPanel from './DropdownMenuPanel';
import { CSS } from '@dnd-kit/utilities';
import { ItemWithId, useDndGestures } from './useDndGestures';
import { MdDragIndicator } from 'react-icons/md';

const SortableItem = ({
  id,
  onClick,
  container,
  children,
}: {
  id: string;
  onClick?: () => void;
  children: React.ReactNode;
  container?: ({ children, onClick }: { children: React.ReactNode; onClick?: () => void }) => React.ReactNode;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging, setActivatorNodeRef } = useSortable({
    id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 'auto',
    // width: "500px",
    // width: columnWidths[header.id] ? `${columnWidths[header.id]}px` : "auto",
  };

  const Panel = container ?? DropdownMenuPanel.HoverableItem;

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={style}
    >
      <Panel onClick={onClick}>
        <div className="relative flex w-full items-center gap-2">
          <div
            ref={setActivatorNodeRef}
            {...listeners}
            className="cursor-grab"
            onClick={(e) => e.stopPropagation()}
          >
            <MdDragIndicator className="h-4 w-4" />
          </div>
          {children}
        </div>
      </Panel>
    </div>
  );
};

SortableItem.PlainContainer = ({ children, onClick }: { children: React.ReactNode; onClick?: () => void }) => (
  <div onClick={onClick}>{children}</div>
);

const SortableList = <T extends ItemWithId>({
  items,
  container,
  itemRenderer,
  onOrderChange,
  onItemClick,
}: {
  items: T[];
  itemRenderer: (item: T) => React.ReactNode;
  onOrderChange: (newOrder: string[]) => void;
  container?: ({ children, onClick }: { children: React.ReactNode; onClick?: () => void }) => React.ReactNode;
  onItemClick?: (item: T) => void;
}) => {
  const { sensors, handleDragStart, handleDragEnd } = useDndGestures(items, 2, onOrderChange);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
    >
      <div className="my-2 flex w-full grow flex-col gap-2">
        <SortableContext
          items={items.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          {items.map((item) => (
            <SortableItem
              key={item.id}
              id={item.id}
              onClick={() => onItemClick?.(item)}
              container={container}
            >
              {itemRenderer(item)}
            </SortableItem>
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );
};

export default SortableList;
