import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { CanvasDetails, CanvasPosition, useGenerateCanvasSection, useSaveCanvas } from '@/api/canvases/api.ts';
import { closeSideModal, useSideModalContext } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { useFormik } from 'formik';
import { ToolName } from '../../../../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { listenOnCanvasFillerMessage } from '../../../../helpers/listen-on-canvas-filler-message.ts';
import { mutate } from 'swr';
import { setCanvasSectionProcessingState } from '@/api/canvases/store.ts';
import { GenericCanvasGenerationContextSideModalBody } from '@/components/GenericCanvasGenerationContextSideModalBody.tsx';

export const ForcedCanvasPositionGenerationContextSideModalBody = () => {
  const { dataRoomItems } = useDataRoomItems();
  const { saveCanvas } = useSaveCanvas();
  const { canvasDetails, position } = useSideModalContext<{ canvasDetails: CanvasDetails; position: CanvasPosition }>();
  const { generateCanvasSection } = useGenerateCanvasSection();

  const formik = useFormik<{ generationContextItemsIds: string[] }>({
    initialValues: {
      generationContextItemsIds: [],
    },
    onSubmit: async ({ generationContextItemsIds }) => {
      closeSideModal();
      setCanvasSectionProcessingState(canvasDetails.id, position.id, true);
      await saveCanvas(canvasDetails.templateId, canvasDetails.positions, canvasDetails.id, generationContextItemsIds);
      const wsChannel = `${ToolName.CANVAS_FILLER_POSITION}: ${uuid()}`;
      generateCanvasSection(canvasDetails.id, position.id, wsChannel);

      listenOnCanvasFillerMessage(wsChannel, () => {
        mutate(`/strategy-canvas/instance/${canvasDetails.id}`);
        setCanvasSectionProcessingState(canvasDetails.id, position.id, false);
      });
    },
  });

  return (
    <GenericCanvasGenerationContextSideModalBody
      formik={formik}
      dataRoomItems={dataRoomItems}
      submitButtonText="Generate"
    />
  );
};
