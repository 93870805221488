import { createContext, useContext } from 'react';

export const DropDownMenuContext = createContext<{
  closeDropdown: () => void;
}>({
  closeDropdown: () => {},
});

export const useCloseDropdown = () => {
  const context = useContext(DropDownMenuContext);
  return context.closeDropdown;
};
