// single sortable header cell
import { useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { flexRender, Header } from '@tanstack/react-table';
import clsx from 'clsx';
import ColumnMenu from './dropdowns/ColumnMenu';
import { DropdownMenu } from '../shared/DropdownMenu';
import { DynamicRowData } from '../api/tables';
import { ColumnTypes } from './column-types.const.tsx';
import { ColumnType } from './table.inferfaces.ts';

interface TableHeaderCellProps {
  header: Header<DynamicRowData, unknown>;
}

const TableHeaderCell = ({ header }: TableHeaderCellProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: header.id });

  const table = header.getContext().table;

  const style = {
    opacity: isDragging ? 0 : 1,
    transform: CSS.Transform.toString(transform),
    transition,
    width: header.getSize(),
  };

  const column = header.column;
  const { type } = column.columnDef.meta as { type: ColumnType };

  const resizeHandler = header.getResizeHandler();

  const startResize = useCallback(
    (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      resizeHandler(e);
    },
    [resizeHandler],
  );

  const isResizing = header.column.getIsResizing();

  return (
    <th
      ref={setNodeRef}
      style={style}
      {...attributes}
      className="border-separate border border-[#e7e7e9]"
    >
      <>
        <div className="flex cursor-pointer">
          <DropdownMenu
            DropdownControl={
              <ColumnMenu
                table={table}
                header={header}
                column={column}
              />
            }
            placement="bottom-start"
          >
            {(dropdownProps) => (
              <div
                className="flex grow items-center overflow-hidden p-3"
                {...dropdownProps}
                {...listeners}
              >
                <span className="opacity-30">{ColumnTypes[type].icon}</span>
                <span className="ml-2 min-w-0 grow select-none overflow-hidden text-ellipsis text-nowrap text-left text-sm font-normal text-[#27262c]">
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </span>
              </div>
            )}
          </DropdownMenu>

          <div
            className={clsx('w-[6px] cursor-ew-resize bg-blue-500 hover:opacity-100', {
              'opacity-0': !isResizing,
              'opacity-100': isResizing,
            })}
            onMouseDown={startResize}
            onTouchStart={startResize}
          >
            &nbsp;
          </div>
        </div>
      </>
    </th>
  );
};

export default TableHeaderCell;
