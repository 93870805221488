import { getStringDateFormat } from '@/helpers/get-string-date-format.ts';
import { DateTime } from 'luxon';
import { ColumnType, DataColumn, DataColumnSelect } from './table.inferfaces.ts';

export const columnTypeConversionFunction = new Map<
  ColumnType,
  Map<
    ColumnType,
    (
      row: {
        [p: string]: unknown;
        _id: string;
      },
      column: DataColumn,
    ) => { [key: string]: unknown; _id: string }
  >
>([
  [
    'text',
    new Map([
      [
        'number',
        (row, column) => {
          let value = row[column.id] as string;
          value = value.replace(',', '.');

          return {
            ...row,
            [column.id]: !/^\d*\.?\d*$/.test(value) ? '' : parseFloat(value),
          };
        },
      ],
      [
        'checkbox',
        (row, column) => {
          return {
            ...row,
            [column.id]: ['yes', 'true'].includes((row[column.id] as string).toLowerCase()),
          };
        },
      ],
      [
        'date',
        (row, column) => {
          const value = row[column.id] as string;
          const dateFormat = getStringDateFormat(value);

          return {
            ...row,
            [column.id]: dateFormat ? DateTime.fromFormat(value, dateFormat).toISO() : '',
          };
        },
      ],
    ]),
  ],
  [
    'number',
    new Map([
      [
        'text',
        (row, column) => {
          return {
            ...row,
            [column.id]: (row[column.id] as number).toString(),
          };
        },
      ],
    ]),
  ],
  [
    'select',
    new Map([
      [
        'text',
        (row, column) => {
          const { tags } = column as DataColumnSelect;
          return {
            ...row,
            [column.id]: tags.find((tag) => tag.id === row[column.id])?.name ?? '',
          };
        },
      ],
    ]),
  ],
  [
    'date',
    new Map([
      [
        'text',
        (row, column) => {
          const value = row[column.id] as string;
          return {
            ...row,
            [column.id]: DateTime.fromISO(value).toFormat('dd.MM.yyyy'),
          };
        },
      ],
    ]),
  ],
]);
