import { useCallback, useRef, useState } from 'react';
import { CellContext } from '@tanstack/react-table';
import { DynamicRowData } from '../../../api/tables';
import { EditableCellProps, ReadOnlyCellProps } from '../interface';

interface InternalCellContainerProps<T, TElement extends HTMLElement> extends CellContext<DynamicRowData, T> {
  readonlyCell?: React.FC<ReadOnlyCellProps<T>>;
  editableCell: React.FC<EditableCellProps<T, TElement>>;
}

export const InternalCellContainer = <T, TElement extends HTMLElement>({
  getValue,
  table,
  row,
  column,
  readonlyCell,
  editableCell,
}: InternalCellContainerProps<T, TElement>) => {
  const { editedCell, setEditedCell, updateData, handleKeyDown } = table.options.meta || {};
  const value = getValue();
  const [localValue, setLocalValue] = useState<T>(value || column.columnDef.meta!.defaultValue);
  const focusableRef = useRef<TElement>(null);

  const onChange = (newValue: T) => {
    setLocalValue(newValue);
  };

  const onBlur = () => {
    updateData?.(row.index, column.id, localValue);
    setEditedCell?.(undefined);
  };

  const isCurrentlyEditing = editedCell?.rowIndex === row.index && editedCell?.columnId === column.id;

  const activateCallback = useCallback(
    () => setEditedCell?.({ rowIndex: row.index, columnId: column.id }),
    [row, column, setEditedCell],
  );

  if (!readonlyCell || isCurrentlyEditing) {
    return (
      <div
        className="h-full w-full"
        onBlur={onBlur}
        onFocus={activateCallback}
        onClick={activateCallback}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            setLocalValue(value);
            setEditedCell?.(undefined);
            if (focusableRef.current) {
              focusableRef.current.blur();
            } else {
              onBlur();
            }
          } else if (e.key === 'Enter') {
            e.preventDefault();
            if (focusableRef.current) {
              focusableRef.current.blur();
            } else {
              onBlur();
            }
          } else {
            if (e.key === 'Tab') {
              if (focusableRef.current) {
                focusableRef.current.blur();
              } else {
                onBlur();
              }
              handleKeyDown?.(e, row.index, column.id);
            }
          }
        }}
      >
        {editableCell({ value: localValue, onChange, onBlur, innerRef: focusableRef, table, column })}
      </div>
    );
  }

  return (
    <div
      className="h-full w-full leading-[22px]"
      onClick={activateCallback}
    >
      {readonlyCell?.({ value: localValue, onClick: activateCallback })}
    </div>
  );
};
