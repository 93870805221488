import { useFlowTemplates } from '@/api/flow/get-flow-templates.ts';
import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url.ts';
import { Link } from 'react-router-dom';
import { FlowTemplateCard } from '../routes/flows-library/components/FlowTemplateCard.tsx';
import { FlowTemplateCardPlaceholder } from '../routes/flows-library/components/FlowTemplateCardPlaceholder.tsx';

export function RecommendedFlows() {
  const { flowTemplates, isLoading } = useFlowTemplates('', '');
  const randomFlows = flowTemplates.sort(() => Math.random() - 0.5).slice(0, 3);

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex gap-1">
          <img
            src="/generate-colorful.svg"
            alt=""
          />
          <span className="text-caption-md">Recommended flows</span>
        </div>
        <Link
          to={buildUrl([Urls.FLOWS_LIBRARY])}
          className="link link--sm"
        >
          View all
          <img
            src="/arrow-right-blue.svg"
            alt=""
          />
        </Link>
      </div>

      <div className="mt-3 grid grid-cols-2 gap-4 md:grid-cols-3">
        {isLoading && new Array(3).fill(null).map((_, i) => <FlowTemplateCardPlaceholder key={i} />)}

        {randomFlows?.map((flowTemplate) => (
          <FlowTemplateCard
            key={flowTemplate.id}
            template={flowTemplate}
          />
        ))}
      </div>
    </>
  );
}
