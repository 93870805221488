import EditIcon from '@assets/edit.svg';
import SearchIcon from '@assets/search-check-black.svg';
import { FormikProvider, useFormik } from 'formik';
import { useRef, useState } from 'react';
import { object, string } from 'yup';

function WebSearcherAnswerHeader({
  question,
  onEditQuestion,
  editDisabled,
}: {
  question: string;
  onEditQuestion: (question: string) => void;
  editDisabled: boolean;
}) {
  const [editMode, setEditMode] = useState(false);
  const [questionLines, setQuestionLines] = useState(0);
  const questionRef = useRef<HTMLSpanElement>(null);

  const formik = useFormik({
    initialValues: {
      question,
    },
    validationSchema: object({
      question: string().required(),
    }),
    onSubmit: ({ question }) => {
      setEditMode(false);
      onEditQuestion(question);
    },
  });

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.key === 'Escape') {
      setEditMode(false);
      formik.resetForm();
    }
  };

  const enableEdit = () => {
    const lineHeight = parseInt(window.getComputedStyle(questionRef.current!).lineHeight, 10);
    const height = questionRef.current?.offsetHeight;
    const lines = height ? Math.ceil(height / lineHeight) : 0;
    if (lines) {
      setQuestionLines(lines);
    }
    setEditMode(true);
  };

  return (
    <h2
      className="web-searcher-header mt-7 flex w-full scroll-mt-[3.75rem] items-center gap-4 rounded-2xl p-6"
      style={{ backgroundImage: 'url(/main-gradient-sm.jpg)', backgroundSize: 'cover' }}
    >
      <img
        src="/generate-gradient-purple.svg"
        alt=""
      />
      {!editMode || editDisabled ? (
        <div>
          <div className="mb-0.5 text-body-md text-neutrals-800">Asked question</div>
          <div
            className="flex min-h-9 cursor-pointer items-center gap-2 text-h3-desktop text-neutrals-900"
            onClick={enableEdit}
          >
            <span ref={questionRef}>{question}</span>
            {!editDisabled && (
              <button
                type="button"
                className="rounded-lg bg-transparent p-2"
              >
                <EditIcon />
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div className="mb-0.5 text-body-md text-neutrals-800">New question</div>

          <FormikProvider value={formik}>
            <form
              className="flex items-center justify-between gap-2"
              onSubmit={formik.handleSubmit}
            >
              {questionLines > 1 ? (
                <textarea
                  onKeyDown={handleKeyDown}
                  {...formik.getFieldProps('question')}
                  rows={questionLines}
                  className="w-full border-0 border-b border-b-primary-400 bg-transparent p-0 text-h3-desktop outline-none focus:outline-0 focus:ring-0"
                />
              ) : (
                <input
                  {...formik.getFieldProps('question')}
                  onKeyDown={handleKeyDown}
                  className="w-full grow border-b border-b-primary-400 bg-transparent text-h3-desktop outline-none"
                />
              )}
              <button
                type="submit"
                className="rounded-lg bg-primary-600 p-2.5"
              >
                <SearchIcon className="size-4 path:fill-white" />
              </button>
            </form>
          </FormikProvider>
        </div>
      )}
    </h2>
  );
}
export default WebSearcherAnswerHeader;
