import { Table, Column } from '@tanstack/react-table';
import { useState } from 'react';
import { MdVisibility } from 'react-icons/md';
import clsx from 'clsx';
import { PlusIcon } from '@heroicons/react/16/solid';
import { ChevronRightIcon } from '@heroicons/react/16/solid';
import ColumnDetailsMenu from './ColumnDetailsMenu';
import DropdownMenuPanel from '../../shared/DropdownMenuPanel';
import SortableList from '../../shared/SortableList';
import { DynamicRowData } from '../../api/tables';

interface TableDetailsMenuProps {
  table: Table<DynamicRowData>;
}

const ColumnDetailsItem: React.FC<{ column: Column<DynamicRowData, unknown>; asHidden?: boolean }> = ({
  column,
  asHidden,
}) => {
  const toggleVisibility = column.getToggleVisibilityHandler();

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-2">{column.columnDef.header as string}</div>
      <div className="flex items-center">
        <div
          className="mr-1"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            toggleVisibility(e);
          }}
        >
          <MdVisibility
            className={clsx('h-4 w-4 text-gray-500 hover:opacity-60', {
              'opacity-100': !asHidden,
              'opacity-30': asHidden,
            })}
          />
        </div>
        <div>
          <ChevronRightIcon className="h-4 w-4 text-gray-500" />
        </div>
      </div>
    </div>
  );
};

const TableDetailsMenu: React.FC<TableDetailsMenuProps> = ({ table }) => {
  const headers = table.getHeaderGroups()[0].headers;
  const hiddenColumns = table.getAllLeafColumns().filter((column) => !column.getIsVisible());

  const [editedColumn, setEditedColumn] = useState<Column<DynamicRowData, unknown> | null>(null);

  return (
    <DropdownMenuPanel>
      {editedColumn ? (
        <ColumnDetailsMenu
          table={table}
          column={editedColumn}
          onBack={() => setEditedColumn(null)}
        />
      ) : (
        <>
          <DropdownMenuPanel.Header>Table Configuration</DropdownMenuPanel.Header>
          {headers.length > 0 && (
            <>
              <DropdownMenuPanel.SubHeader>Visible Columns</DropdownMenuPanel.SubHeader>
              <SortableList
                items={headers}
                onOrderChange={(newOrder) => table.setColumnOrder(newOrder)}
                onItemClick={(item) => setEditedColumn(item.column)}
                itemRenderer={(item) => <ColumnDetailsItem column={item.column} />}
              />
            </>
          )}
          {hiddenColumns.length > 0 && (
            <>
              <DropdownMenuPanel.SubHeader>Hidden Columns</DropdownMenuPanel.SubHeader>
              <SortableList
                items={hiddenColumns}
                onOrderChange={() => {}}
                onItemClick={(item) => setEditedColumn(item)}
                itemRenderer={(item) => (
                  <ColumnDetailsItem
                    column={item}
                    asHidden
                  />
                )}
              />
            </>
          )}
          <DropdownMenuPanel.Separator />
          <DropdownMenuPanel.HoverableItem
            onClick={() => {
              table.options.meta?.addColumn?.();
              setTimeout(() => {
                setEditedColumn(table.getAllLeafColumns()[table.getAllLeafColumns().length - 1]);
              }, 1);
            }}
          >
            <div className="flex items-center gap-2">
              <PlusIcon className="h-4 w-4 text-gray-500" />
              Add Column
            </div>
          </DropdownMenuPanel.HoverableItem>
        </>
      )}
    </DropdownMenuPanel>
  );
};

export default TableDetailsMenu;
