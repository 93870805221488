import { useState } from 'react';
import { useCreateTable, useDeleteTable, useMyTables } from '../api/tables';
import { Button, Label, TextInput } from 'flowbite-react';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store';

const AddNewTableModal = ({ onCreate }: { onCreate: (name: string) => void }) => {
  const [newTableName, setNewTableName] = useState('');

  return (
    <div>
      <div>
        <Label htmlFor="table-name">Table name</Label>
        <TextInput
          autoFocus
          id="table-name"
          type="text"
          name="Table name"
          onChange={(e) => setNewTableName(e.target.value)}
          value={newTableName}
        />
      </div>
      <Button
        color="primary"
        size="sm"
        className="mt-4"
        onClick={() => onCreate(newTableName)}
      >
        Save
      </Button>
    </div>
  );
};

export default function TableSelector({
  currentTable,
  setCurrentTable,
}: {
  currentTable: string | undefined;
  setCurrentTable: (tableId: string | undefined) => void;
}) {
  const { data: tables } = useMyTables();
  const { openModal, closeModal } = useGlobalModal();
  const createTable = useCreateTable();
  const { openDialog } = useGlobalDialog();
  const deleteTable = useDeleteTable();

  return (
    <div className="flex items-center justify-between">
      <div className="flex gap-2">
        <Button.Group>
          {tables?.map((table) => (
            <Button
              key={table._id}
              color={currentTable === table._id ? 'gray' : 'secondary'}
              size="sm"
              onClick={() => {
                setCurrentTable(table._id);
              }}
            >
              {table.name}
            </Button>
          ))}
        </Button.Group>
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            // setNewTableName('');
            openModal({
              header: 'Create a table',
              body: (
                <AddNewTableModal
                  onCreate={async (name: string) => {
                    const table = await createTable(name);
                    closeModal();
                    setCurrentTable(table._id);
                  }}
                />
              ),
            });
          }}
        >
          Create table
        </Button>
      </div>
      {currentTable && (
        <Button
          color="red"
          size="sm"
          onClick={() => {
            openDialog(
              {
                header: 'Delete table',
                confirmButtonText: 'Delete',
              },
              () => {
                deleteTable(currentTable);
                setCurrentTable(undefined);
              },
            );
          }}
        >
          Delete table
        </Button>
      )}
    </div>
  );
}
