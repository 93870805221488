import { useCallback, useState } from 'react';
import {
  flip,
  FloatingFocusManager,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import { autoUpdate } from '@floating-ui/react-dom';
import { DropDownMenuContext } from './dropdown-menu.context';

type DropdownMenuProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: (props: any) => React.ReactNode;
  placement?: Placement | undefined;
  offset?: number;
  DropdownControl: React.ReactNode;
  onDropdownClose?: () => void;
  strategy?: 'fixed' | 'absolute';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const DropdownMenu = ({
  children,
  DropdownControl,
  offset: placementOffset = 4,
  placement = 'bottom',
  onDropdownClose,
  strategy = 'fixed',
  ...rest
}: DropdownMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    transform: true,
    strategy,
    middleware: [offset(placementOffset), flip(), shift()],
    open: isOpen,
    onOpenChange: (isOpen) => {
      setIsOpen(isOpen);
      if (!isOpen && onDropdownClose) {
        onDropdownClose();
      }
    },
    whileElementsMounted: autoUpdate,
  });

  const { isMounted, styles } = useTransitionStyles(context, {
    initial: { opacity: 0, transform: 'scale(0.97)' },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const closeDropdown = useCallback(() => setIsOpen(false), [setIsOpen]);

  const allProps = { ref: refs.setReference, ...getReferenceProps(), ...rest };

  return (
    <DropDownMenuContext.Provider value={{ closeDropdown }}>
      <>
        {children(allProps)}
        {isMounted && (
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              style={{ ...floatingStyles, zIndex: 10000 }}
              {...getFloatingProps()}
            >
              <div style={{ ...styles }}>{DropdownControl}</div>
            </div>
          </FloatingFocusManager>
        )}
      </>
    </DropDownMenuContext.Provider>
  );
};
