import { useApi } from '@/api/api.ts';
import { CreateProductCanvasDto } from './ProductCanvasDto';
import { useTriggerEvent } from '../events/api';
import { TagManagerEvent } from '../events/model';

export const useCreateProductCanvas = () => {
  const { postFetcher } = useApi();
  const { triggerDataLayer } = useTriggerEvent();

  const createProductCanvas = async (productCanvasDto: CreateProductCanvasDto) => {
    triggerDataLayer(TagManagerEvent.user_created_product, {});
    return postFetcher(`/product-canvas`, {
      arg: productCanvasDto,
    });
  };

  return {
    createProductCanvas,
  };
};
