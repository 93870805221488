import { useCurrentWorkspace } from '@/state/app-store.ts';
import { useApi } from '@/api/api.ts';
import { WebSearcherRoom } from '@/models/web-searcher-room.interface.ts';
import useSWR from 'swr';

export const useWebSearcherRooms = () => {
  const { getFetcher } = useApi();
  const workspaceId = useCurrentWorkspace()?.id;

  const { data } = useSWR<WebSearcherRoom[]>(`/websearcher/rooms/by-workspace/${workspaceId}`, getFetcher);

  const roomsWithSortedHistory = data?.map((room) => ({
    ...room,
    history: room.history.sort((a, b) => (a.id && b.id ? a.id - b.id : 0)),
  }));

  return {
    webSearcherRooms: roomsWithSortedHistory,
  };
};
