import { create } from 'zustand';
interface SemrushPayload {
  sub?: string;
  org_id?: null;
  aud: string;
  exp: number;
  viewer_id: string;
  is_app_installed: boolean;
  is_main_product_active: boolean;
  is_app_taken_for_free: boolean;
  is_main_product_trial_available: boolean;
  product_trials_available: [];
  active_products: [];
  email_subscription: {
    state: string;
  };
  url: string;
  lang: string;
}

interface SemrushStore {
  isSemrush: boolean;
  decodedToken: SemrushPayload | null;
}

export const useSemrush = create<SemrushStore>(() => ({
  isSemrush: false,
  decodedToken: null,
}));

export const setIsSemrush = (value: boolean) => {
  useSemrush.setState({ isSemrush: value });
};

export const setDecodedToken = (decodedToken: SemrushPayload) => {
  useSemrush.setState({ decodedToken });
};
