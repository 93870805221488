import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { CanvasDetails, useGenerateCanvas, useSaveCanvas } from '@/api/canvases/api.ts';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { closeSideModal, useSideModalContext } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { useFormik } from 'formik';
import { ToolName } from '../../../../../../libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { listenOnCanvasFillerMessage } from '../../../../helpers/listen-on-canvas-filler-message.ts';
import { mutate } from 'swr';
import { GenericCanvasGenerationContextSideModalBody } from '@/components/GenericCanvasGenerationContextSideModalBody.tsx';

export const ForcedCanvasGenerationContextSideModalBody = () => {
  const { dataRoomItems } = useDataRoomItems();
  const { saveCanvas } = useSaveCanvas();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { canvasDetails } = useSideModalContext<{ canvasDetails: CanvasDetails }>();
  const { generateCanvas } = useGenerateCanvas();

  const formik = useFormik<{ generationContextItemsIds: string[] }>({
    initialValues: {
      generationContextItemsIds: [],
    },
    onSubmit: async ({ generationContextItemsIds }) => {
      closeSideModal();
      showLoader('Generating canvas...');
      await saveCanvas(canvasDetails.templateId, canvasDetails.positions, canvasDetails.id, generationContextItemsIds);
      const wsChannel = `${ToolName.CANVAS_FILLER}: ${uuid()}`;
      await generateCanvas(canvasDetails.templateId, wsChannel);
      listenOnCanvasFillerMessage(wsChannel, () => {
        mutate(`/strategy-canvas/instance/${canvasDetails.id}`);
        hideLoader();
      });
    },
  });

  return (
    <GenericCanvasGenerationContextSideModalBody
      formik={formik}
      dataRoomItems={dataRoomItems}
      submitButtonText="Generate"
    />
  );
};
