import { useApi } from '@/api/api.ts';
import { useDataRoomId } from '@/state/app-store.ts';
import { useTriggerEvent } from '../events/api';
import { TagManagerEvent } from '../events/model';

export const useCreateSegment = (): {
  createSegment: (name: string) => Promise<void>;
} => {
  const { postFetcher } = useApi();
  const dataRoomId = useDataRoomId();
  const { triggerDataLayer } = useTriggerEvent();

  const createSegment = async (name: string) => {
    triggerDataLayer(TagManagerEvent.user_created_segment, {});
    return postFetcher(`/persona-canvas/segment`, {
      arg: {
        dataRoomId,
        name,
      },
    });
  };

  return {
    createSegment,
  };
};
