import { Label, Radio } from 'flowbite-react';
import { FormikProps, getIn } from 'formik';
import { ReactNode } from 'react';
import { cn } from '@/helpers/cn';

export const TileRadio = ({
  children,
  name,
  value,
  formik,
  className,
}: {
  children: ReactNode;
  name: string;
  value: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formik: FormikProps<any>;
  className?: string;
}) => {
  const touched = getIn(formik.touched, name);
  const error = getIn(formik.errors, name);

  return (
    <Label>
      <div
        className={cn(
          'flex cursor-pointer items-center gap-2 rounded-lg border border-neutrals-400 px-3 py-2 transition hover:border-primary-600 has-[:checked]:border-primary-600 has-[:checked]:bg-primary-100',
          className,
        )}
      >
        <Radio
          id={`${name}-${value}`}
          color={touched && error ? 'failure' : 'gray'}
          name={name}
          value={String(value)}
          checked={formik.values[name] === value}
          onChange={() => formik.setFieldValue(name, value)}
          className="cursor-pointer"
        />
        {children}
      </div>
    </Label>
  );
};
