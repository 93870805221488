import { buildUrl } from '@/helpers/build-url.ts';
import { Urls } from '@/consts/urls.ts';
import { Helmet } from 'react-helmet';
import { PageWithHeaderLayout } from '@/layouts/PageWithHeaderLayout/PageWithHeaderLayout.tsx';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { MODALS } from '@/components/GlobalModal/modals.const.tsx';
import { useProductCanvases } from '@/api/products/get-product-canvases.ts';
import { Card } from 'flowbite-react';
import { IconButton } from '@/components/IconButton.tsx';
import { Link } from 'react-router-dom';
import { useDeleteProductCanvas } from '@/api/products/delete-product-canvas';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { ProductCanvasImportFileButton } from './components/ProductCanvasImportFileButton.tsx';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useImportingProductCanvases } from './product-canvases.store.ts';

export const ProductCanvasPanel = () => {
  const { productCanvases } = useProductCanvases();
  const { deleteProductCanvas } = useDeleteProductCanvas();
  const { openModal } = useGlobalModal();
  const { openDialog } = useGlobalDialog();
  const { showLoader, hideLoader } = useGlobalLoader();
  const importingProductCanvases = useImportingProductCanvases();

  const onCreateNewProductCanvas = () => {
    openModal(MODALS.CreateProductCanvas);
  };

  const onDeleteProductCanvas = (id: string) => {
    openDialog(DIALOGS.DeleteProductCanvas, async () => {
      showLoader();
      await deleteProductCanvas(id);
      hideLoader();
    });
  };

  return (
    <>
      <PageWithHeaderLayout
        header={{
          breadcrumbs: [
            { label: 'Data Room', href: buildUrl([Urls.DATA_ROOM]) },
            { label: 'Products', href: '#' },
          ],
          icon: '/user-black.svg',
          rightAddons: [<ProductCanvasImportFileButton key="import" />],
        }}
      >
        <Helmet>
          <title>Digitalfirst.ai | Product Canvases</title>
        </Helmet>

        <div className="flex flex-col gap-10">
          <div className="grid grid-cols-[112px,1fr] items-start gap-4">
            <button
              className=""
              onClick={onCreateNewProductCanvas}
            >
              <Card className="size-[112px] border-primary-300 !bg-primary-200 hover:!bg-primary-300 active:!bg-primary-400">
                <div className="flex size-full items-center justify-center">
                  <img
                    src="/add-black.svg"
                    width={32}
                    height={32}
                    alt=""
                  />
                </div>
              </Card>
            </button>
            <div className="grid grid-cols-3 gap-4">
              {productCanvases?.map((productCanvas, i) => (
                <div
                  key={i}
                  className="relative h-[112px]"
                >
                  {importingProductCanvases.has(productCanvas._id) && (
                    <div className="absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center gap-2 rounded-xl border border-gray-divider bg-white opacity-80">
                      <img
                        src="/loading-blue.svg"
                        className="animate-spin-slow"
                        alt=""
                      />
                      <span className="text-body-sm text-neutrals-700">Importing...</span>
                    </div>
                  )}
                  <div className="absolute right-4 top-4">
                    <IconButton
                      icon="/trash-gray.svg"
                      size={24}
                      alt="Delete"
                      onClick={() => onDeleteProductCanvas(productCanvas._id)}
                    />
                  </div>
                  <Link
                    className="h-full"
                    to={buildUrl([Urls.PRODUCTS, Urls.PRODUCT_CANVAS], { productCanvasId: productCanvas._id })}
                  >
                    <Card className="h-full">
                      <div className="flex w-full">
                        <h3 className="block min-w-0 grow overflow-hidden text-ellipsis text-nowrap text-h5-desktop">
                          {productCanvas.name}
                        </h3>
                        <div className="size-6 shrink-0"></div>
                      </div>
                    </Card>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </PageWithHeaderLayout>
    </>
  );
};
