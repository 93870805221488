import { useState } from 'react';
import { TagElement } from './TagElement.tsx';

export interface TagInputProps {
  selectedTags: Tag[];
  innerRef: React.RefObject<HTMLInputElement>;
  onEnterKeyPress: () => void;
  onRemoveTagFromSelectedTags: (tag: Tag) => void;
  onInputChange: (value: string) => void;
  onUpDownArrowPress: (direction: 'up' | 'down') => void;
}

export interface Tag {
  id: string;
  name: string;
  color:
    | 'Gray'
    | 'Green'
    | 'Red'
    | 'Pink'
    | 'Violet'
    | 'Purple'
    | 'Blue'
    | 'Turquoise'
    | 'Mint'
    | 'Lime'
    | 'Olive'
    | 'Yellow'
    | 'Orange'
    | 'Brown';
}

export const TagInput = ({
  selectedTags,
  innerRef,
  onEnterKeyPress,
  onRemoveTagFromSelectedTags,
  onInputChange,
  onUpDownArrowPress,
}: TagInputProps) => {
  const [inputValue, setInputValue] = useState('');

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();

      onEnterKeyPress();
      setInputValue('');
      onInputChange('');

      setTimeout(() => {
        innerRef.current?.focus();
      }, 100);
    } else if (e.key === 'Backspace' && e.currentTarget.value === '') {
      e.preventDefault();
      onRemoveTagFromSelectedTags(selectedTags[selectedTags.length - 1]);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      onUpDownArrowPress('up');
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      onUpDownArrowPress('down');
    }
  };

  return (
    <>
      <div className="flex w-full max-w-full flex-wrap items-center gap-2 rounded-lg border border-neutrals-300 p-2 text-body-md">
        {!!selectedTags.length &&
          selectedTags.map((tag) => (
            <TagElement
              tag={tag}
              key={tag.id}
              withRemoveOption={true}
              onRemove={() => onRemoveTagFromSelectedTags(tag)}
            />
          ))}
        <input
          type="text"
          ref={innerRef}
          value={inputValue}
          autoFocus
          onChange={(e) => {
            setInputValue(e.currentTarget.value);
            onInputChange(e.currentTarget.value);
          }}
          onKeyDown={onInputKeyDown}
          className="reset-input h-8 min-w-[40%] grow p-0 text-body-md text-neutrals-700"
          placeholder="Search for an option..."
        />
      </div>
    </>
  );
};
