import { Row } from '@tanstack/react-table';

export const numberBetween = <TData>(
  row: Row<TData>,
  columnId: string,
  filterValue: [number | null, number | null],
) => {
  const cellValue = row.getValue(columnId) as string;

  if (!cellValue) return false;

  const number = parseFloat(cellValue);

  return (!filterValue[0] || number >= filterValue[0]) && (!filterValue[1] || number <= filterValue[1]);
};

numberBetween.autoRemove = (val: [number | null, number | null]) => !val[0] && !val[1];
