import { MdOutlineArrowCircleLeft } from 'react-icons/md';
import DropdownMenuPanel from '../../shared/DropdownMenuPanel.tsx';
import { Tag } from './TagInput.tsx';
import { cn } from '@/helpers/cn.ts';
import { mapTagColorToCssClasses } from '@/helpers/map-tag-color-to-css-classes.ts';
import { TagColors } from './tag-colors.const.ts';

interface EditTagFormProps {
  tag: Tag;
  onTurnOffEditingTag: () => void;
  onUpdateTag: (props: { name?: string; color?: Tag['color'] }) => void;
  onDeleteTag: () => void;
}

export const EditTagForm = ({ tag, onTurnOffEditingTag, onUpdateTag, onDeleteTag }: EditTagFormProps) => {
  return (
    <>
      <div
        onClick={onTurnOffEditingTag}
        className="mr-2 flex h-5 w-5 cursor-pointer items-center hover:text-gray-500"
      >
        <MdOutlineArrowCircleLeft className="h-5 w-5" />
      </div>
      <input
        value={tag.name}
        onChange={(e) => onUpdateTag({ name: e.target.value })}
        className="mt-3 w-full rounded-lg border border-neutrals-300 p-3 text-body-md text-neutrals-700 outline-none"
        placeholder="Tag name"
      />
      <button
        onClick={onDeleteTag}
        className="mt-1 flex w-full items-center gap-2 rounded-lg p-3 text-neutrals-1000 transition hover:bg-neutrals-100 active:bg-neutrals-200"
      >
        <img
          src="/trash-gray.svg"
          className="h-4 w-4"
          alt=""
        />
        Delete
      </button>
      <DropdownMenuPanel.Separator />
      <DropdownMenuPanel.SubHeader>Colors</DropdownMenuPanel.SubHeader>
      <div className="mt-3 flex w-full flex-col gap-0.5">
        {TagColors.map((color) => (
          <button
            key={color}
            onClick={() => onUpdateTag({ color })}
            className={cn(
              `flex w-full items-center gap-2 rounded-lg p-2 transition hover:bg-neutrals-100 active:bg-neutrals-200`,
              {
                'bg-neutrals-100': tag.color === color,
              },
            )}
          >
            <div className={cn('h-4 w-4 rounded', mapTagColorToCssClasses(color))}></div>
            <div className="grow text-left">{color}</div>
            {tag.color === color && (
              <img
                src="/approve-gray.svg"
                className="h-3 w-3"
                alt=""
              />
            )}
          </button>
        ))}
      </div>
    </>
  );
};
