import { useSensor, useSensors, PointerSensor, KeyboardSensor, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { useCallback, useState } from 'react';

import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

export interface ItemWithId {
  id: string;
}

export function useDndGestures(items: ItemWithId[], distance = 8, onOrderChange: (newOrder: string[]) => void) {
  const [draggedColumnId, setDraggedColumnId] = useState<string | null>(null);

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setDraggedColumnId(event.active.id as string);
  }, []);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active && over && active.id !== over.id) {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        const newOrder = arrayMove(
          items.map((item) => item.id),
          oldIndex,
          newIndex,
        );
        onOrderChange(newOrder);
      }

      setDraggedColumnId(null);
    },
    [items],
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  return { handleDragStart, handleDragEnd, sensors, draggedColumnId };
}
