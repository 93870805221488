import { findElementId } from '@/components/tiptap/extensions/DigitalFirst/helpers/findElementId.ts';
import { MentionReferenceType } from '@/components/tiptap/extensions/DigitalFirst/editing/mentions/mention-type.enum.ts';
import { replacePromptVars } from '@/components/tiptap/extensions/DigitalFirst/helpers/replacePromptVars.ts';
import { ProductCanvasDto } from '@/api/products/ProductCanvasDto.ts';

export const injectProductCanvasesIntoPrompt = async (
  prompt: string,
  productCanvases: ProductCanvasDto[],
  loadTableData: (tableId: string) => Promise<object>,
) => {
  let replacementPrompt = prompt;
  const productCanvasesMentionsIds = findElementId(prompt, `[data-type="${MentionReferenceType.PRODUCT_CANVAS}"]`);

  for (const productCanvasMentionId of productCanvasesMentionsIds) {
    const selector = `[data-id="${productCanvasMentionId}"]`;
    const productCanvas = productCanvases.find((productCanvas) => productCanvas._id === productCanvasMentionId);

    const tableData = await loadTableData(productCanvas!._id);

    /** % is needed for better understanding by LLM */
    const text = `%%%%%${JSON.stringify(tableData)}%%%%%`;

    replacementPrompt = replacePromptVars(replacementPrompt, text, selector);
  }

  return replacementPrompt;
};
