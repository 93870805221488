import { ColumnType } from './table.inferfaces.ts';
import { TextCell } from './cells/editable/TextCell.tsx';
import { ColumnDefinition } from './cells/interface.ts';
import {
  BsArrowDownCircle,
  BsAt,
  BsCalendar2Date,
  BsCheck,
  BsHash,
  BsLink45Deg,
  BsListUl,
  BsTextLeft,
} from 'react-icons/bs';
import { NumberCell } from './cells/editable/NumberCell.tsx';
import { CheckboxCell } from './cells/editable/CheckboxCell.tsx';
import { SingleSelectCell } from './cells/editable/SingleSelectCell.tsx';
import { MultiSelectCell } from './cells/editable/MultiSelectCell.tsx';
import { DateCell } from './cells/editable/DateCell.tsx';
import { ReadonlyUrlCell } from './cells/readonly/ReadonlyUrlCell.tsx';
import { ReadonlyEmailCell } from './cells/readonly/ReadonlyEmailCell.tsx';
import { ReadonlyNumberCell } from './cells/readonly/ReadonlyNumberCell.tsx';
import { GenericTextCell } from './cells/editable/GenericTextCell.tsx';

export const ColumnTypes = {
  text: {
    title: 'Text',
    icon: <BsTextLeft />,
    defaultValue: '',
    editableCell: TextCell,
    filterType: 'match',
  },
  number: {
    title: 'Number',
    icon: <BsHash />,
    defaultValue: '',
    readonlyCell: ReadonlyNumberCell,
    editableCell: NumberCell,
    filterType: 'range',
    filterFn: 'numberBetween',
  },
  checkbox: {
    title: 'Checkbox',
    icon: <BsCheck />,
    defaultValue: false,
    editableCell: CheckboxCell,
    filterType: 'select',
    filterFn: 'checked',
  },
  select: {
    title: 'Select',
    icon: <BsArrowDownCircle />,
    defaultValue: null,
    editableCell: SingleSelectCell,
    filterType: 'select',
    filterFn: 'includes',
  },
  multiselect: {
    title: 'Multiselect',
    icon: <BsListUl />,
    defaultValue: [],
    editableCell: MultiSelectCell,
    filterType: 'select',
    filterFn: 'includes',
  },
  date: {
    title: 'Date',
    icon: <BsCalendar2Date />,
    defaultValue: '',
    editableCell: DateCell,
    filterType: 'range',
    filterFn: 'dateBetween',
  },
  url: {
    title: 'URL',
    icon: <BsLink45Deg />,
    defaultValue: '',
    readonlyCell: ReadonlyUrlCell,
    editableCell: GenericTextCell,
    filterType: 'match',
  },
  email: {
    title: 'Email',
    icon: <BsAt />,
    defaultValue: '',
    readonlyCell: ReadonlyEmailCell,
    editableCell: GenericTextCell,
    filterType: 'match',
  },
} as { [key in ColumnType]: ColumnDefinition<unknown, HTMLInputElement | HTMLTextAreaElement | HTMLElement> };
