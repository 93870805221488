import { Tag, TagInput } from './TagInput.tsx';
import DropdownMenuPanel from '../../shared/DropdownMenuPanel.tsx';
import SortableList from '../../shared/SortableList.tsx';
import { TagElement } from './TagElement.tsx';
import { IconButton } from '@/components/IconButton.tsx';
import { cn } from '@/helpers/cn.ts';
import { BsChevronRight } from 'react-icons/bs';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { getRandomTagColor } from './get-random-tag-color.ts';

interface TagSelectorProps {
  selectedTags: Tag[];
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  onTagSelect: (tag: Tag) => void;
  onRemoveTagFromSelectedTags: (tag: Tag) => void;
  innerRef: React.RefObject<HTMLInputElement>;
  onTurnOnTagEditing: (tag: Tag) => void;
}

export const TagSelector = ({
  selectedTags,
  tags,
  setTags,
  onTagSelect,
  onRemoveTagFromSelectedTags,
  innerRef,
  onTurnOnTagEditing,
}: TagSelectorProps) => {
  const [tagSearchInput, setTagSearchInput] = useState('');
  const [tagSearchSelectedTagIndex, setTagSearchSelectedTagIndex] = useState<number>(0);
  const [nextRandomColor, setNextRandomColor] = useState<Tag['color']>(getRandomTagColor(tags));

  const filteredTags = useMemo(() => {
    return tags.filter((tag) => {
      return [...tagSearchInput].every((char) => tag.name.toLowerCase().includes(char.toLowerCase()));
    });
  }, [tags, tagSearchInput]);

  const isExactMatchForTagSearchInput = useMemo(() => {
    return filteredTags.some((tag) => tag.name.toLowerCase() === tagSearchInput.toLowerCase());
  }, [filteredTags, tagSearchInput]);

  useEffect(() => {
    setTagSearchSelectedTagIndex(0);
  }, [filteredTags]);

  const onTagOrderChange = (newOrder: string[]) => {
    const newOrderTags = newOrder.map((id) => tags.find((tag) => tag.id === id)!);
    setTags(newOrderTags);
  };

  const onSearchTagInputChange = (value: string) => {
    setTagSearchInput((prevValue) => {
      if (prevValue === '') {
        setTagSearchSelectedTagIndex(0);
      }
      return value;
    });
  };

  const onUpDownArrowPress = (direction: 'up' | 'down') => {
    setTagSearchSelectedTagIndex((prevIndex) => {
      const maxIndex = isExactMatchForTagSearchInput ? filteredTags.length - 1 : filteredTags.length;

      if (direction === 'up') {
        return prevIndex === 0 ? maxIndex : prevIndex - 1;
      } else {
        return prevIndex === maxIndex ? 0 : prevIndex + 1;
      }
    });
  };

  const onTagInputEnterKeyPress = () => {
    if (filteredTags[tagSearchSelectedTagIndex]) {
      onTagSelect(filteredTags[tagSearchSelectedTagIndex]);
    } else {
      createNewTag();
    }
  };

  const createNewTag = () => {
    const tag: Tag = {
      id: uuid(),
      name: tagSearchInput,
      color: nextRandomColor,
    };
    setNextRandomColor(getRandomTagColor(tags));
    setTags([...tags, tag]);
    onTagSelect(tag);
  };

  return (
    <>
      <TagInput
        selectedTags={selectedTags}
        onEnterKeyPress={onTagInputEnterKeyPress}
        onRemoveTagFromSelectedTags={onRemoveTagFromSelectedTags}
        innerRef={innerRef}
        onInputChange={onSearchTagInputChange}
        onUpDownArrowPress={onUpDownArrowPress}
      />
      <DropdownMenuPanel.SubHeader>Select an option or create one</DropdownMenuPanel.SubHeader>
      {tagSearchInput === '' ? (
        <SortableList
          items={tags}
          onOrderChange={onTagOrderChange}
          onItemClick={onTagSelect}
          itemRenderer={(tag) => (
            <>
              <TagElement tag={tag} />
              <IconButton
                size={20}
                icon="/three-dots-gray.svg"
                className="absolute right-1"
                onClick={(e) => {
                  e.stopPropagation();
                  onTurnOnTagEditing(tag);
                }}
              />
            </>
          )}
        />
      ) : (
        <div className="my-2 flex w-full flex-col gap-2">
          {filteredTags.map((tag, i) => (
            <button
              className={cn(
                'mx-1 flex h-9 cursor-pointer items-center gap-2 rounded-lg py-1 pr-2 transition hover:bg-gray-100',
                {
                  'bg-gray-100': tagSearchSelectedTagIndex === i,
                },
              )}
              onClick={() => {
                if (tagSearchSelectedTagIndex === i) {
                  onTagSelect(tag);
                }
              }}
              key={tag.id}
            >
              <span
                className={cn('relative left-1 inline-block size-3 transition-all', {
                  'opacity-0': tagSearchSelectedTagIndex !== i,
                })}
              >
                <BsChevronRight color="#888888" />
              </span>
              <TagElement tag={tag} />
            </button>
          ))}

          {!isExactMatchForTagSearchInput && (
            <button
              className={cn(
                'mx-1 flex h-9 cursor-pointer items-center gap-2 rounded-lg py-1 pr-2 transition hover:bg-gray-100',
                {
                  'bg-gray-100': tagSearchSelectedTagIndex === filteredTags.length,
                },
              )}
              onClick={createNewTag}
            >
              <span
                className={cn('relative left-1 inline-block size-3 transition-all', {
                  'opacity-0': tagSearchSelectedTagIndex !== filteredTags.length,
                })}
              >
                <BsChevronRight color="#888888" />
              </span>
              Create
              <TagElement
                tag={{
                  id: 'new',
                  name: tagSearchInput,
                  color: nextRandomColor,
                }}
              />
            </button>
          )}
        </div>
      )}
    </>
  );
};
