import clsx from 'clsx';
import { cn } from '@/helpers/cn.ts';
import { forwardRef } from 'react';

type DropdownMenuPanelProps = {
  autoWidth?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
  className?: string;
};

const DropdownMenuPanelBase = forwardRef<HTMLDivElement, DropdownMenuPanelProps>(
  ({ autoWidth = false, noPadding = false, children, className }, ref) => {
    return (
      <div
        className={clsx(
          'flex flex-col items-start overflow-clip rounded-lg bg-white text-sm font-normal shadow-dropdownPanel',
          {
            'w-[250px]': !autoWidth,
            'p-3': !noPadding,
          },
          className,
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

const Header = ({ children }: { children: React.ReactNode }) => (
  <div className="flex w-full items-center font-semibold">{children}</div>
);

const SubHeader = ({ children }: { children: React.ReactNode }) => (
  <div className="mt-3 w-full text-xs font-semibold text-[#37352fa6]">{children}</div>
);

const FullWidth = ({ children }: { children: React.ReactNode }) => (
  <div className="-mx-3 w-[calc(100%+1.5rem)]">{children}</div>
);

const HoverableItem = ({
  children,
  onClick,
  isActive,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  isActive?: boolean;
}) => (
  <FullWidth>
    <div
      className={cn(
        'group mx-1 flex cursor-pointer items-center rounded-lg px-2 py-1 transition hover:bg-neutrals-100 has-[button:hover,a:hover]:bg-transparent',
        { 'bg-neutrals-100': isActive },
      )}
      onClick={onClick}
    >
      {children}
    </div>
  </FullWidth>
);

const Separator = () => <hr className="-mx-3 my-2 w-[calc(100%+1.5rem)] border-gray-200" />;

type DropdownMenuPanelType = React.ForwardRefExoticComponent<
  DropdownMenuPanelProps & React.RefAttributes<HTMLDivElement>
> & {
  Header: typeof Header;
  SubHeader: typeof SubHeader;
  FullWidth: typeof FullWidth;
  HoverableItem: typeof HoverableItem;
  Separator: typeof Separator;
};

const DropdownMenuPanel = DropdownMenuPanelBase as DropdownMenuPanelType;
DropdownMenuPanel.Header = Header;
DropdownMenuPanel.SubHeader = SubHeader;
DropdownMenuPanel.FullWidth = FullWidth;
DropdownMenuPanel.HoverableItem = HoverableItem;
DropdownMenuPanel.Separator = Separator;

export default DropdownMenuPanel;
