import Select from 'react-select';
import clsx from 'clsx';
import { useMemo } from 'react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/16/solid';

const options = [
  { label: 'Ascending', value: false },
  { label: 'Descending', value: true },
];

const SortOrderSelect = ({ value, onValueChange }: { value: boolean; onValueChange: (value: boolean) => void }) => {
  const selectedOption = useMemo(() => options.find((option) => option.value === value), [value]);

  return (
    <Select
      isClearable={false}
      isSearchable={false}
      unstyled
      classNames={{
        // control: (state) => state.."border-red-500 p-0 m-0 min-h-0",
        container: () => 'rounded-md border border-[rgba(55,53,47,0.16)] text-sm p-0 m-0',
        valueContainer: () => 'p-1 px-2',
        // indicatorsContainer: (state) => "p-0 m-0 min-h-0",
        // indicatorSeparator: (state) => "p-0 m-0 min-h-0",
        // dropdownIndicator: (state) => "p-0 m-0 min-h-0",
        menu: () => 'bg-white shadow-dropdownPanel rounded-lg text-sm font-normal flex flex-col items-start p-1 ',
        // menuList: (state) => "text-sm p-0 m-0 min-h-0",
        // option: (state) => clsx("text-sm p-0 m-0 min-h-0", { "bg-red-500 text-white": state.isFocused }),
        // singleValue: (state) => "text-sm p-0 m-0 min-h-0",
        // input: (state) => "text-sm p-0 m-0 min-h-0",
        // placeholder: (state) => "text-sm p-0 m-0 min-h-0",
        // clearIndicator: (state) => "p-0 m-0 min-h-0",
        // group: (state) => "text-sm p-0 m-0 min-h-0 pt-0",
        // groupHeading: (state) => "text-sm p-0 m-0 min-h-0",
        // loadingIndicator: (state) => "text-sm p-0 m-0 min-h-0",
        // loadingMessage: (state) => "text-sm p-0 m-0 min-h-0",
        // noOptionsMessage: (state) => "text-sm p-0 m-0 min-h-0",
        // menuPortal: (state) => "text-sm p-0 m-0 min-h-0",
        // multiValue: (state) => "text-sm p-0 m-0 min-h-0",
        // multiValueLabel: (state) => "text-sm p-0 m-0 min-h-0",
        // multiValueRemove: (state) => "text-sm p-0 m-0 min-h-0",
      }}
      styles={{
        control: (base) => ({
          ...base,
          minHeight: 0,
        }),
      }}
      //   className="basic-single"
      //   classNamePrefix="select"
      components={{
        DropdownIndicator: () => <ChevronDownIcon className="mr-1 h-4 w-4 text-[rgba(55,53,47,0.3)]" />,
        Option: ({ children, innerProps, isSelected }) => (
          <div
            className="flex w-full items-center gap-2 rounded-md py-1 pl-1 pr-3 hover:bg-gray-100"
            {...innerProps}
          >
            {<CheckIcon className={clsx('h-4 w-4', { 'opacity-100': isSelected, 'opacity-0': !isSelected })} />}
            {children}
          </div>
        ),
        Menu: ({ children, innerRef, innerProps }) => (
          <div
            ref={innerRef}
            {...innerProps}
            className="absolute z-[10000] flex flex-col items-start rounded-lg bg-white p-1 text-sm font-normal shadow-dropdownPanel"
            {...innerProps}
          >
            {children}
          </div>
        ),
      }}
      options={options}
      value={selectedOption}
      onChange={(option) => option && onValueChange?.(option.value)}
    />
  );
};

export default SortOrderSelect;
