import DropdownMenuPanel from '../../shared/DropdownMenuPanel.tsx';
import { MdOutlineArrowCircleLeft } from 'react-icons/md';
import { DynamicRowData } from '../../api/tables.ts';
import { Column, Table } from '@tanstack/react-table';
import { ColumnType, FilterType } from '../table.inferfaces.ts';
import { Tag } from '../tags/TagInput.tsx';
import { CalendarDatePicker } from '../CalendarDatePicker.tsx';
import { DropdownMenu } from '../../shared/DropdownMenu.tsx';
import { DateTime } from 'luxon';
import { BsCheck } from 'react-icons/bs';
import { TagElement } from '../tags/TagElement.tsx';

interface ColumnFilterMenuProps {
  table: Table<DynamicRowData>;
  column: Column<DynamicRowData>;
  onBack?: () => void;
  showBackButton?: boolean;
}

export const ColumnFilterMenu = ({ table, column, onBack, showBackButton }: ColumnFilterMenuProps) => {
  const { type, filterType, tags } = column.columnDef.meta as { type: ColumnType; filterType: FilterType; tags: Tag[] };
  const columnFilterValue = column.getFilterValue();

  return (
    <DropdownMenuPanel>
      {showBackButton && (
        <div
          onClick={onBack}
          className="mr-2 flex h-5 w-5 cursor-pointer items-center hover:text-gray-500"
        >
          <MdOutlineArrowCircleLeft className="h-5 w-5" />
        </div>
      )}

      {filterType === 'match' ? (
        <input
          className="w-full rounded-md border border-gray-200 bg-[#f8f8f9] px-2 py-1"
          value={(columnFilterValue ?? '') as string}
          onChange={(e) => {
            table.setColumnFilters((old) => {
              const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
              return [...oldWithoutThisColumn, { id: column.id, value: e.target.value }];
            });
          }}
        />
      ) : filterType === 'range' && type === 'date' ? (
        <div className="grid grid-cols-2 gap-2">
          <DropdownMenu
            placement="bottom-start"
            DropdownControl={
              <>
                <DropdownMenuPanel className="w-[320px]">
                  <CalendarDatePicker
                    value={(columnFilterValue as [string, string])?.[0] ?? null}
                    onChange={(value) => {
                      table.setColumnFilters((old) => {
                        const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
                        return [
                          ...oldWithoutThisColumn,
                          { id: column.id, value: [value, (columnFilterValue as [string, string])?.[1] ?? null] },
                        ];
                      });
                    }}
                  />
                </DropdownMenuPanel>
              </>
            }
          >
            {(props) => (
              <input
                {...props}
                className="w-full rounded-md border border-gray-200 bg-[#f8f8f9] px-2 py-1"
                value={
                  (columnFilterValue as [string, string])?.[0]
                    ? DateTime.fromISO((columnFilterValue as [string, string])?.[0] ?? '').toFormat('dd.MM.yyyy')
                    : ''
                }
                placeholder="From"
              />
            )}
          </DropdownMenu>

          <DropdownMenu
            placement="bottom-start"
            DropdownControl={
              <>
                <DropdownMenuPanel className="w-[320px]">
                  <CalendarDatePicker
                    value={(columnFilterValue as [string, string])?.[1] ?? null}
                    onChange={(value) => {
                      table.setColumnFilters((old) => {
                        const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
                        return [
                          ...oldWithoutThisColumn,
                          { id: column.id, value: [(columnFilterValue as [string, string])?.[0] ?? null, value] },
                        ];
                      });
                    }}
                  />
                </DropdownMenuPanel>
              </>
            }
          >
            {(props) => (
              <input
                {...props}
                className="w-full rounded-md border border-gray-200 bg-[#f8f8f9] px-2 py-1"
                value={
                  (columnFilterValue as [string, string])?.[1]
                    ? DateTime.fromISO((columnFilterValue as [string, string])?.[1] ?? '').toFormat('dd.MM.yyyy')
                    : ''
                }
                placeholder="To"
              />
            )}
          </DropdownMenu>
        </div>
      ) : filterType === 'range' && type === 'number' ? (
        <div className="grid grid-cols-2 gap-2">
          <input
            className="w-full rounded-md border border-gray-200 bg-[#f8f8f9] px-2 py-1"
            value={(columnFilterValue as [number, number])?.[0] ?? ''}
            onChange={(e) => {
              table.setColumnFilters((old) => {
                const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
                return [
                  ...oldWithoutThisColumn,
                  {
                    id: column.id,
                    value: [parseInt(e.target.value), (columnFilterValue as [number, number])?.[1]],
                  },
                ];
              });
            }}
            type="number"
            placeholder="From"
          />

          <input
            className="w-full rounded-md border border-gray-200 bg-[#f8f8f9] px-2 py-1"
            value={(columnFilterValue as [number, number])?.[1] ?? ''}
            onChange={(e) => {
              table.setColumnFilters((old) => {
                const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
                return [
                  ...oldWithoutThisColumn,
                  {
                    id: column.id,
                    value: [(columnFilterValue as [number, number])?.[0], parseInt(e.target.value)],
                  },
                ];
              });
            }}
            type="number"
            placeholder="To"
          />
        </div>
      ) : filterType === 'select' && type === 'checkbox' ? (
        <>
          <DropdownMenuPanel.HoverableItem
            onClick={() => {
              table.setColumnFilters((old) => {
                const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
                return [...oldWithoutThisColumn, { id: column.id, value: true }];
              });
            }}
          >
            <button className="flex w-full items-center gap-2 text-sm">
              <span className="grow text-left">Checked</span>
              {columnFilterValue === true && <BsCheck className="h-4 w-4" />}
            </button>
          </DropdownMenuPanel.HoverableItem>

          <DropdownMenuPanel.HoverableItem
            onClick={() => {
              table.setColumnFilters((old) => {
                const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
                return [...oldWithoutThisColumn, { id: column.id, value: false }];
              });
            }}
          >
            <button className="flex w-full items-center gap-2 text-sm">
              <span className="grow text-left">Unchecked</span>
              {columnFilterValue === false && <BsCheck className="h-4 w-3" />}
            </button>
          </DropdownMenuPanel.HoverableItem>

          {(columnFilterValue === false || columnFilterValue === true) && (
            <>
              <DropdownMenuPanel.Separator />

              <DropdownMenuPanel.HoverableItem
                onClick={() => {
                  table.setColumnFilters((old) => {
                    const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
                    return [...oldWithoutThisColumn];
                  });
                }}
              >
                <button className="flex w-full items-center text-sm">
                  <span>Clear</span>
                </button>
              </DropdownMenuPanel.HoverableItem>
            </>
          )}
        </>
      ) : filterType === 'select' ? (
        <div className="mt-2 flex w-full flex-col gap-1">
          {tags.map((tag) => (
            <DropdownMenuPanel.HoverableItem
              key={tag.id}
              onClick={() => {
                table.setColumnFilters((old) => {
                  if (columnFilterValue) {
                    const oldWithoutThisColumn = old.filter((f) => f.id !== column.id);
                    let arrayFilterValue = columnFilterValue as string[];
                    arrayFilterValue = arrayFilterValue.includes(tag.id)
                      ? arrayFilterValue.filter((f) => f !== tag.id)
                      : [...arrayFilterValue, tag.id];
                    return [...oldWithoutThisColumn, { id: column.id, value: arrayFilterValue }];
                  } else {
                    return [...old, { id: column.id, value: [tag.id] }];
                  }
                });
              }}
              isActive={(columnFilterValue as string[])?.includes(tag.id)}
            >
              <div className="flex w-full items-center gap-2 text-sm">
                <span className="flex grow items-center text-left">
                  <TagElement tag={tag} />
                </span>
                {(columnFilterValue as string[])?.includes(tag.id) && <BsCheck className="h-4 w-4" />}
              </div>
            </DropdownMenuPanel.HoverableItem>
          ))}
        </div>
      ) : null}
    </DropdownMenuPanel>
  );
};
