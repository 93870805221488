import { useStripeCustomerSession } from '@/api/pricing/get-stripe-customer-session';
import { useAuth } from '@/hooks/auth/useAuth';
import { Helmet } from 'react-helmet';
import { Button } from 'flowbite-react';

export const TestABPaymentModal = () => {
  const pricingTableId = import.meta.env.VITE_STRIPE_PRICING_TABLE_ID;
  const publishableKey = import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY;
  const { clientSecret } = useStripeCustomerSession();
  const { user, logout } = useAuth();

  const getPayer = () => {
    if (user?.sub) {
      return user?.sub.replace('|', '_');
    }
  };

  return (
    <>
      <Helmet>
        <title>Digitalfirst.ai | Start trial</title>
        <script
          async
          src="https://js.stripe.com/v3/pricing-table.js"
        ></script>
      </Helmet>
      <div className="p-4">
        <div className="mb-8 grid grid-cols-[20%,minmax(0,1fr)] gap-10">
          <img
            src="/upgrade-plan-picture.jpg"
            className="h-full max-h-[400px] w-full rounded-xl object-cover"
            alt=""
          />

          <div className="flex flex-col justify-start">
            <div>
              <h2 className="mb-3 mt-4 flex w-[70%] flex-wrap items-center gap-3 text-h2-desktop">
                Start a<span className="italic">free</span>
                trial
                <div className="rounded-lg bg-purple-200 p-2">
                  <img
                    src="/generate-black.svg"
                    alt=""
                  />
                </div>
                <span className="text-h5-desktop text-neutrals-700">
                  Unlock premium features with a 3-day free trial. You can cancel anytime.
                </span>
              </h2>

              <div className="mb-6 text-body-md text-neutrals-900">Benefits:</div>

              <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                {[
                  {
                    title: 'Unlimited Credits',
                    description: 'Enjoy unrestricted access to generate as much as you need.',
                  },
                  {
                    title: 'Unlimited Team Seats',
                    description: 'Collaborate effortlessly with your entire team, no matter its size.',
                  },
                  {
                    title: 'Additional Workspace Projects',
                    description: 'Manage up to 3 projects within each workspace.',
                  },
                  {
                    title: 'Expanded Data Room Storage',
                    description: 'Securely store up to 1 GB of data in each workspace.',
                  },
                  {
                    title: 'Flows Templates Library',
                    description:
                      'Access a comprehensive library of ready-to-use strategic flow documents anytime, without limits.',
                  },
                ].map((elem, index) => (
                  <div
                    key={index}
                    className="flex items-start gap-2"
                  >
                    <img
                      src="/circle-check-black.svg"
                      alt=""
                    />
                    <div className="flex flex-col gap-1">
                      <div className="text-subtitle-sm">{elem.title}</div>
                      <div className="text-body-sm text-neutrals-700">{elem.description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <stripe-pricing-table
          pricing-table-id={pricingTableId}
          publishable-key={publishableKey}
          client-reference-id={getPayer()}
          customer-session-client-secret={clientSecret}
        ></stripe-pricing-table>

        <div className="absolute right-0 top-0 p-8">
          <Button
            color="secondary"
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          >
            Logout
          </Button>
        </div>
      </div>
    </>
  );
};
