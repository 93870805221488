import { Column, Table } from '@tanstack/react-table';
import { DynamicRowData } from '../../api/tables.ts';
import { DropdownMenu } from '../../shared/DropdownMenu.tsx';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import { ColumnFilterMenu } from './ColumnFilterMenu.tsx';
import { ColumnTypes } from '../column-types.const.tsx';
import { DateTime } from 'luxon';
import { Tag } from '../tags/TagInput.tsx';

export const FiltersMenu = ({ table }: { table: Table<DynamicRowData> }) => {
  const filters = table.getState().columnFilters.map((filter) => {
    const column = table.getColumn(filter.id) as Column<DynamicRowData>;
    const columnTags: Tag[] = column.columnDef.meta?.tags ?? [];

    let displayValue: string;
    let filterValue;
    switch (column.columnDef.meta!.type) {
      case 'date':
        filterValue = filter.value as [string, string];
        if (filterValue[0] && filterValue[1]) {
          displayValue = `${DateTime.fromISO(filterValue[0]).toFormat('dd.MM.yyyy')} - ${DateTime.fromISO(filterValue[1]).toFormat('dd.MM.yyyy')}`;
        } else if (filterValue[0]) {
          displayValue = `From ${DateTime.fromISO(filterValue[0]).toFormat('dd.MM.yyyy')}`;
        } else {
          displayValue = `To ${DateTime.fromISO(filterValue[1]).toFormat('dd.MM.yyyy')}`;
        }
        break;
      case 'number':
        filterValue = filter.value as [number, number];
        if (filterValue[0] && filterValue[1]) {
          displayValue = `${filterValue[0]} - ${filterValue[1]}`;
        } else if (filterValue[0]) {
          displayValue = `Min ${filterValue[0]}`;
        } else {
          displayValue = `Max ${filterValue[1]}`;
        }
        break;
      case 'checkbox':
        displayValue = filter.value == false ? 'Unchecked' : 'Checked';
        break;
      case 'select':
      case 'multiselect':
        filterValue = filter.value as string[];
        filterValue = filterValue.map((value) => columnTags.find((tag) => tag.id === value)?.name);
        displayValue = filterValue.join(', ');
        break;
      default:
        displayValue = filter.value as string;
        break;
    }

    return {
      column,
      icon: ColumnTypes[column.columnDef.meta!.type!].icon,
      name: column.columnDef.header as string,
      displayValue,
    };
  });

  return (
    <>
      {filters
        .sort((a, b) => a.column.id.localeCompare(b.column.id))
        .map((filter) => (
          <DropdownMenu
            key={filter.column.id}
            placement="bottom-start"
            DropdownControl={
              <ColumnFilterMenu
                table={table}
                column={filter.column}
              />
            }
          >
            {(props) => (
              <div
                {...props}
                className="flex cursor-pointer items-center gap-[2px] rounded-3xl border border-[rgb(35,131,226,0.35)] bg-[rgb(35,131,226,0.03)] px-2 py-[1px] text-sm text-[rgb(35,131,226)] hover:bg-[rgb(35,131,226,0.07)]"
              >
                {filter.icon} {filter.name}: {filter.displayValue} <ChevronDownIcon className="h-4 w-4" />
              </div>
            )}
          </DropdownMenu>
        ))}
    </>
  );
};
