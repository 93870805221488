import { useAuth } from '@/hooks/auth/useAuth';
import { GetTokenSilentlyOptions } from '@auth0/auth0-spa-js';

export const useAccessToken = (): { getAccessToken: (options?: GetTokenSilentlyOptions) => Promise<string> } => {
  const { getAccessTokenSilently, logout } = useAuth();

  return {
    getAccessToken: (options?: GetTokenSilentlyOptions) =>
      getAccessTokenSilently(options).catch(async () => {
        await logout({ logoutParams: { returnTo: window.location.origin } });
        return '';
      }),
  };
};
