import { useEffect, useRef } from 'react';

interface ThreeStateCheckboxProps {
  checked: boolean;
  indeterminate: boolean;
  onChange: ({ target: { value } }: { target: { value: boolean } }) => void;
}

const ThreeStateCheckbox = ({ checked, indeterminate, onChange }: ThreeStateCheckboxProps) => {
  const checkboxRef = useRef<HTMLInputElement>(null);

  // Effect to apply the indeterminate attribute
  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate]);

  const handleChange = () => {
    // Determine the new state based on the current one
    if (indeterminate) {
      onChange({ target: { value: true } }); // From indeterminate to unchecked
    } else {
      onChange({ target: { value: !checked } }); // From checked to indeterminate
    }
  };

  return (
    <input
      type="checkbox"
      ref={checkboxRef}
      checked={checked === true}
      onChange={handleChange}
      aria-checked={indeterminate ? 'mixed' : checked ? 'true' : 'false'}
    />
  );
};

export default ThreeStateCheckbox;
