import { Tag } from './TagInput.tsx';
import { TagColors } from './tag-colors.const.ts';

export const getRandomTagColor = (tags: Tag[]) => {
  const colors = [...TagColors];
  const sortedColorsByUsage: { color: Tag['color']; count: number }[] = colors
    .map((color) => ({
      color,
      count: tags.filter((tag) => tag.color === color).length,
    }))
    .sort((a, b) => a.count - b.count);

  const leastUsedColors = sortedColorsByUsage.filter((color) => color.count === sortedColorsByUsage[0].count);

  return leastUsedColors[Math.floor(Math.random() * leastUsedColors.length)].color;
};
