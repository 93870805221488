export function FlowTemplateCardPlaceholder() {
  return (
    <div className="animate-pulse rounded-xl border border-gray-divider bg-white p-4">
      <div className="mb-4 size-9 rounded-lg bg-neutrals-200 p-2"></div>
      <div className="mb-3 h-6 w-2/3 rounded-3xl bg-neutrals-200"></div>
      <div className="mb-2 h-3 rounded-3xl bg-neutrals-200"></div>
      <div className="mb-12 h-3 rounded-3xl bg-neutrals-200"></div>
      <div className="h-10 rounded-lg bg-neutrals-200"></div>
    </div>
  );
}
