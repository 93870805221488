import { Link } from 'react-router-dom';
import { WebSearcherRoomHistoryItem } from '@/models/web-seracher-room-history-item.interface.ts';
import { llmMarked } from '@/helpers/llmMarked.ts';
import { WebSearcherAnswerLoadingPlaceholder } from './WebSearcherAnswerLoadingPlaceholder.tsx';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { VideoEmbed } from '@/components/VideoEmbed.tsx';
import { Badge } from 'flowbite-react';
import { IconButton } from '@/components/IconButton.tsx';
import { useMemo, useState } from 'react';
import { cn } from '@/helpers/cn';
import WebSearcherAnswerHeader from './WebSearcherAnswerHeader.tsx';
import { WebSearcherAnswerModel } from '@/models/web-searcher-answer-model.interface.ts';

export function WebSearcherAnswer({
  historyItem,
  question,
  onAskFollowUpQuestion,
  onEditQuestion,
  answer,
}: {
  historyItem: WebSearcherRoomHistoryItem | undefined;
  question: string;
  onAskFollowUpQuestion: (question: string) => void;
  onEditQuestion: (question: string) => void;
  answer: WebSearcherAnswerModel;
}) {
  const [expandedSideSections, setExpandedSideSections] = useState({
    locationDetails: false,
    images: false,
    videos: false,
  });

  const maps = useMemo(() => {
    return answer?.maps.filter((place) => place.latitude && place.longitude);
  }, [answer]);

  const isLoading = !answer;

  return (
    <>
      <div className="grid grid-cols-[minmax(10%,42rem),minmax(auto,22rem)] gap-12">
        <div className="flex min-w-0 flex-col gap-10">
          <WebSearcherAnswerHeader
            question={question}
            onEditQuestion={onEditQuestion}
            editDisabled={isLoading}
            key={historyItem?.id}
          />

          {isLoading || !answer.answer ? (
            <WebSearcherAnswerLoadingPlaceholder />
          ) : (
            <>
              {/* Main Sections */}

              {answer.sources.length > 0 && (
                <section className="web-searcher-section">
                  <div className="web-searcher-section__header">
                    <div className="web-searcher-section__header__icon bg-notice-300">
                      <img
                        src="/linked-services-black.svg"
                        alt=""
                      />
                    </div>
                    <h2 className="web-searcher-section__header__title">Sources</h2>
                  </div>

                  <div className="grid grid-cols-2 gap-4 xl:grid-cols-4">
                    {answer.sources.map((source, i) => (
                      <Link
                        key={i}
                        to={source.link}
                        target="_blank"
                      >
                        <div className="flex h-full flex-col justify-between gap-2 rounded-lg border border-gray-divider bg-white p-2 transition hover:bg-gray-bg hover:text-text-primary active:bg-gray-ultra-light">
                          <div className="mb-2 text-nav-sub">{source.title}</div>
                          <div className="flex max-w-full items-center gap-2">
                            <img
                              src={`https://www.google.com/s2/favicons?domain=${source.link}`}
                              className="rounded"
                              width={20}
                              height={20}
                              alt=""
                            />
                            <div className="grow overflow-hidden text-ellipsis text-nowrap text-body-sm text-neutrals-700">
                              {source.link ? new URL(source.link).hostname : ''}
                            </div>
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </section>
              )}

              <section className="web-searcher-section">
                <div className="web-searcher-section__header">
                  <div className="web-searcher-section__header__icon bg-mint-400">
                    <img
                      src="/search-check-black.svg"
                      alt=""
                    />
                  </div>
                  <h2 className="web-searcher-section__header__title">Answer</h2>
                </div>
                <div
                  className="marked text-body-lg"
                  dangerouslySetInnerHTML={{ __html: llmMarked(answer.answer) }}
                />
              </section>

              {answer.followUpQuestions.length > 0 && (
                <section className="web-searcher-section">
                  <div className="web-searcher-section__header">
                    <div className="web-searcher-section__header__icon bg-blue-200">
                      <img
                        src="/fast-check-black.svg"
                        alt=""
                      />
                    </div>
                    <h2 className="web-searcher-section__header__title">Related</h2>
                  </div>
                  <ul className="flex flex-col overflow-hidden rounded-xl bg-neutrals-100">
                    {answer.followUpQuestions.map((question, i) => (
                      <li
                        key={i}
                        className="border-b border-neutrals-300 last:border-none"
                      >
                        <button
                          className="flex w-full items-center justify-between gap-2 p-4 text-left text-link-md transition hover:bg-neutrals-200 active:bg-neutrals-300"
                          onClick={() => onAskFollowUpQuestion(question)}
                        >
                          <div>{question}</div>
                          <img
                            src="/arrow-up-blue.svg"
                            width={20}
                            height={20}
                            alt=""
                          />
                        </button>
                      </li>
                    ))}
                  </ul>
                </section>
              )}
            </>
          )}
        </div>

        {/* Side sections */}

        <div className="mt-7 min-w-0">
          {isLoading || answer.images.length === 0 ? (
            <div className="flex flex-col gap-6">
              {new Array(3).fill(null).map((_, i) => (
                <div
                  className="h-[8rem] w-full animate-pulse rounded-xl bg-neutrals-200"
                  key={i}
                ></div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col gap-8">
              {!!answer.images.length && (
                <section className="web-searcher-section sidebar-section">
                  <div className="web-searcher-section__header">
                    <div className="web-searcher-section__header__icon bg-purple-100">
                      <img
                        src="/websearcher-image-section.svg"
                        alt=""
                      />
                    </div>
                    <h2 className="web-searcher-section__header__title">Images</h2>
                    <div className="web-searcher-section__header__addons">
                      <span className="link link--sm">View all</span>
                      <Badge color="blue">{answer.images.length}</Badge>
                      <IconButton
                        icon="/chevron-down-blue.svg"
                        size={20}
                        onClick={() =>
                          setExpandedSideSections({
                            ...expandedSideSections,
                            images: !expandedSideSections.images,
                          })
                        }
                        className={cn('transition', { 'rotate-180': expandedSideSections.images })}
                      />
                    </div>
                  </div>

                  <div
                    className={cn('grid max-h-[208px] grid-cols-2 gap-4 overflow-hidden', {
                      'max-h-[auto]': expandedSideSections.images,
                    })}
                  >
                    <PhotoProvider
                      toolbarRender={(props) => (
                        <div className="fixed left-1/2 top-3 z-50 -translate-x-1/2 transform text-sm">
                          {props.images[props.index].src}
                        </div>
                      )}
                      photoWrapClassName="bg-black"
                    >
                      {answer.images.map((imageSrc, index) => (
                        <PhotoView
                          key={index}
                          src={imageSrc}
                        >
                          <img
                            src={imageSrc}
                            alt=""
                            className="h-[96px] w-full cursor-pointer rounded-lg object-cover"
                            onError={(e) => ((e.target as HTMLImageElement).style.display = 'none')}
                          />
                        </PhotoView>
                      ))}
                    </PhotoProvider>
                  </div>
                </section>
              )}

              {!!answer.videos.length && (
                <section className="web-searcher-section sidebar-section">
                  <div className="web-searcher-section__header">
                    <div className="web-searcher-section__header__icon bg-orange-200">
                      <img
                        src="/websearcher-image-section.svg"
                        alt=""
                      />
                    </div>
                    <h2 className="web-searcher-section__header__title">Videos</h2>
                    <div className="web-searcher-section__header__addons">
                      <span className="link link--sm">View all</span>
                      <Badge color="blue">{answer.videos.length}</Badge>
                      <IconButton
                        icon="/chevron-down-blue.svg"
                        size={20}
                        onClick={() =>
                          setExpandedSideSections({
                            ...expandedSideSections,
                            videos: !expandedSideSections.videos,
                          })
                        }
                        className={cn('transition', { 'rotate-180': expandedSideSections.videos })}
                      />
                    </div>
                  </div>

                  <div
                    className={cn('flex max-h-[316px] flex-col gap-4 overflow-y-hidden', {
                      'max-h-[auto]': expandedSideSections.videos,
                    })}
                  >
                    {answer.videos.map((videoSrc, index) => (
                      <VideoEmbed
                        videoSrc={videoSrc}
                        width="100%"
                        height="150px"
                        className="rounded-lg"
                        key={index}
                      />
                    ))}
                  </div>
                </section>
              )}

              {!!maps && !!maps.length && (
                <section className="web-searcher-section sidebar-section">
                  <div className="web-searcher-section__header">
                    <div className="web-searcher-section__header__icon bg-pink-100">
                      <img
                        src="/websearcher-map-pointer.svg"
                        alt=""
                      />
                    </div>
                    <h2 className="web-searcher-section__header__title">Location details</h2>
                    {maps.length > 1 && (
                      <div className="web-searcher-section__header__addons">
                        <span className="link link--sm">View all</span>
                        <Badge color="blue">{maps.length}</Badge>
                        <IconButton
                          icon="/chevron-down-blue.svg"
                          size={20}
                          onClick={() =>
                            setExpandedSideSections({
                              ...expandedSideSections,
                              locationDetails: !expandedSideSections.locationDetails,
                            })
                          }
                          className={cn('transition', { 'rotate-180': expandedSideSections.locationDetails })}
                        />
                      </div>
                    )}
                  </div>

                  <div className="flex flex-col gap-4">
                    <div>
                      <MapContainer
                        center={[maps[0].latitude, maps[0].longitude]}
                        zoom={11}
                        scrollWheelZoom={true}
                        style={{ height: '200px', width: '100%' }}
                        className="rounded-lg"
                        zoomControl={false}
                        trackResize={true}
                      >
                        <TileLayer
                          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {maps.map((place) => (
                          <Marker
                            key={place.title}
                            position={[place.latitude, place.longitude]}
                          >
                            <Popup>
                              {place.title}, <p>Address: {place.address}</p>
                            </Popup>
                          </Marker>
                        ))}
                      </MapContainer>
                    </div>

                    {(expandedSideSections.locationDetails ? maps : maps.slice(0, 1)).map((place, index) => (
                      <div
                        className="flex flex-col gap-2 rounded-lg border border-neutral-300 p-3 text-body-sm"
                        key={index}
                      >
                        <h3 className="text-caption-md">{place.title}</h3>
                        {!!place.rating && (
                          <div className="flex items-center gap-1">
                            <span>{place.rating}</span>
                            <div className="relative bottom-[1px] flex items-center gap-0.5">
                              {new Array(5).fill(null).map((_, i) => (
                                <img
                                  key={i}
                                  src="/star-yellow.svg"
                                  className={cn({ grayscale: i + 0.5 > place.rating! })}
                                  alt=""
                                />
                              ))}
                            </div>
                            {!!place.ratingCount && <span className="text-neutrals-700">({place.ratingCount})</span>}
                          </div>
                        )}
                        <p className="text-neutrals-700">{place.address}</p>
                        <div className="flex items-center gap-3">
                          {place.phoneNumber && (
                            <a
                              href={`tel:${place.phoneNumber}`}
                              className="link link--sm"
                            >
                              {place.phoneNumber}
                            </a>
                          )}
                          {place.website && (
                            <a
                              href={place.website}
                              target="_blank"
                              className="link link--sm"
                              rel="noreferrer"
                            >
                              {new URL(place.website).hostname}
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </section>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
