import { Urls } from '@/consts/urls.ts';
import { buildUrl } from '@/helpers/build-url';
import { isOrganizationAdmin } from '@/helpers/is-organization-admin';
import { cn } from '@/helpers/cn';
import { useLocation, useNavigate } from 'react-router-dom';
import { isOnPersonalAccount } from '@/helpers/is-on-personal-account.ts';
import { useMyProducts } from '@/api/pricing/my-products.ts';
import { useOrganizations } from '@/api/user/get-organizations.ts';
import { useAuth } from '@/hooks/auth/useAuth';

export function SettingsSubHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { products } = useMyProducts();
  const { organizationsCreatedByUser } = useOrganizations();

  const hasAnyPlan = products?.some((product) => product.productType === 'plan');

  const menuItems = [
    {
      label: 'Create organization',
      url: Urls.CREATE_ORGANIZATION,
      show: isOnPersonalAccount(user) && hasAnyPlan && !organizationsCreatedByUser?.length,
    },
    {
      label: 'Organization settings',
      url: Urls.ORGANIZATION_SETTINGS,
      show: isOrganizationAdmin(user),
    },
    {
      label: 'Organization members',
      url: Urls.ORGANIZATION_MEMBERS,
      show: isOrganizationAdmin(user),
    },
    {
      label: 'User settings',
      url: Urls.USER_SETTINGS,
      show: true,
    },
    {
      label: 'Billing',
      url: Urls.PRICING,
      show: isOrganizationAdmin(user) || isOnPersonalAccount(user),
    },
  ];

  return (
    <div className="flex cursor-pointer gap-1 border-b px-8 py-3">
      {menuItems
        .filter((menuItem) => menuItem.show)
        .map((menuItem) => {
          const isActive = location.pathname.includes(menuItem.url);
          return (
            <div
              key={menuItem.url}
              onClick={() => navigate(buildUrl([menuItem.url]))}
              className={cn(
                'rounded-lg px-4 py-2 text-caption-md',
                !isActive && 'text-text-secondary',
                isActive && 'bg-primary-100 text-primary-default',
              )}
            >
              {menuItem.label}
            </div>
          );
        })}
    </div>
  );
}
