import React, { KeyboardEvent, useCallback, useMemo, useState } from 'react';
import { LuSettings2 } from 'react-icons/lu';
import { HiMiniMagnifyingGlass } from 'react-icons/hi2';
import { v4 as uuidv4 } from 'uuid';
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  ColumnSizingState,
  FilterFnOption,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table';
import { ColumnType, DataColumn, TableProps } from './table.inferfaces';
import { ColumnTypes } from './column-types.const.tsx';
import { closestCenter, DndContext, DragOverlay } from '@dnd-kit/core';
import { useTableHeaderGestures } from './useTableHeaderGestures';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';

import { horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import TableHeaderCell from './TableHeaderCell';
import DraggedColumnOverlay from './DraggedColumnOverlay';
import TableDetailsMenu from './dropdowns/TableDetailsMenu';
import { DropdownMenu } from '../shared/DropdownMenu';
import { PlusIcon } from '@heroicons/react/16/solid';
import SortDropdown from './dropdowns/SortDropdown';
import clsx from 'clsx';
import ThreeStateCheckbox from '../shared/ThreeStateCheckbox';
import { FaRegTrashCan } from 'react-icons/fa6';
import { DynamicRowData } from '../api/tables';
import { InternalCellContainer } from './cells/internals/InternalCellContainer.tsx';
import { Tag } from './tags/TagInput.tsx';
import { isSelectColumn } from './tags/is-select-column.ts';
import { checked } from './filters/checked.ts';
import { dateBetween } from './filters/date-between.ts';
import { includes } from './filters/includes.ts';
import { FiltersMenu } from './dropdowns/FiltersMenu.tsx';
import { notUndefined, useVirtualizer } from '@tanstack/react-virtual';
import { numberBetween } from './filters/number-between.ts';
import './Table.scss';
import { useGlobalDialog } from '@/components/GlobalDialog/global-dialog.store.ts';
import { DIALOGS } from '@/consts/dialogs.const.ts';
import { columnTypeConversionFunction } from './column-type-conversion-function.const.ts';
import { cn } from '@/helpers/cn.ts';
import { cellHeight } from './cell-height.const.ts';

const Table: React.FC<TableProps> = ({
  data,
  columns,
  viewConfig,
  onViewConfigChange,
  onColumnsChange,
  onRowUpdate,
  onRowsDelete,
  onDataChange,
  handleRefillAllRows,
}) => {
  const [editedCell, setEditedCell] = useState<{ rowIndex: number; columnId: string } | undefined>(undefined);
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [isGlobalFilterExpanded, setIsGlobalFilterExpanded] = useState<boolean>(false);

  const tableColumns = useMemo<ColumnDef<DynamicRowData>[]>(
    () =>
      columns.map((col) => ({
        accessorKey: col.id,
        header: col.header,
        filterFn: (ColumnTypes[col.type].filterFn as FilterFnOption<DynamicRowData>) ?? 'auto',
        meta: {
          type: col.type,
          defaultValue: ColumnTypes[col.type].defaultValue,
          tags: isSelectColumn(col) ? col.tags : undefined,
          filterType: ColumnTypes[col.type].filterType,
        },
        cell: (props) => (
          <InternalCellContainer
            {...props}
            readonlyCell={ColumnTypes[col.type].readonlyCell}
            editableCell={ColumnTypes[col.type].editableCell}
          />
        ),
      })),
    [columns],
  );

  const table = useReactTable<DynamicRowData>({
    data,
    columns: tableColumns,
    defaultColumn: {
      size: 250,
      minSize: 120,
      maxSize: 5000,
    },
    state: {
      sorting: viewConfig?.sorting,
      columnOrder: viewConfig?.columnOrder,
      columnSizing: viewConfig?.columnSizing,
      columnVisibility: viewConfig?.columnVisibility,
      columnFilters: viewConfig?.columnFilters,
      globalFilter,
    },
    meta: {
      editedCell,
      setEditedCell,
      addColumn: () => {
        onColumnsChange?.((old: DataColumn[]) => [
          ...old,
          { id: uuidv4(), header: 'New Column', type: 'text', defaultValue: '' } as DataColumn,
        ]);
      },
      updateColumnName: (columnId: string, name: string) => {
        const newColumns = columns.map((col) => {
          if (col.id === columnId) {
            return { ...col, header: name };
          }
          return col;
        });
        onColumnsChange?.(newColumns);
      },
      updateColumnType: async (columnId: string, type: ColumnType) => {
        const newColumns = await Promise.all(
          columns.map(async (col) => {
            if (col.id === columnId) {
              await mapCellValuesOnColumnTypeChange(col, type);
              const newCol = { ...col, type };
              if (isSelectColumn(newCol)) {
                newCol.tags = [];
              }
              return newCol;
            }
            return col;
          }),
        );
        onColumnsChange?.(newColumns);
      },
      updateColumnTags: (columnId: string, tags: Tag[]) => {
        const newColumns = columns.map((col) => {
          if (col.id === columnId) {
            return { ...col, tags };
          }
          return col;
        });
        onColumnsChange?.(newColumns);
      },
      deleteColumn: (columnId: string) => {
        onColumnsChange?.((old) => old.filter((col) => col.id !== columnId));
      },
      updateData: (rowIndex: number, columnId: string, value: unknown) => {
        onDataChange?.((oldData) => {
          const newData = [...oldData];
          newData[rowIndex][columnId] = value;
          onRowUpdate?.(table.getCoreRowModel().rows[rowIndex].original);
          return newData;
        });
      },
      addEmptyRow: () => {
        const newRow = columns.reduce(
          (acc, col) => {
            acc[col.id] = '';
            return acc;
          },
          {
            _id: uuidv4(),
          } as DynamicRowData,
        );
        onDataChange?.((old: DynamicRowData[]) => [...old, newRow] as DynamicRowData[]);
      },
      deleteSelectedRows: () => {
        const selectedIds = table.getState().rowSelection;
        const newData = table.getCoreRowModel().rows.filter((row) => !selectedIds[row.id]);
        const removedRows = table.getCoreRowModel().rows.filter((row) => selectedIds[row.id]);
        onDataChange?.(newData.map((row) => row.original));
        table.resetRowSelection();

        onRowsDelete?.(removedRows.map((r) => r.original));
      },
      handleKeyDown: (e: KeyboardEvent<HTMLDivElement>, rowIndex: number, columnId: string) => {
        // TODO obsluga esc
        if (e.key === 'Tab') {
          e.preventDefault();
          const columnIds = table
            .getAllLeafColumns()
            .filter((col) => col.getIsVisible())
            .map((col) => col.id);
          const currentColIndex = columnIds.indexOf(columnId);
          let nextColIndex = e.shiftKey ? currentColIndex - 1 : currentColIndex + 1;
          let nextRowIndex = rowIndex;

          if (nextColIndex < 0) {
            nextColIndex = columnIds.length - 1;
            nextRowIndex--;
          } else if (nextColIndex >= columnIds.length) {
            nextColIndex = 0;
            nextRowIndex++;
          }

          if (nextRowIndex == data.length) {
            table.options.meta?.addEmptyRow?.();
            setEditedCell({ rowIndex: nextRowIndex, columnId: columnIds[nextColIndex] });
          } else if (nextRowIndex >= 0 && nextRowIndex < data.length) {
            setEditedCell({ rowIndex: nextRowIndex, columnId: columnIds[nextColIndex] });
          }
        }
      },
    },
    columnResizeMode: 'onChange',
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    filterFns: {
      dateBetween: dateBetween,
      checked: checked,
      includes: includes,
      numberBetween: numberBetween,
    },
    globalFilterFn: 'includesString',

    onSortingChange: (updater) => {
      const sorting = updater instanceof Function ? updater(viewConfig?.sorting || ([] as SortingState)) : updater;
      onViewConfigChange?.({ ...viewConfig, sorting });
    },
    onColumnOrderChange: (updater) => {
      const columnOrder =
        updater instanceof Function ? updater(viewConfig?.columnOrder || ({} as ColumnOrderState)) : updater;
      onViewConfigChange?.({ ...viewConfig, columnOrder });
    },
    onColumnSizingChange: (updater) => {
      const columnSizing =
        updater instanceof Function ? updater(viewConfig?.columnSizing || ({} as ColumnSizingState)) : updater;
      onViewConfigChange?.({ ...viewConfig, columnSizing });
    },
    onColumnVisibilityChange: (updater) => {
      const columnVisibility =
        updater instanceof Function ? updater(viewConfig?.columnVisibility || ({} as VisibilityState)) : updater;
      onViewConfigChange?.({ ...viewConfig, columnVisibility });
    },
    onColumnFiltersChange: (updater) => {
      const columnFilters =
        updater instanceof Function ? updater(viewConfig?.columnFilters || ({} as ColumnFiltersState)) : updater;
      onViewConfigChange?.({ ...viewConfig, columnFilters });
    },
  });

  const tableContainer = React.useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: table.getRowModel().rows.length,
    getScrollElement: () => tableContainer.current,
    estimateSize: () => cellHeight,
    overscan: 20,
  });

  const items = virtualizer.getVirtualItems();
  const [before, after] =
    items.length > 0
      ? [
          notUndefined(items[0]).start - virtualizer.options.scrollMargin,
          virtualizer.getTotalSize() - notUndefined(items[items.length - 1]).end,
        ]
      : [0, 0];

  const { sensors, handleDragStart, handleDragEnd, draggedColumnId } = useTableHeaderGestures(table);

  const headers = table.getHeaderGroups()[0].headers;

  const selectedRowsCount = table.getSelectedRowModel().rows.length;

  const { openDialog } = useGlobalDialog();

  const mapCellValuesOnColumnTypeChange = useCallback(
    async (column: DataColumn, newType: ColumnType): Promise<void> => {
      return new Promise((resolve) => {
        const oldType = column.type;

        if (oldType === newType) {
          resolve();
          return;
        }

        const textTypes = ['text', 'url', 'email'];
        if (textTypes.includes(oldType) && textTypes.includes(newType)) {
          resolve();
          return;
        }

        const conversionFunction = columnTypeConversionFunction.get(oldType)?.get(newType);

        if (!conversionFunction) {
          openDialog(DIALOGS.ChangeTypeOfTableColumn, () => {
            onDataChange?.((oldData) => {
              const newRows = oldData.map((row) => {
                if (newType === 'select') {
                  return {
                    ...row,
                    [column.id]: null,
                  };
                } else if (newType === 'multiselect') {
                  return {
                    ...row,
                    [column.id]: [],
                  };
                } else {
                  return {
                    ...row,
                    [column.id]: '',
                  };
                }
              });
              handleRefillAllRows?.(newRows);

              return newRows;
            });

            resolve();
          });
        } else {
          onDataChange?.((oldData) => {
            const newRows = oldData.map((row) => {
              return conversionFunction(row, column);
            });
            handleRefillAllRows?.(newRows);

            return newRows;
          });
          resolve();
        }
      });
    },
    [onDataChange, handleRefillAllRows],
  );

  return (
    <div className="flex max-h-full min-w-full flex-col overflow-x-auto">
      <div className="flex shrink-0 justify-between rounded-t-2xl border border-b-0 p-2">
        <div className="ml-2 flex flex-wrap items-center gap-3">
          <SortDropdown table={table} />
          <FiltersMenu table={table} />
          {!!selectedRowsCount && (
            <button
              className="flex items-center py-2 text-xs font-semibold text-red-500 hover:opacity-70"
              onClick={() => {
                table.options.meta?.deleteSelectedRows?.();
              }}
            >
              <FaRegTrashCan className="mr-2 h-4 w-4 text-red-500" />
              Delete {selectedRowsCount} {selectedRowsCount > 1 ? 'rows' : 'row'}
            </button>
          )}
        </div>
        <div className="flex items-center gap-3">
          <div className="flex items-center">
            <button onClick={() => setIsGlobalFilterExpanded((prev) => !prev)}>
              <HiMiniMagnifyingGlass className="h-4 w-4" />
            </button>
            <div
              className={cn('w-0 overflow-hidden transition-all duration-200', {
                'ml-2 w-[250px]': isGlobalFilterExpanded,
              })}
            >
              <input
                type="text"
                className="h-8 w-[250px] rounded-lg border border-gray-200 px-3 text-xs"
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
            </div>
          </div>

          <DropdownMenu
            DropdownControl={<TableDetailsMenu table={table} />}
            placement="bottom-end"
          >
            {(props) => (
              <button
                className="flex items-center self-center rounded-lg bg-[#4B30F0] px-3 py-2 text-xs font-bold text-white"
                {...props}
              >
                <LuSettings2 className="mr-2 h-4 w-4" />
                Configure
              </button>
            )}
          </DropdownMenu>
        </div>
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        modifiers={[restrictToHorizontalAxis]}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
      >
        <div
          ref={tableContainer}
          className="table-container"
        >
          <table
            className="shrink-0 table-fixed border-collapse"
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              width: table.getCenterTotalSize(),
            }}
          >
            <thead className="sticky top-[-1px] z-10">
              <SortableContext
                items={headers.map((header) => header.id)}
                strategy={horizontalListSortingStrategy}
              >
                <tr className="border border-gray-200 bg-[#f8f8f9]">
                  {headers.length > 0 && (
                    <th
                      className="w-[45px] max-w-[45px]"
                      style={{ width: 45 }}
                    >
                      <ThreeStateCheckbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                      />
                    </th>
                  )}
                  {headers.map((header) => (
                    <TableHeaderCell
                      key={header.id}
                      header={header}
                    />
                  ))}
                </tr>
              </SortableContext>
            </thead>
            <tbody>
              {before > 0 && (
                <tr>
                  <td
                    colSpan={headers.length}
                    style={{ height: before }}
                  />
                </tr>
              )}
              {items.map((virtualRow) => {
                const row = table.getRowModel().rows[virtualRow.index];

                return (
                  <tr
                    key={row.id}
                    style={{
                      height: `${virtualRow.size}px`,
                    }}
                    className="group border border-gray-200 bg-white"
                  >
                    {headers.length > 0 && (
                      <td
                        className={clsx('w-[45px] max-w-[45px] text-center', {
                          'opacity-15 group-hover:opacity-100': !row.getIsSelected(),
                          visible: row.getIsSelected(),
                        })}
                        style={{ width: 45 }}
                      >
                        <input
                          type="checkbox"
                          checked={row.getIsSelected()}
                          onChange={row.getToggleSelectedHandler()}
                        />
                      </td>
                    )}
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="border border-[#e7e7e9] text-sm"
                        style={{ width: `${cell.column.getSize()}px`, height: cellHeight }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                );
              })}
              {after > 0 && (
                <tr>
                  <td
                    colSpan={headers.length}
                    style={{ height: after }}
                  />
                </tr>
              )}
            </tbody>
          </table>
          {/* Placeholder for easier resizing columns horizontally */}
          <div className="w-[400px] shrink-0"></div>
        </div>
        <DragOverlay>
          {draggedColumnId ? (
            <DraggedColumnOverlay
              id={draggedColumnId}
              table={table}
            />
          ) : null}
        </DragOverlay>
      </DndContext>

      {headers.length > 0 && (
        <div className="flex shrink-0 rounded-b-2xl border border-t-0 text-sm">
          <div
            className="flex cursor-pointer items-center gap-2 px-2 py-3 opacity-50 hover:opacity-100"
            onClick={() => {
              table?.options.meta?.addEmptyRow?.();

              const columnIds = table
                .getAllLeafColumns()
                .filter((col) => col.getIsVisible())
                .map((col) => col.id);

              setTimeout(() => virtualizer.scrollToIndex(data.length, { align: 'start' }), 100);
              setEditedCell({ rowIndex: data.length, columnId: columnIds[0] });
            }}
          >
            <PlusIcon className="h-4 w-4" />
            Add new row
          </div>
        </div>
      )}
    </div>
  );
};

export default Table;
