import { useAuth } from '@/hooks/auth/useAuth';

export const useSwitchToOrganizationAccount = (): {
  switchToOrganizationAccount: (organization: string) => Promise<void>;
} => {
  const { loginWithRedirect } = useAuth();

  const switchToOrganizationAccount = async (organization: string): Promise<void> => {
    return loginWithRedirect({
      authorizationParams: {
        organization,
        redirect_uri: window.location.origin,
      },
    });
  };

  return {
    switchToOrganizationAccount,
  };
};
