import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useGlobalModal } from '@/components/GlobalModal/global-modal-store.ts';
import { FormikProvider, useFormik } from 'formik';
import { Button, Label, TextInput } from 'flowbite-react';
import { mutate } from 'swr';
import { useCreateProductCanvas } from '@/api/products/create-product-canvas.ts';
import { DataColumn } from '../../../libs/tables/table/table.inferfaces.ts';
import { getDfFieldProps } from '@/helpers/get-df-flowbite-field-props.ts';
import { ProductCanvasDto } from '@/api/products/ProductCanvasDto.ts';

export const ProductCanvasModalBody = () => {
  const { createProductCanvas } = useCreateProductCanvas();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { closeModal, context } = useGlobalModal<{ productCanvas: ProductCanvasDto }>();
  const productCanvas = context?.productCanvas;
  const isEditMode = !!productCanvas;

  const formik = useFormik<{ productCanvasName: string }>({
    initialValues: {
      productCanvasName: isEditMode ? productCanvas.name : '',
    },
    onSubmit: async ({ productCanvasName }) => {
      closeModal();
      showLoader();
      isEditMode
        ? {}
        : await createProductCanvas({
            name: productCanvasName,
            columnsDef: [
              {
                id: 'name',
                header: 'Name',
                type: 'text',
                systemDefinedColumn: true,
              },
              {
                id: 'description',
                header: 'Description',
                type: 'text',
                systemDefinedColumn: true,
              },
              {
                id: 'features',
                header: 'Features',
                type: 'text',
                systemDefinedColumn: true,
              },
              {
                id: 'benefits',
                header: 'Benefits',
                type: 'text',
                systemDefinedColumn: true,
              },
              {
                id: 'price',
                header: 'Price',
                type: 'text',
                systemDefinedColumn: true,
              },
              {
                id: 'margin',
                header: 'Margin [%]',
                type: 'text',
                systemDefinedColumn: true,
              },
              {
                id: 'sku',
                header: 'SKU',
                type: 'text',
                systemDefinedColumn: true,
              },
              {
                id: 'gtin',
                header: 'GTIN',
                type: 'text',
                systemDefinedColumn: true,
              },
            ] as DataColumn[],
            viewConfig: {},
          });
      await mutate(`/product-canvas/by-workspace`);
      hideLoader();
    },
  });

  return (
    <>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <div className="mt-6">
            <div className="mt-6">
              <Label
                htmlFor="productCanvasName"
                value="Product Canvas Name"
              />
              <TextInput
                {...getDfFieldProps('productCanvasName', formik)}
                placeholder="Enter product canvas name"
              />
            </div>
          </div>

          <div className="modal-divider mb-6 mt-8"></div>

          <div className="flex items-center justify-end gap-3">
            <Button
              color="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              type="submit"
              disabled={!formik.values.productCanvasName}
            >
              {isEditMode ? 'Save' : 'Create'}
            </Button>
          </div>
        </form>
      </FormikProvider>
    </>
  );
};
