import { DateTime } from 'luxon';

export const getStringDateFormat = (dateString: string): string | null => {
  const dateFormats = [
    'dd-MM-yyyy',
    'dd/MM/yyyy',
    'dd.MM.yyyy',
    'dd MM yyyy',
    'MM-dd-yyyy',
    'MM/dd/yyyy',
    'MM.dd.yyyy',
    'MM dd yyyy',
    'yyyy-MM-dd',
    'yyyy/MM/dd',
    'yyyy.MM.dd',
    'yyyy MM dd',
  ];

  for (const format of dateFormats) {
    const date = DateTime.fromFormat(dateString, format);
    if (date.isValid) {
      return format;
    }
  }
  return null;
};
