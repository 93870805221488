import { ChevronDownIcon, PlusIcon } from '@heroicons/react/16/solid';
import { Table } from '@tanstack/react-table';
import { FaArrowUp, FaArrowDown, FaArrowsUpDown } from 'react-icons/fa6';
import { DropdownMenu } from '../../shared/DropdownMenu';
import DropdownMenuPanel from '../../shared/DropdownMenuPanel';
import SortableList from '../../shared/SortableList';
import { FaRegTrashCan } from 'react-icons/fa6';
import { useCallback, useMemo, useState } from 'react';
import ColumnSelector from './ColumnSelector';
import { IoIosClose } from 'react-icons/io';
// import Select from "./Select";
import SortOrderSelect from './SortOrderSelect';
import { DynamicRowData } from '../../api/tables';

// const ColumnLabel = ({ column }: { column: ColumnDef<any, any> }) => {
const ColumnNameFromId = ({ table, id }: { table: Table<DynamicRowData>; id: string }) => {
  const columnName = useMemo(
    () => table.getAllFlatColumns().find((col) => col.id === id)?.columnDef.header as string,
    [table, id],
  );

  return <div>{columnName}</div>;
};

const SortDropdownMenu = ({ table }: { table: Table<DynamicRowData> }) => {
  const sortingState = table.getState().sorting;

  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);

  const handleAddSort = useCallback(
    (columnId: string) => {
      table.setSorting((prev) => [...prev.filter((s) => s.id !== columnId), { id: columnId, desc: false }]);
      setIsColumnSelectorOpen(false);
    },
    [table],
  );

  const handleNewSortingOrder = useCallback(
    (newOrder: string[]) => {
      table.setSorting((oldSorting) =>
        newOrder.map((id) => ({ id, desc: !!oldSorting.find((s) => s.id === id)?.desc })),
      );
    },
    [table],
  );
  const handleRemoveSort = useCallback(
    (id: string) => {
      table.setSorting((prev) => prev.filter((s) => s.id !== id));
    },
    [table],
  );
  const handleChangeSortingDirection = useCallback(
    (id: string, desc: boolean) => {
      table.setSorting((prev) => prev.map((s) => (s.id === id ? { id, desc } : s)));
    },
    [table],
  );

  return (
    <DropdownMenuPanel autoWidth>
      {isColumnSelectorOpen ? (
        <ColumnSelector
          table={table}
          hiddenColumnIds={sortingState.map((s) => s.id)}
          onSelect={(columnId) => handleAddSort(columnId)}
          onBack={() => setIsColumnSelectorOpen(false)}
        />
      ) : (
        <>
          <DropdownMenuPanel.Header>Sort by</DropdownMenuPanel.Header>
          <SortableList
            container={({ children, onClick }) => <div onClick={onClick}>{children}</div>}
            items={sortingState}
            itemRenderer={(sortingStage) => (
              <div className="flex w-full items-center justify-between">
                <ColumnNameFromId
                  table={table}
                  id={sortingStage.id}
                />
                <div className="ml-4 flex items-center gap-2">
                  <SortOrderSelect
                    value={sortingStage.desc}
                    onValueChange={(a) => handleChangeSortingDirection(sortingStage.id, a)}
                  />
                  {/* <Select initialIndex={sortingStage.desc ? 1 : 0}>
                    <Select.Option label="Ascending" />
                    <Select.Option label="Descending" />
                  </Select> */}
                  <button
                    className="hover:opacity-50"
                    onClick={() => handleRemoveSort(sortingStage.id)}
                  >
                    <IoIosClose className="h-6 w-6" />
                  </button>
                </div>
              </div>
            )}
            onOrderChange={(newOrder) => handleNewSortingOrder(newOrder)}
          />
          <DropdownMenuPanel.Separator />
          <DropdownMenuPanel.HoverableItem
            onClick={() => {
              setIsColumnSelectorOpen(true);
            }}
          >
            <div className="flex items-center gap-2 py-[2px]">
              <PlusIcon className="h-4 w-4 text-gray-500" />
              Add sort
            </div>
          </DropdownMenuPanel.HoverableItem>
          <DropdownMenuPanel.HoverableItem
            onClick={() => {
              table.resetSorting();
            }}
          >
            <div className="flex items-center gap-2 py-[2px] group-hover:text-red-500">
              <FaRegTrashCan className="h-4 w-4 text-gray-500 group-hover:text-red-500" />
              Clear sorting
            </div>
          </DropdownMenuPanel.HoverableItem>
        </>
      )}
    </DropdownMenuPanel>
  );
};

const SortDropdown = ({ table }: { table: Table<DynamicRowData> }) => {
  const sortingState = table.getState().sorting;
  if (sortingState.length === 0) return null;

  let label = '';
  let descending = false;

  if (sortingState.length == 1) {
    label = table.getAllFlatColumns().find((col) => col.id === sortingState[0].id)?.columnDef.header as string;
    descending = sortingState[0].desc;
  } else {
    label = `${sortingState.length} sorts`;
  }

  const Icon = sortingState.length > 1 ? FaArrowsUpDown : descending ? FaArrowDown : FaArrowUp;

  return (
    <DropdownMenu
      placement="bottom-start"
      DropdownControl={<SortDropdownMenu table={table} />}
    >
      {(props) => (
        <div
          {...props}
          className="flex cursor-pointer items-center gap-[2px] rounded-3xl border border-[rgb(35,131,226,0.35)] bg-[rgb(35,131,226,0.03)] px-2 py-[1px] text-sm text-[rgb(35,131,226)] hover:bg-[rgb(35,131,226,0.07)]"
        >
          <Icon className="h-3 w-3" /> {label} <ChevronDownIcon className="h-4 w-4" />
        </div>
      )}
    </DropdownMenu>
  );
};

export default SortDropdown;
