import { CanvasDetails, CanvasPosition, useGenerateCanvasSection, useSaveCanvas } from '@/api/canvases/api.ts';
import { setCanvasSectionProcessingState, useCanvasSectionProcessingStatus } from '@/api/canvases/store.ts';
import { listenOnCanvasFillerMessage } from '../../../helpers/listen-on-canvas-filler-message.ts';
import { mutate } from 'swr';
import { ToolName } from 'src/libs/tools/type.model.ts';
import { v4 as uuid } from 'uuid';
import { CanvasPositionCard } from '@/components/CanvasPositionCard.tsx';
import { useGlobalLoader } from '@/components/GlobalLoader/global-loader.store.ts';
import { useDataRoomItems } from '@/api/data-room/get-data-room-items.ts';
import { openSideModal } from '@/components/GlobalSideModal/global-side-modal.store.ts';
import { ForcedCanvasPositionGenerationContextSideModalBody } from './modals/ForcedCanvasPositionGenerationContextSideModalBody.tsx';

export function StrategyCanvasPositionCard({
  position,
  canvasDetails,
  index,
}: {
  position: CanvasPosition;
  canvasDetails: CanvasDetails;
  index: number;
}) {
  const { saveCanvas } = useSaveCanvas();
  const { generateCanvasSection } = useGenerateCanvasSection();
  const { showLoader, hideLoader } = useGlobalLoader();
  const { isSmallDataRoom } = useDataRoomItems();

  const isProcessing = useCanvasSectionProcessingStatus(canvasDetails.id, position.id);

  const onSave = async (newPositionValue: string[]) => {
    const newPositions = canvasDetails.positions.filter((p) => p.id !== position.id);
    newPositions.push({
      ...position,
      value: newPositionValue,
    });
    showLoader();
    await saveCanvas(canvasDetails.templateId, newPositions, canvasDetails.id);
    hideLoader();
  };

  const onGenerate = async () => {
    if (isSmallDataRoom || canvasDetails.generationContextItemsIds) {
      setCanvasSectionProcessingState(canvasDetails.id, position.id, true);
      const wsChannel = `${ToolName.CANVAS_FILLER_POSITION}: ${uuid()}`;
      generateCanvasSection(canvasDetails.id, position.id, wsChannel);

      listenOnCanvasFillerMessage(wsChannel, () => {
        mutate(`/strategy-canvas/instance/${canvasDetails.id}`);
        setCanvasSectionProcessingState(canvasDetails.id, position.id, false);
      });
    } else {
      openSideModal(<ForcedCanvasPositionGenerationContextSideModalBody />, { canvasDetails, position });
    }
  };

  return (
    <CanvasPositionCard
      position={position}
      index={index}
      isProcessing={isProcessing}
      onSave={onSave}
      onRegenerate={onGenerate}
    />
  );
}
